const Screens = {
    // =========================================================================
    // Auth
    // =========================================================================
    LOGIN_REDIRECT: "/auth/callback",

    NOT_FOUND: "/404",

    // =========================================================================
    // Main
    // =========================================================================
    /** / */
    HOME: "/",
    PROFILE: "/profile",
    DASHBOARD: "/dashboard",
    LOGIN_LOG: "/loginlog",

    // ROLE
    ROLE_LIST: "/role/list",
    ROLE_EDIT: "/role/edit",
    ROLE_CREATE: "/role/create",

    // POLICY
    POLICY_LIST: "/policy/list",
    POLICY_EDIT: "/policy/edit",
    POLICY_CREATE: "/policy/create",

    // Quản lý tổ chức (Organization Manager)
    // ORGANIZATION
    ORGANIZATION_LIST: "/organization-module/organization/list",
    ORGANIZATION_EDIT: "/organization-module/organization/edit",
    ORGANIZATION_CREATE: "/organization-module/organization/create",

    // GROUP
    GROUP_LIST: "/organization-module/group/list",
    GROUP_EDIT: "/organization-module/group/edit",
    GROUP_CREATE: "/organization-module/group/create",

    // STAFF
    STAFF_LIST: "/organization-module/staff/list",
    STAFF_EDIT: "/organization-module/staff/edit",
    STAFF_CREATE: "/organization-module/staff/create",

    MANAGER_INVITATION: "/invitation/list",

    MANAGER_EVENT: "/event/list",
    MANAGER_EVENT_DETAIL: "/event/detail",

    // System Setting
    // MENU
    MENU_LIST: "/setting/menu/list",
    MENU_EDIT: "/setting/menu/edit",
    MENU_CREATE: "/setting/menu/create",

    // Module
    MODULE_LIST: "/setting/module/list",
    MODULE_EDIT: "/setting/module/edit",
    MODULE_CREATE: "/setting/module/create",

    // Resource
    RESOURCE_LIST: "/setting/resource/list",
    RESOURCE_EDIT: "/setting/resource/edit",

    //Codename
    CODENAME_LIST: "/setting/codename/list",
    CODENAME_EDIT: "/setting/codename/edit",
    CODENAME_CREATE: "/setting/codename/create",

    // Quản lý dịch vụ (Service)
    // SERVICE
    SERVICE_LIST: "/service-module/service/list",
    SERVICE_EDIT: "/service-module/service/edit",
    SERVICE_CREATE: "/service-module/service/create",

    // CLIENT
    CLIENT_LIST: "/service-module/client/list",
    CLIENT_EDIT: "/service-module/client/edit",
    CLIENT_CREATE: "/service-module/client/create",

    // TENANT
    TENANT_LIST: "/service-module/tenant/list",
    TENANT_EDIT: "/service-module/tenant/edit",
    TENANT_CREATE: "/service-module/tenant/create",

    // SYSTEM SETTING
    SYSTEM_SETTING_LIST: "/setting/system/list",
    SYSTEM_SETTING_EDIT: "/setting/system/edit",

    NOTIFICATION_LIST: "/notification/list",
    NOTIFICATION_EDIT: "/notification/edit",

};

export default Screens;
