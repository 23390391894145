import Constants from "constants/index";
import IdentityService from "./indentity.service";

class UserService extends IdentityService {

    public getPaged = async (data: any) => {
        const result = await this.api.get({
            path: `${Constants.ApiPath.USER.GET_PAGED}?${data}`,
        });
        return result.data.result;
    }

    public getServiceUserPaged = async (data: any) => {
        const result = await this.api.get({
            path: `${Constants.ApiPath.USER.GET_SERVICE_USER_PAGED}?${data}`,
        });
        return result.data.result;
    }

    public getDetail = async (data: { id: string, clientId?: string, organizationId?: string }) => {
        const result = await this.api.get({
            path: `${Constants.ApiPath.USER.DETAIL}`,
            query: data,
        });
        return result.data.result;
    }

    public create = async (data: any) => {
        const result = await this.api.post({
            path: `${Constants.ApiPath.USER.CREATE}`,
            data
        });
        return result.data;
    }

    public update = async (data: any) => {
        const result = await this.api.post({
            path: `${Constants.ApiPath.USER.UPDATE}`,
            data
        });
        return result.data;
    }

    public block = async (id: string) => {
        const result = await this.api.post({
            path: `${Constants.ApiPath.USER.BLOCK}?id=${id}`,
        });
        return result.data;
    }

    public delete = async (data: any) => {
        const result = await this.api.delete({
            path: `${Constants.ApiPath.USER.DELETE}`,
            data
        });
        return result.data;
    }

    public updateStatus = async (data: any) => {
        const result = await this.api.post({
            path: Constants.ApiPath.USER.UPDATE_STATUS,
            data
        });
        return result.data;
    }

    public getManageUser = async () => {
        const result = await this.api.get({
            path: `${Constants.ApiPath.USER.GET_MANAGE_USER}`,
        });
        return result.data.result;
    }
}

export default UserService;