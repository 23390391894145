import Strings from "../../constants/strings";
import { Status } from "constants/enum";
import { DataTableStatusCell } from "@maysoft/common-component-react";


const CustomStatusCell = ({ status, isDetail }: { status: number, isDetail?: boolean; }) => {
    let data: any;

    switch (status) {
        case Status.Active:
            data = { icon: "done", color: "success", title: Strings.Common.ACTIVE };
            break;
        default:
            data = { icon: "clear", color: "secondary", title: Strings.Common.IN_ACTIVE };
            break;
    }

    return (<DataTableStatusCell data={data} isDetail={isDetail} />)
};

CustomStatusCell.defaultProps = {
    status: Status.Inactive,
}

export default CustomStatusCell;