import IdentityService from "./indentity.service";
import { IMultiLang } from "commons/interfaces";


export interface IDataCreateUpdateSettingApp {
    clientId: string
    tenantCode: string
    serviceCode: string
    organizationId?: string

    id?: string
    type: number
    name: IMultiLang
    setting: ISettingApp
    description?: IMultiLang
}

export interface ISettingApp {
    logoUrl?: string,
    faviconUrl?: string,
    hasReferer?: boolean,
    style?: ISettingStyle,
    allowRegister?: boolean,
    password?: ISettingPassword,
    metaData?: { [key: string]: string },
}

export interface ISettingStyle {
    fontFamily?: string
    link?: IStyleDetail
    body?: IStyleDetail
    button?: IStyleDetail
    header?: IStyleDetail
    footer?: IStyleDetail
    heading?: IStyleDetail
    subtitle?: IStyleDetail
}

export interface IStyleDetail {
    background: string
    color: string
}

export interface ISettingPassword {
    minLength?: number
    numeric?: boolean
    uppercase?: boolean
    lowercase?: boolean
    specialCharacter?: boolean
}

export interface IRecordAppSetting {
    clientId: string
    tenantCode: string
    serviceCode: string
    organizationId: string

    id: string
    type: number
    appUri: string
    setting: string
    name: IMultiLang
    description: IMultiLang
    settingJson: ISettingApp
    status: number
    createTime: string
    createUser: string
    updateTime: string
    updateUser: string
}



class AppService extends IdentityService {

    public getPaged = async (data: {
        organizationId?: string,
        serviceCode: string,
        clientId?: string,
        type?: string,
        pageNumber?: number,
        pageSize?: number,
        orderby?: string,
    }) => {
        const result = await this.api.get({
            path: "/App/GetPaged",
            query: data,
        });
        return result.data.result;
    };

    public getAll = async (data: {
        organizationId?: string,
        serviceCode: string,
        clientId?: string,
        type?: string,
    }): Promise<IRecordAppSetting[]> => {
        const result = await this.api.get({
            path: "/App/GetAll",
            query: data,
        });
        return result.data.result;
    };

    public getDetail = async (id: string) => {
        const result = await this.api.get({
            path: `/App/Detail/${id}`,
        });
        return result.data.result;
    };

    public create = async (data: IDataCreateUpdateSettingApp) => {
        const result = await this.api.post({
            path: "/App/Create",
            data: data,
        });
        return result.data.result;
    };

    public update = async (data: IDataCreateUpdateSettingApp) => {
        const result = await this.api.post({
            path: "/App/Update",
            data: data,
        });
        return result.data.result;
    };

    public delete = async (data: {
        ids: string[],
        organizationId: string,
    }) => {
        const result = await this.api.delete({
            path: "/App/Delete",
            data: data,
        });
        return result.data.result;
    };

    public updateAppUri = async (data: {
        id: string,
        appUrri: string,
        organizationId: string,
    }) => {
        const result = await this.api.post({
            path: "/App/UpdateAppUri",
            data: data,
        });
        return result.data.result;
    };
}

export default AppService;