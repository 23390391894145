import Constants from "constants/index";
import IdentityService from "./indentity.service";

class EventService extends IdentityService {

    public getPaged = async (data: string) => {
        const result = await this.api.get({
            path: `${Constants.ApiPath.EVENT.GET_PAGED}?${data}`,
        });
        return result.data.result;
    };

    public retry = async (id: string) => {
        const result = await this.api.put({
            path: `${Constants.ApiPath.EVENT.RETRY}/${id}`,
        });
        return result.data;
    };

    public update = async (data: {
        id: string,
        eventData: string
    }) => {
        const result = await this.api.put({
            path: Constants.ApiPath.EVENT.UPDATE,
            data: data,
        });
        return result.data;
    };
}

export default EventService;
