import { getToken, onMessage } from "firebase/messaging";
import { Messaging, getMessaging } from "firebase/messaging";
import { FirebaseApp, FirebaseOptions, initializeApp } from "firebase/app";
import { browserName, browserVersion, deviceType, osVersion, osName } from "react-device-detect";

import Helpers from "commons/helpers";
import Constants from "constants/index";
import NotificationService from "services/message/notification.service";

import { store } from "store";
import { setAddUnreadNotifi, setDataMessage } from "store/slice/message.slice";



interface IFirebaseGlobal {
    appInitFirebase: FirebaseApp | null;
    messagingFirebase: Messaging | null;

    vapidKey: string | null;
    firebaseConfig: FirebaseOptions | null;
}

export const FirebaseGlobal: IFirebaseGlobal = {
    vapidKey: null,
    firebaseConfig: null,

    appInitFirebase: null,
    messagingFirebase: null,
};

export function resetFirebaseGlobal() {
    FirebaseGlobal.vapidKey = null;
    FirebaseGlobal.firebaseConfig = null;
    FirebaseGlobal.appInitFirebase = null;
    FirebaseGlobal.messagingFirebase = null;
}

export function initFirebaseGlobal(data: { config?: any, vapidKey?: string }) {
    if (!Helpers.isNullOrEmpty(data.config)) {
        const newAppInit = initializeApp(data.config);
        const newMsg = getMessaging(newAppInit);

        FirebaseGlobal.firebaseConfig = data.config;
        FirebaseGlobal.appInitFirebase = newAppInit;
        FirebaseGlobal.messagingFirebase = newMsg;
    }
    if (!Helpers.isNullOrEmpty(data.vapidKey)) {
        FirebaseGlobal.vapidKey = data.vapidKey;
    }
}


export async function registerFirebase() {

    if (Helpers.isNullOrEmpty(FirebaseGlobal.vapidKey)
        || Helpers.isNullOrEmpty(FirebaseGlobal.messagingFirebase)) {
        console.log("Firebase Global is null");
    } else {
        const oldFcmToken = await Helpers.getItemInLocalStorage(Constants.StorageKeys.FCM_TOKEN);

        // Show permission request UI
        Notification.requestPermission().then((permission) => {
            // permission: "default" | "denied" | "granted"
            if (permission === "granted") {
                if (Helpers.isNullOrEmpty(oldFcmToken)) {
                    // Retrieve a registration token for use with FCM.
                    getToken(
                        FirebaseGlobal.messagingFirebase,
                        {
                            vapidKey: FirebaseGlobal.vapidKey,
                        }
                    ).then(async (currentToken: any) => {
                        if (!Helpers.isNullOrEmpty(currentToken)) {
                            const deviceInfo = JSON.stringify({
                                browserName,
                                browserVersion,
                                deviceType,
                                osVersion,
                                osName
                            });
                            const newDataReq: any = {
                                deviceId: currentToken,
                                deviceInfo: deviceInfo,
                            };

                            try {
                                const result = await new NotificationService().registerFirebase(newDataReq);
                                if (result.statusCode === Constants.ApiCode.SUCCESS) {
                                    Helpers.setItemInLocalStorage(Constants.StorageKeys.FCM_TOKEN, currentToken);
                                    console.log("Đã đăng ký thiết bị thành công");
                                }
                            } catch (error) {
                                console.log("Lỗi đăng ký thiết bị không thành công: ", error);
                            };
                        }
                    }).catch((err: any) => {
                        console.log("Đăng ký firebase không thành công: ", err);
                    });
                } else {
                    console.log("Đã đăng ký rồi!");
                }
            } else {
                Helpers.setItemInLocalStorage(Constants.StorageKeys.FCM_TOKEN, "");
                console.log("Không có quyền thông báo");
            }
        });
    }
}

export async function unRegisterFirebase() {
    const newFcmToken = Helpers.getItemInLocalStorage(Constants.StorageKeys.FCM_TOKEN, "");

    if (!Helpers.isNullOrEmpty(newFcmToken)) {
        try {
            await new NotificationService().unRegisterFirebase(newFcmToken);
            resetFirebaseGlobal();
            Helpers.setItemInLocalStorage(Constants.StorageKeys.FCM_TOKEN, "");
            console.log("Hủy đăng ký firebase thành công ");
        }
        catch (error) {
            console.log("Hủy đăng ký firebase không thành công : ", error);
        }
    } else {
        console.log("Không có FCM_TOKEN firebase");
    }
}

export function subscribeMessage() {
    if (FirebaseGlobal.messagingFirebase) {
        return onMessage(FirebaseGlobal.messagingFirebase, (payload: any) => {
            store.dispatch(setAddUnreadNotifi());
            store.dispatch(setDataMessage({
                messageId: payload.messageId,
                data: {
                    Title: payload.data?.Title,
                    Body: payload.data?.Body,
                }
            }));
        });
    } else {
        return () => { };
    }
}