import DashboardScreen from "screens/dashboard";
import ProfileScreen from "screens/profile";

import GroupScreen from "screens/group";
import OrganizationScreen from "screens/organization";
import RoleScreen from "screens/role";
import StaffScreen from "screens/staff";

import ModuleScreen from "screens/module";

import ClientScreen from "screens/client";
import ClientEditScreen from "screens/client/edit";

import MenuScreen from "screens/menu";
import MenuEditScreen from "screens/menu/edit";

import ServiceScreen from "screens/service";
import ServiceEditScreen from "screens/service/edit";

import TenantScreen from "screens/tenant";
import TenantEditScreen from "screens/tenant/edit";

import CodenameScreen from "screens/codename";

import ResourceScreen from "screens/resource";
import ResourceEditScreen from "screens/resource/edit";

import InvitationScreen from "screens/invitation";
import LoginLogScreen from "screens/login/loginLog";

import EventScreen from "screens/events";
import EventDetailScreen from "screens/events/detail";

import NotificationScreen from "screens/notification";

import SystemSettingScreen from "screens/systemsetting";
import SystemSettingEditScreen from "screens/systemsetting/edit";

export const useMapScreenName = () => {
    let data: { [key: string]: JSX.Element } = {
        ProfileScreen: <ProfileScreen />,
        DashboardScreen: <DashboardScreen />,
        InvitationScreen: <InvitationScreen />,

        RoleScreen: <RoleScreen />,
        RoleEditScreen: <RoleScreen />,

        OrganizationScreen: <OrganizationScreen />,
        OrganizationEditScreen: <OrganizationScreen />,

        GroupScreen: <GroupScreen />,
        GroupEditScreen: <GroupScreen />,

        StaffScreen: <StaffScreen />,
        StaffEditScreen: <StaffScreen />,

        ServiceScreen: <ServiceScreen />,
        ServiceEditScreen: <ServiceEditScreen />,

        ClientScreen: <ClientScreen />,
        ClientEditScreen: <ClientEditScreen />,

        TenantScreen: <TenantScreen />,
        TenantEditScreen: <TenantEditScreen />,

        LoginLogScreen: <LoginLogScreen />,

        EventScreen: <EventScreen />,
        EventDetailScreen: <EventDetailScreen />,

        NotificationScreen: <NotificationScreen />,
        NotificationEditScreen: <NotificationScreen />,

        CodenameScreen: <CodenameScreen />,
        CodenameEditScreen: <CodenameScreen />,

        MenuScreen: <MenuScreen />,
        MenuEditScreen: <MenuEditScreen />,

        ModuleScreen: <ModuleScreen />,
        ModuleEditScreen: <ModuleScreen />,

        ResourceScreen: <ResourceScreen />,
        ResourceEditScreen: <ResourceEditScreen />,

        SystemSettingScreen: <SystemSettingScreen />,
        SystemSettingEditScreen: <SystemSettingEditScreen />,
    };

    return data;
};
