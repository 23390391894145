import { Add } from "@mui/icons-material";
import { Card, Grid } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

import Helpers from "commons/helpers";
import Constants from "constants/index";
import Screens from "constants/screens";
import Strings from "constants/strings";
import ResourceService from "services/identity/resource.service";

import { RootState } from "store";
import { DashboardLayout } from "layout";
import { setDataAlert } from "store/slice/message.slice";
import { showLoading } from "store/slice/loadingAPI.slice";
import { ICodename, ITitleRoute, IMultiLang } from "commons/interfaces";
import { AccessMode, Mode, PermissionType, Status } from "constants/enum";
import {
    Box, Modal, Button, FormField, DataTable,
    Typography, Autocomplete, SelectMultiLanguage,
    useCommonComponentContext,
} from "@maysoft/common-component-react";

interface IDataResourceDetail {
    id?: string,
    controller?: string,
    resourceCode?: string,
    action?: string,
    method?: string,
    editMode?: number,
    status?: number,
}

interface IDataDetail {
    id?: string,
    tenantCode?: string,
    serviceCode?: string,
    organizationId?: number,
    clientId?: string,
    resourceType?: any,
    resourceName?: IMultiLang,
    resourceCode?: string,
    description?: IMultiLang,
    accessMode?: string,
    updateTime?: any,
    resourceDetails?: IDataResourceDetail[],
}

interface IModel {
    edited?: boolean;
    dataDetail?: IDataDetail;

    mode?: number;
    title?: string;
    language?: string;
    route?: ITitleRoute[];
}

const ResourceEditScreen = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const { getResourcePermissions } = useCommonComponentContext();

    const resourceService = new ResourceService();

    const [model, setModel] = useState<IModel>({} as IModel);
    const [openPopup, setOpenPopup] = useState<boolean>(false);
    const [dataPopup, setDataPopup] = useState<IDataResourceDetail>({});
    const [dataError, setDataError] = useState<{ action?: string, method?: string, editMode?: string, }>({});

    const resourcePermissions = getResourcePermissions(Constants.ResourceCode.RESOURCE);
    const listPathName = useSelector((state: RootState) => state.titleRoute.listPathName);

    const language = Helpers.getItemInLocalStorage(Constants.StorageKeys.LANGUAGE, Constants.DefaultLanguage);
    const clientId = searchParams.get("clientId") || Helpers.getItemInLocalStorage(Constants.StorageKeys.CLIENT_ID);

    const listMultiLanguage = useMemo(() => {
        return [
            { code: Constants.LanguageContent.VI, name: Strings.Languages.VI },
            { code: Constants.LanguageContent.EN, name: Strings.Languages.EN },
        ]
    }, [Strings.getLanguage()]);

    const listEditModes: ICodename[] = [
        { code: `${PermissionType.Read}`, name: Strings.ROLE.READ },
        { code: `${PermissionType.Create}`, name: Strings.ROLE.CREATE },
        { code: `${PermissionType.Update}`, name: Strings.ROLE.UPDATE },
        { code: `${PermissionType.Delete}`, name: Strings.ROLE.DELETE },
        { code: `${PermissionType.Import}`, name: Strings.ROLE.IMPORT },
        { code: `${PermissionType.Export}`, name: Strings.ROLE.EXPORT },
    ];

    const listMethod: ICodename[] = [
        { code: "post", name: "post" },
        { code: "get", name: "get" },
        { code: "patch", name: "patch" },
        { code: "put", name: "put" },
        { code: "delete", name: "delete" },
    ];

    const listAccessMode: ICodename[] = [
        { code: `${AccessMode.Public}`, name: Strings.MODULE.PUBLIC },
        { code: `${AccessMode.Private}`, name: Strings.MODULE.PRIVATE },
        { code: `${AccessMode.PrivateRBAC}`, name: Strings.MODULE.PRIVATERBAC },
    ];

    const idDetail: string = searchParams.get("id");
    const pramsMode = !Helpers.isNullOrEmpty(searchParams.get("mode")) ? Number(searchParams.get("mode")) : undefined;

    useEffect(() => {
        if (!Helpers.isNullOrEmpty(clientId)) {
            Helpers.setItemInLocalStorage(Constants.StorageKeys.CLIENT_ID, clientId);
        }
        getData();
    }, []);

    const handleGoBack = () => {
        const itemP = listPathName.find(el => el.pathName === Screens.RESOURCE_LIST);
        navigate(Screens.RESOURCE_LIST + (itemP ? itemP.query : ""));
    }

    const getData = async () => {
        try {
            dispatch(showLoading(true));

            let dataDetail: IDataDetail = { accessMode: `${AccessMode.Public}` };

            if (!Helpers.isNullOrEmpty(idDetail)) {
                const mode = resourcePermissions.canUpdate ? (pramsMode || Mode.Update) : Mode.View;
                const result = await resourceService.getDetailById(idDetail);

                dataDetail = {
                    id: idDetail,
                    clientId: result.clientId,
                    organizationId: result.organizationId,
                    serviceCode: result.serviceCode,
                    tenantCode: result.tenantCode,
                    resourceType: result.resourceType,
                    resourceName: result.resourceName,
                    resourceCode: result.resourceCode,
                    description: result.description,
                    accessMode: result.accessMode,
                    updateTime: result.updateTime,
                    resourceDetails: result.resourceDetails || [],
                };

                setModel({
                    mode: mode,
                    title: (mode === Mode.View)
                        ? Strings.RESOURCE.TITLE_DETAIL_VIEW : Strings.RESOURCE.TITLE_UPDATE_VIEW,
                    route: [
                        { title: Strings.RESOURCE.TITLE_MENU, route: Screens.RESOURCE_LIST },
                        {
                            title: (mode === Mode.View) ? Strings.Common.DETAIL : Strings.Common.UPDATE,
                            route: "",
                        }
                    ],
                    language: language,
                    dataDetail: dataDetail,
                });

                navigate(Screens.RESOURCE_EDIT + `?id=${idDetail}&mode=${mode}`, { replace: true });
            } else {
                handleGoBack();
            }

            dispatch(showLoading(false));
        } catch (error) {
            dispatch(showLoading(false));
            const e = Helpers.renderExceptionError(error);
            dispatch(setDataAlert({ message: e, type: "error" }));
        }
    }

    const onSubmit = async () => {
        if (model.mode === Mode.View) {
            setModel({
                ...model,
                mode: Mode.Update,
                title: Strings.RESOURCE.TITLE_UPDATE_VIEW,
                route: [
                    { title: Strings.RESOURCE.TITLE_MENU, route: Screens.RESOURCE_LIST },
                    { title: Strings.Common.UPDATE, route: "" }
                ]
            });
            navigate(Screens.RESOURCE_EDIT + `?id=${idDetail}&mode=${Mode.Update}`, { replace: true });
        } else {
            try {
                dispatch(showLoading(true));
                let dataDetail: IDataDetail = { ...model.dataDetail };

                let resourceDetailRequests: any[] = [];
                [...model.dataDetail?.resourceDetails || []].forEach((item) => {
                    resourceDetailRequests.push({
                        id: (item.status === Status.Active) ? item.id : undefined,
                        editMode: Number(item.editMode),
                        controller: item.controller,
                        action: item.action,
                        method: item.method,
                    });
                });


                let data: any = {
                    id: dataDetail?.id,
                    updateTime: dataDetail?.updateTime,
                    tenantCode: dataDetail?.tenantCode,
                    serviceCode: dataDetail?.serviceCode,
                    clientId: dataDetail?.organizationId,
                    accessMode: Number(dataDetail?.accessMode),
                    resourceType: Number(dataDetail?.resourceType),
                    resourceDetailRequests: resourceDetailRequests,
                    organizationId: dataDetail?.organizationId || 0,
                    description: Helpers.setValueMultiLanguage(dataDetail?.description, model.language, listMultiLanguage) || { value: { [model.language]: "" } },
                    resourceName: Helpers.setValueMultiLanguage(dataDetail?.resourceName, model.language, listMultiLanguage) || { value: { [model.language]: "" } },
                };

                const result = await resourceService.update(data);
                if (result.statusCode === Constants.ApiCode.SUCCESS) {
                    dispatch(setDataAlert({ message: Strings.Message.UPDATE_SUCCESS, type: "success" }));
                    setModel({
                        ...model,
                        edited: false,
                        mode: Mode.View,
                        title: Strings.RESOURCE.TITLE_DETAIL_VIEW,
                        route: [
                            { title: Strings.RESOURCE.TITLE_MENU, route: Screens.RESOURCE_LIST },
                            { title: Strings.Common.DETAIL, route: "" }
                        ]
                    });

                    navigate(Screens.RESOURCE_EDIT + `?id=${dataDetail?.id}&mode=${Mode.View}`, { replace: true });
                }

                dispatch(showLoading(false));
            } catch (error) {
                dispatch(showLoading(false));
                const e = Helpers.renderExceptionError(error);
                dispatch(setDataAlert({ message: e, type: "error" }));
            }
        }
    }

    const basicInfo = () => (
        <Card>
            <Box p={2}>
                <Box
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{ display: "flex", flexWrap: "wrap" }}
                >
                    <Typography variant="h5">{Strings.RESOURCE.BASIC_INFO}</Typography>
                    {
                        (model?.mode !== Mode.View) &&
                        <SelectMultiLanguage
                            defaultLanguage={model.language}
                            onChangeLanguage={(value) => {
                                setModel({ ...model, language: value })
                            }}
                        />
                    }
                </Box>
                <Box component="form">
                    <Grid container spacing={3} p={2} alignContent="center">
                        <Grid item xs={12} md={6}>
                            <FormField
                                maxLength={255}
                                mode={model.mode}
                                label={Strings.RESOURCE.NAME}
                                value={model.dataDetail?.resourceName?.value?.[model.language] || ""}
                                onChangeValue={(value) => {
                                    setModel({
                                        ...model,
                                        edited: true,
                                        dataDetail: {
                                            ...model.dataDetail,
                                            resourceName: {
                                                ...model.dataDetail.resourceName,
                                                value: {
                                                    ...model.dataDetail.resourceName?.value,
                                                    [model.language]: value,
                                                }
                                            }
                                        }
                                    })
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Autocomplete
                                isSelectedBox
                                mode={model.mode}
                                data={listAccessMode || []}
                                label={Strings.MODULE.ROLE_ACCESS}
                                defaultValue={model.dataDetail?.accessMode}
                                onChange={(value) => {
                                    setModel({
                                        ...model,
                                        edited: true,
                                        dataDetail: {
                                            ...model.dataDetail,
                                            accessMode: value
                                        }
                                    });
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormField
                                isFullRow
                                multiline
                                maxLength={500}
                                mode={model.mode}
                                label={Strings.RESOURCE.DESCRIPTION}
                                placeholder={Strings.RESOURCE.ENTER_DESCRIPTION}
                                defaultValue={model.dataDetail?.description?.value?.[model.language] || ""}
                                onBlur={(value) => {
                                    setModel({
                                        ...model,
                                        edited: true,
                                        dataDetail: {
                                            ...model.dataDetail,
                                            description: {
                                                ...model.dataDetail.description,
                                                value: {
                                                    ...model.dataDetail.description?.value,
                                                    [model.language]: value,
                                                }
                                            }
                                        }
                                    })
                                }}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            {/* Button Add New Resource Detail */}
                            {
                                (model.mode !== Mode.View) &&
                                <Box sx={{
                                    gap: 1,
                                    marginBottom: 1,
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                }}>
                                    <Typography variant="h6">{Strings.RESOURCE.RESOURCE_DETAIL}</Typography>
                                    <Button color="info" variant="outlined"
                                        onClick={() => setOpenPopup(true)}>
                                        <Add />&nbsp;
                                        {Strings.Common.ADD_NEW}
                                    </Button>
                                </Box>
                            }
                            {/* Table Show Resource Detail */}
                            <DataTable
                                isLocal
                                isShowIndex
                                loading={false}
                                
                                pageNumber={1}
                                rowPerPage={Constants.ROW_PER_PAGE}
                                hideActionMenu={model.mode === Mode.View}
                                totalCount={model.dataDetail?.resourceDetails?.length || 0}

                                table={{
                                    columns: [
                                        {
                                            Header: Strings.RESOURCE.NAME_API, accessor: "action", width: "150px",
                                        },
                                        {
                                            Header: Strings.RESOURCE.METHOD, accessor: "method", width: "150px",
                                        },
                                        {
                                            Header: Strings.RESOURCE.EDIT_MODE, accessor: "editMode", width: "150px",
                                            Cell: ({ value }: any) => <>{listEditModes.find(el => el.code === `${value}`)?.name}</>
                                        },
                                    ],
                                    rows: model.dataDetail?.resourceDetails || []
                                }}
                                actionList={row => [
                                    {
                                        key: "edit",
                                        iconName: "edit",
                                        title: Strings.Common.EDIT,
                                        callback: (row) => {
                                            setOpenPopup(true);
                                            setDataPopup(row);
                                        },
                                    },
                                    {
                                        key: "delete",
                                        iconName: "delete",
                                        title: Strings.Common.DELETE,
                                        callback: (row) => {
                                            let dataTemp = [...model.dataDetail?.resourceDetails || []];
                                            dataTemp = dataTemp.filter(el => el.id !== row.id);
                                            setModel({
                                                ...model,
                                                dataDetail: {
                                                    ...model.dataDetail,
                                                    resourceDetails: dataTemp,
                                                }
                                            })
                                        },
                                    },
                                ]}
                            />
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Card>
    )

    const onConfirm = () => {
        let isChecked: boolean = true;
        let dataErr: any = { ...dataError };

        if (Helpers.isNullOrEmpty(dataPopup.action)) {
            dataErr["action"] = Strings.Validation.REQUIRED;
            isChecked = false;
        }
        if (Helpers.isNullOrEmpty(dataPopup.editMode)) {
            dataErr["editMode"] = Strings.Validation.REQUIRED;
            isChecked = false;
        }
        if (Helpers.isNullOrEmpty(dataPopup.method)) {
            dataErr["method"] = Strings.Validation.REQUIRED;
            isChecked = false;
        }

        if (isChecked) {
            let dataTemp = [...model.dataDetail?.resourceDetails || []];
            if (Helpers.isNullOrEmpty(dataPopup.id)) {
                dataTemp.push({
                    id: Date.now().toString(),
                    action: dataPopup.action,
                    method: dataPopup.method,
                    editMode: dataPopup.editMode,
                    controller: dataTemp[0].controller,
                });
            } else {
                const index = dataTemp.findIndex(el => el.id === dataPopup.id);
                if (index !== -1) {
                    dataTemp[index] = {
                        ...dataTemp[index],
                        action: dataPopup.action,
                        method: dataPopup.method,
                        editMode: dataPopup.editMode,
                    }
                }
            }
            setModel({
                ...model,
                dataDetail: {
                    ...model.dataDetail,
                    resourceDetails: dataTemp,
                }
            });
            setDataPopup({});
            setDataError({});
            setOpenPopup(false);
        } else {
            setDataError(dataErr);
        }
    }

    const modalAdd = () => {
        return (
            <Modal
                fullWidth
                maxWidth={"sm"}
                hasActionButton
                visible={openPopup || false}
                title={Strings.RESOURCE.RESOURCE_DETAIL}
                onAction={() => onConfirm()}
                onClose={() => {
                    setOpenPopup(false);
                    setDataError({});
                }}
            >
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <FormField
                            required
                            maxLength={255}
                            label={Strings.RESOURCE.NAME_API}
                            placeholder={Strings.RESOURCE.ENTER_NAME_API}
                            errorMessage={dataError.action}
                            defaultValue={dataPopup.action || ""}
                            onBlur={(value) => {
                                setDataPopup({ ...dataPopup, action: value })
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Autocomplete
                            required
                            isSelectedBox
                            data={listMethod || []}
                            defaultValue={dataPopup.method || ""}
                            errorMessage={dataError.method}
                            label={Strings.RESOURCE.METHOD}
                            placeholder={Strings.RESOURCE.SELECT_METHOD}
                            onChange={(value) => {
                                setDataPopup({ ...dataPopup, method: value })
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Autocomplete
                            required
                            isSelectedBox
                            data={listEditModes || []}
                            defaultValue={dataPopup.editMode || ""}
                            errorMessage={dataError.editMode}
                            label={Strings.RESOURCE.EDIT_MODE}
                            placeholder={Strings.RESOURCE.SELECT_EDIT_MODE}
                            onChange={(value) => {
                                setDataPopup({ ...dataPopup, editMode: value })
                            }}
                        />
                    </Grid>
                </Grid>
            </Modal >
        )
    }

    return (
        <DashboardLayout
            title={model.title}
            route={model.route}
            isPermission={resourcePermissions.canRead}
            onBackPress={() => {
                if (!model.edited) {
                    handleGoBack()
                } else {
                    Helpers.showConfirmAlert(Strings.Message.CANCEL_ACTION_MESSAGE, handleGoBack);
                }
            }}
            nameActionPress={(model.mode === Mode.View) ? Strings.Common.EDIT : Strings.Common.SAVE}
            onActionPress={(resourcePermissions.canCreate || resourcePermissions.canUpdate) ? onSubmit : undefined}
        >
            <Box>
                {basicInfo()}
                {modalAdd()}
            </Box>
        </DashboardLayout>
    )
}

export default ResourceEditScreen;