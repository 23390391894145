import { Theme } from "@mui/material/styles";

export const cardItem = () => ({
    padding: "1em 0.5rem",
});

export const iconBox = (theme: Theme, ownerState: any) => {
    const { palette } = theme;
    const { color } = ownerState;
    return {
        padding: "1.5rem",
        background: " #e3eaef",
        borderRadius: "6px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "-3rem",
        backgroundColor: (palette as any)?.[color].main,
    };
};

export const icon = () => ({
    fontSize: "3rem",
    color: "#ffffff",
});

export const cardContent = () => ({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-end",
});

export const cardTitle = (theme: Theme, ownerState: { fontSize: string }) => {
    const { fontSize } = ownerState;
    return {
        fontSize,
        letterSpacing: ".5px",
        lineHeight: "1.2",
        color: "#98a6ad",
    };
};

export const cardValue = () => ({
    fontWeight: "bold",
    fontSize: "1.5rem",
});

export const cardSummary = () => ({
    fontSize: "0.875rem",
    color: "#7b809a",
    paddingTop: "1rem",
    px: "1rem",
});

export const cardSummaryValue = (theme: Theme, ownerState: any) => {
    const { palette } = theme;
    const { color } = ownerState;
    return {
        display: "inline-block",
        px: 1,
        fontSize: "0.875rem",
        color: (palette as any)[color].main,
    };
};

export const contentBox = () => ({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
});

export const title = () => ({
    fontWeight: "bold",
    fontSize: "1rem",
    color: "#344767",
});

export const statisticBox = () => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
});

export const titleBox = () => ({
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
});

export const card = () => ({
    padding: "1rem",
});

export const container = () => ({ display: "grid", gap: "1rem", mt: "1rem" });
