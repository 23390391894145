import Helpers from "commons/helpers";
import Constants from "constants/index";
import IdentityService from "./indentity.service";

class LoginLogService extends IdentityService {

    public getPaged = async (data: any) => {
        const query = Helpers.handleFormatParams(data);
        const result = await this.api.get({
            path: `${Constants.ApiPath.LOGIN_LOG.GET_PAGED}?${query}`,
        });
        return result.data.result;
    }
}

export default LoginLogService;