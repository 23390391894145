import { useDispatch } from "react-redux";
import { Theme } from "@mui/material/styles";
import { useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Box, Typography } from "@maysoft/common-component-react";
import { Card, Divider, Grid, Icon } from "@mui/material";

import Strings from "constants/strings";
import Screens from "constants/screens";
import ProfileService from "services/identity/profile.service";

import { DashboardLayout } from "layout";
import { showLoading } from "store/slice/loadingAPI.slice";
import {
    card,
    icon,
    title,
    iconBox,
    cardItem,
    cardTitle,
    cardValue,
    container,
    contentBox,
    cardContent,
    cardSummary,
    statisticBox,
    cardSummaryValue,
} from "./styles";

type IColor = | "white" | "primary" | "secondary" | "info" | "success" | "warning" | "error" | "light" | "dark" | "default";

interface IData {
    id: string;
    title: string;
    value: number;
    icon: string;
    color: IColor;
    href: string;
    summary: {
        title: string;
        value: number;
    };
}

interface IManagement {
    value: number;
    title: string;
    href: string;
}

const DashboardScreen = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const profileService = new ProfileService();

    const [dataSummary, setDataSummary] = useState<{
        totalGroup: number;
        totalOrganization: number;
        totalOwner: number;
        totalOwnersGroup: number;
        totalOwnersUser: number;
    }>({
        totalGroup: 0,
        totalOrganization: 0,
        totalOwner: 0,
        totalOwnersGroup: 0,
        totalOwnersUser: 0,
    });

    useEffect(() => {
        if (window.location.pathname === "/") {
            navigate(Screens.DASHBOARD, { replace: true });
        }
        getUserSummary();
    }, []);

    const getUserSummary = async () => {
        try {
            dispatch(showLoading(true));
            const result = await profileService.getUserSummary();

            if (result.statusCode === 200) {
                setDataSummary(result?.result);
            }

            dispatch(showLoading(false));
        } catch (error) {
            dispatch(showLoading(false));
        }
    };

    const data: IData[] = useMemo(
        () => [
            {
                id: "1",
                title: Strings.DASHBOARD.TOTAL_MANAGEMENT_ORG,
                value: dataSummary.totalOwner,
                icon: "location_city",
                color: "dark",
                href: Screens.ORGANIZATION_LIST,
                summary: {
                    title: Strings.DASHBOARD.THAN_LAST_MONTH,
                    value: 0,
                },
            },
            {
                id: "2",
                title: Strings.DASHBOARD.TOTAL_MANAGEMENT_GROUP,
                value: dataSummary.totalOwnersGroup,
                icon: "groups",
                color: "primary",
                href: Screens.GROUP_LIST,
                summary: {
                    title: Strings.DASHBOARD.THAN_LAST_MONTH,
                    value: 0,
                },
            },
            {
                id: "3",
                title: Strings.DASHBOARD.TOTAL_MANAGEMENT_MEMBER,
                value: dataSummary.totalOwnersUser,
                icon: "person",
                color: "success",
                href: Screens.STAFF_LIST,
                summary: {
                    title: Strings.DASHBOARD.THAN_LAST_MONTH,
                    value: 0,
                },
            },
        ],
        [dataSummary]
    );

    const managementData: IManagement[] = useMemo(
        () => [
            {
                value: dataSummary.totalOrganization,
                title: Strings.DASHBOARD.TOTAL_ORG_BELONG,
                href: Screens.ORGANIZATION_LIST,
            },
            {
                value: dataSummary.totalGroup,
                title: Strings.DASHBOARD.TOTAL_GROUP_BELONG,
                href: Screens.GROUP_LIST,
            },
        ],
        [dataSummary]
    );

    return (
        <DashboardLayout
            isPermission
            title={Strings.DASHBOARD.TITLE_MENU}
            route={[{ title: Strings.DASHBOARD.TITLE_MENU, route: "" }]}
        >
            <Box sx={container}>
                <Grid container spacing={2}>
                    {data.map((item, index) => (
                        <CardItem key={index} item={item} />
                    ))}
                </Grid>
                <Card sx={card}>
                    <Typography sx={title}>{Strings.DASHBOARD.STATISTICAL}</Typography>
                    <Grid container spacing={2}>
                        {managementData.map((item, index) => (
                            <ManagementItem key={index} item={item} />
                        ))}
                    </Grid>
                </Card>
            </Box>
        </DashboardLayout>
    );
};

const ManagementItem = ({ item }: { item: IManagement }) => (
    <Grid item xs={12} md={6} lg={12 / 2} sx={statisticBox}>
        <Link to={item.href}>
            <Typography fontWeight="bold">{item.value}</Typography>
        </Link>
        <Typography sx={(theme: Theme) => cardTitle(theme, { fontSize: "0.875rem" })}>
            {item.title}
        </Typography>
    </Grid>
);

const CardItem = ({ item }: { item: IData }) => (
    <Grid item xs={12} sm={6} md={6} lg={12 / 3} key={item.id}>
        <Link to={item.href}>
            <Card sx={cardItem}>
                <Box sx={contentBox}>
                    <Card sx={(theme: Theme) => iconBox(theme, { color: item.color as IColor })}>
                        <Icon sx={icon}>{item.icon}</Icon>
                    </Card>
                    <Box sx={cardContent}>
                        <Typography
                            sx={(theme: Theme) => cardTitle(theme, { fontSize: "0.875rem" })}
                        >
                            {item.title}
                        </Typography>
                        <Typography sx={cardValue}>{item.value}</Typography>
                    </Box>
                </Box>
                <Divider />
                <Typography sx={cardSummary}>
                    {item.summary.value > 0 ? (
                        <Typography
                            sx={(theme: Theme) => cardSummaryValue(theme, { color: "success" })}
                        >
                            +{item.summary.value}%
                        </Typography>
                    ) : (
                        <Typography
                            sx={(theme: Theme) => cardSummaryValue(theme, { color: "error" })}
                        >
                            {item.summary.value}%
                        </Typography>
                    )}
                    {item.summary.title}
                </Typography>
            </Card>
        </Link>
    </Grid>
);

export default DashboardScreen;
