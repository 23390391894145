import Icon from "@mui/material/Icon";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";

import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { BellNotification } from "@maysoft/circle-common-react";
import { MenuOpenRounded, MenuRounded } from "@mui/icons-material";
import { Box, Breadcrumbs, UserProfileDropdown } from "@maysoft/common-component-react";

import Helpers from "commons/helpers";
import Constants from "constants/index";
import Screens from "constants/screens";
import Strings from "constants/strings";

import { RootState } from "store";
import { useAuth } from "providers/authProvider";
import { showLoading } from "store/slice/loadingAPI.slice";
import { fetchUserInfo } from "store/slice/userInfo.slice";
import { MessageRequestType, MessageType } from "constants/enum";
import { setTotalUnreadNotifi } from "store/slice/message.slice";
import { useMaterialUIController, setMiniSidenav } from "components/context";
import {
    navbar, navbarContainer, navbarRow,
    navbarDesktopMenu, navbarMobileMenu, navbarProfile,
} from "components/Navbar/styles";

interface Props {
    title?: string;
    light?: boolean;
    isMini?: boolean;
    absolute?: boolean;
    route?: {
        title: string;
        route: string;
    }[];
    handleDrawerToggle: () => void;
}

function Navbar({ absolute, light, isMini, title, route, handleDrawerToggle }: Props): JSX.Element {
    const auth = useAuth();
    const navigate = useNavigate();
    const dispatchRedux = useDispatch();
    const anchorEl = useRef<HTMLButtonElement>(null);

    const [controller, dispatch] = useMaterialUIController();
    const { miniSidenav, transparentNavbar, darkMode } = controller;

    const [openDropDown, setOpenDropDown] = useState<any>(false);

    const userProfile = useSelector((state: RootState) => state.userInfo?.userProfile);
    const totalUnreadNotifi = useSelector((state: RootState) => state.messageSlice.totalUnreadNotifi);

    useEffect(() => {
        const handleSetOpenMenu = () => { setOpenDropDown(anchorEl.current) };

        __EventEmitter.addListener(Constants.EventName.CHOOSE_ORGANIZATION, handleSetOpenMenu);

        return () => {
            __EventEmitter.removeListener(Constants.EventName.CHOOSE_ORGANIZATION, handleSetOpenMenu);
        };
    }, []);

    const handleMiniSidenav = () => {
        setMiniSidenav(dispatch, !miniSidenav);
        handleDrawerToggle();
    };

    const handleLogout = () => {
        Helpers.showConfirmAlert(Strings.Message.CONFIRM_LOGOUT, async () => {
            await auth.signoutRedirect();
        });
    };

    const onChangeOrganization = (orgId: string) => {
        dispatchRedux(showLoading(true));

        Helpers.setItemInLocalStorage(Constants.StorageKeys.ORGANIZATION_ID, orgId);

        dispatchRedux(fetchUserInfo());

        dispatchRedux(showLoading(false));
    };

    const iconsStyle = ({ palette: { dark, white, text }, functions: { rgba } }: { palette: any; functions: any }) => ({
        color: () => {
            let colorValue = light || darkMode ? white.main : dark.main;
            if (transparentNavbar && !light) {
                colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
            }
            return colorValue;
        },
    });

    return (
        <AppBar
            color="inherit"
            position="static"
            sx={(theme) => navbar(theme, { transparentNavbar, absolute, light, darkMode })}
        >
            <Toolbar sx={(theme) => navbarContainer(theme, { isMini })}>
                <Box
                    color="inherit"
                    mb={{ xs: 1, md: 0 }}
                    sx={(theme: any) => navbarRow(theme, { isMini })}
                >
                    <Breadcrumbs
                        light={light}
                        title={title || ""}
                        route={route || []}
                        pathNameHome={Screens.DASHBOARD}
                        onNavigate={(path) => { navigate(path) }}
                    />
                    <IconButton
                        size="small"
                        disableRipple
                        sx={navbarDesktopMenu}
                        onClick={handleMiniSidenav}
                    >
                        <Icon fontSize="medium" sx={iconsStyle}>
                            {isMini
                                ? <MenuRounded sx={{ color: Constants.Styles.BLACK_COLOR }} />
                                : <MenuOpenRounded sx={{ color: Constants.Styles.BLACK_COLOR }} />
                            }
                        </Icon>
                    </IconButton>
                </Box>

                <Box sx={(theme) => navbarRow(theme, { isMini })}>
                    <Box
                        color={light ? "white" : "inherit"}
                        sx={(theme) => navbarProfile(theme, { isMini })}
                    >
                        <Box display="flex" alignItems="center">
                            <Box mr={2}>
                                <BellNotification
                                    key={"bell_notifi"}
                                    totalUnRead={totalUnreadNotifi || 0}
                                    onRead={(data) => { }}
                                    getTotalUnRead={(total) => {
                                        dispatchRedux(setTotalUnreadNotifi(total));
                                    }}
                                    isShowPopupDetail={(data) => {
                                        return (
                                            data.type === MessageType.Email ||
                                            data.requestType === MessageRequestType.Invitation
                                        );
                                    }}
                                />
                            </Box>

                            <UserProfileDropdown
                                light={light}
                                anchorEl={anchorEl}
                                openDropDown={openDropDown}
                                setOpenDropDown={setOpenDropDown}

                                uncheckedOrganization={true}

                                pathNameProfile={Screens.PROFILE}
                                onNavigate={(pathName) => { navigate(pathName); }}

                                onLogout={() => { handleLogout(); }}
                                onChangeOrganization={(orgId) => {
                                    onChangeOrganization(orgId);
                                }}
                                onSubmitManageOrganization={() => {
                                    Helpers.setItemInLocalStorage(
                                        Constants.StorageKeys.ORGANIZATION_ID,
                                        userProfile?.organizationId
                                    );
                                    dispatchRedux(fetchUserInfo());
                                }}
                            />

                            <IconButton
                                size="small"
                                disableRipple
                                color="inherit"
                                sx={navbarMobileMenu}
                                onClick={handleMiniSidenav}
                            >
                                <Icon sx={iconsStyle} fontSize="medium">
                                    {isMini
                                        ? <MenuRounded sx={{ color: Constants.Styles.BLACK_COLOR }} />
                                        : <MenuOpenRounded sx={{ color: Constants.Styles.BLACK_COLOR }} />
                                    }
                                </Icon>
                            </IconButton>
                        </Box>
                    </Box>
                </Box>
            </Toolbar>
        </AppBar>
    );
}

Navbar.defaultProps = {
    absolute: false,
    light: false,
    isMini: false,
};

export default Navbar;
