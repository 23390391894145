import { Add } from '@mui/icons-material';
import { Card, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Avatar, Box, Button, DataTable, Typography, useCommonComponentContext } from "@maysoft/common-component-react";

import Helpers from "commons/helpers";
import Screens from "constants/screens";
import Strings from "constants/strings";
import Constants from '../../constants';
import CustomStatusCell from 'components/CustomStatusCell';
import TenantService from 'services/identity/tenant.service';

import { RootState } from 'store';
import { Mode } from 'constants/enum';
import { DashboardLayout } from "layout";
import { setDataAlert } from 'store/slice/message.slice';
import { showLoading } from "store/slice/loadingAPI.slice";
import { setListPathName } from 'store/slice/titleRoute.slice';

interface IRecordTenant {
    tenantCode: string
    name: string
    description: string
    logoId: string
    logoUrl: string
    id: string
    status: number
    createTime: any
    createUser: string
    updateTime: string
    updateUser: string
}

interface IModel {
    searchText?: string;

    pageSize?: number;
    pageNumber?: number;
    totalCount?: number;
}

const tenantService = new TenantService();

const TenantScreen = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();
    const { getResourcePermissions } = useCommonComponentContext();

    const [model, setModel] = useState<IModel>({});
    const [modelTemp, setModelTemp] = useState<IModel>({});
    const [tableData, setTableData] = useState<IRecordTenant[]>([]);

    const listPathName = useSelector((state: RootState) => state.titleRoute.listPathName);
    const resourcePermissions = getResourcePermissions(Constants.ResourceCode.MANAGE_TENANT);

    useEffect(() => {
        const pageNumber = Number(searchParams.get("pageNumber") || 1);
        const pageSize = Number(searchParams.get("pageSize") || Constants.ROW_PER_PAGE);
        const item = listPathName.find(el => el.pathName === Screens.TENANT_LIST);

        getPage({ pageNumber, pageSize, totalCount: item?.totalCount, });
    }, []);

    const getPage = async (data?: IModel) => {
        try {
            dispatch(showLoading(true));
            const pageSize = data?.pageSize || Constants.ROW_PER_PAGE;
            const pageNumber = Helpers.getPageNumber(
                data?.pageNumber || 1,
                pageSize,
                data?.totalCount || 0,
            );

            const dataGetpage: any = {
                searchText: data?.searchText,
                pageNumber,
                pageSize,
            };

            const dataRows: any[] = [];
            const result = await tenantService.getPaged(Helpers.handleFormatParams(dataGetpage));
            (result.items || []).forEach((item: any) => {
                dataRows.push(item);
            });

            setTableData(dataRows);
            setModel({ ...data, totalCount: result.totalCount || 0 });

            let query = `?pageNumber=${pageNumber}&pageSize=${dataGetpage.pageSize}`;

            navigate(Screens.TENANT_LIST + query, { replace: true });

            dispatch(setListPathName({ pathname: Screens.TENANT_LIST, query, totalCount: result.totalCount }));
            dispatch(showLoading(false));
        } catch (error) {
            dispatch(showLoading(false));
            const e = Helpers.renderExceptionError(error);
            dispatch(setDataAlert({ message: e, type: "error" }));
        }
    }

    const onValueChange = (key: string, value: any) => {
        let requestDataTemp: any = { ...modelTemp };
        requestDataTemp[key] = value;
        setModelTemp(requestDataTemp);
    }

    const handleDelete = (id: string) => {
        Helpers.showConfirmAlert(Strings.Message.CONFIRM_DELETE, async () => {
            try {
                dispatch(showLoading(true));

                await tenantService.delete(id);

                dispatch(setDataAlert({ message: Strings.Message.DELETE_SUCCESS, type: "success" }));

                await getPage(model);

                dispatch(showLoading(false));
            } catch (error: any) {
                dispatch(showLoading(false));
                const e = Helpers.renderExceptionError(error);
                dispatch(setDataAlert({ message: e, type: "error" }));
            }
        });
    }

    return (
        <DashboardLayout
            isPermission={resourcePermissions.canRead}
            title={Strings.TENANT.TITLE_LIST_VIEW}
            route={[{ title: Strings.TENANT.TITLE_MENU, route: "" }]}
        >
            <Grid container spacing={2}>
                {
                    resourcePermissions.canCreate &&
                    <Grid item xs={12}>
                        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "end" }}>
                            <Button onClick={() => navigate(Screens.TENANT_CREATE)}>
                                <Add />&nbsp;
                                {Strings.Common.ADD_NEW}
                            </Button>
                        </Box>
                    </Grid>
                }
                <Grid item xs={12}>
                    <Card>
                        <Box p={2}>
                            <DataTable
                                loading={false}
                                totalCount={model.totalCount}
                                pageNumber={model.pageNumber}
                                rowPerPage={model.pageSize || Constants.ROW_PER_PAGE}
                                onChangePageSize={(pageSize) => getPage({ ...model, pageSize })}
                                onChangePageNumber={(pageNumber) => getPage({ ...model, pageNumber })}

                                searchText={model.searchText || ""}
                                placeholderSearch={Strings.TENANT.SEARCH_TEXT}
                                onSearch={(val) => getPage(val)}
                                onSearchChange={(val) => onValueChange("searchText", val)}

                                table={{
                                    rows: tableData || [],
                                    columns: [
                                        {
                                            Header: Strings.TENANT.TITLE_MENU, accessor: "name", width: "250px",
                                            Cell: (row) => (
                                                <Box display="flex" alignItems="center">
                                                    <Avatar
                                                        alt="avt-staff"
                                                        text={row.value || ""}
                                                        src={row.row?.original?.logoUrl}
                                                        sx={{
                                                            width: "36px",
                                                            height: "36px",
                                                            objectFit: "cover",
                                                            marginRight: "8px",
                                                            borderRadius: "50%",
                                                        }}
                                                    />
                                                    <Box display="grid">
                                                        <Typography
                                                            color="secondary"
                                                            variant="caption"
                                                        >
                                                            code: {row?.row?.original?.tenantCode || ""}
                                                        </Typography>
                                                        <Typography
                                                            color="text"
                                                            variant="caption"
                                                            fontWeight="medium"
                                                        >
                                                            {row?.value || ""}
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            )
                                        },
                                        { Header: Strings.TENANT.DESCRIPTION, accessor: "description", width: "200px", },
                                        {
                                            Header: Strings.Common.STATUS, accessor: "status", width: "150px",
                                            Cell: ({ value }: any) => <CustomStatusCell status={value} />
                                        },
                                    ],
                                }}

                                actionList={(row) => [
                                    {
                                        key: "detail",
                                        iconName: "view",
                                        title: Strings.Common.VIEW_ALL,
                                        callback: (row) => navigate(Screens.TENANT_EDIT + `?id=${row.id}&mode=${Mode.View}`),
                                    },
                                    resourcePermissions.canUpdate && {
                                        key: "edit",
                                        iconName: "edit",
                                        title: Strings.Common.EDIT,
                                        callback: (row) => navigate(Screens.TENANT_EDIT + `?id=${row.id}&mode=${Mode.Update}`),
                                    },
                                    resourcePermissions.canDelete && {
                                        key: "delete",
                                        iconName: "delete",
                                        title: Strings.Common.DELETE,
                                        callback: (row) => handleDelete(row.id),
                                    },
                                ]}
                            />
                        </Box>
                    </Card>
                </Grid>
            </Grid>
        </DashboardLayout>
    )
}

export default TenantScreen;