import Constants from "constants/index";
import IdentityService from "./indentity.service";

class ClientService extends IdentityService {
    public getAll = async (data?: any) => {
        const result = await this.api.get({
            path: Constants.ApiPath.CLIENT.GET_ALL,
            query: data,
        });
        return result.data.result;
    }

    public getPaged = async (data: any) => {
        const result = await this.api.get({
            path: `${Constants.ApiPath.CLIENT.GET_PAGED}`,
            query: data
        });
        return result.data.result;
    }

    public getDetail = async (id: string) => {
        const result = await this.api.get({
            path: Constants.ApiPath.CLIENT.GET_DETAIL + `/${id}`,
        });
        return result.data.result;
    }

    public create = async (data: any) => {
        const result = await this.api.post({
            path: Constants.ApiPath.CLIENT.CREATE,
            data: data
        });
        return result.data;
    }

    public update = async (data: any) => {
        const result = await this.api.post({
            path: Constants.ApiPath.CLIENT.UPDATE,
            data: data
        });
        return result.data;
    }

    public delete = async (id: string) => {
        const result = await this.api.delete({
            path: Constants.ApiPath.CLIENT.DELETE + `/${id}`,
        });
        return result.data;
    }
}

export default ClientService;