import Constants from "constants/index";
import MessageService from "./message.service";

class NotificationService extends MessageService {
    public getPaged = async (data: any) => {
        const result = await this.api.get({
            path: Constants.ApiPath.NOTIFICATION.GET_PAGED,
            query: data,
        })
        return result.data.result;
    }

    public getDetailNotification = async (messageId: string) => {
        const result = await this.api.get({
            path: `${Constants.ApiPath.NOTIFICATION.DETAIL_MESSAGE}/${messageId}`,
        })
        return result.data.result;
    }

    public readAllNotification = async () => {
        const result = await this.api.post({
            path: Constants.ApiPath.NOTIFICATION.READ_ALL_MESSAGE,
        })
        return result.data;
    }

    public readNotificationById = async (messageId: string) => {
        const result = await this.api.post({
            path: `${Constants.ApiPath.NOTIFICATION.READ_MESSAGE}/${messageId}`,
        })
        return result.data;
    }

    public deleteAllNotification = async () => {
        const result = await this.api.delete({
            path: Constants.ApiPath.NOTIFICATION.DELETE_ALL_MESSAGE,
        })
        return result.data;
    }

    public registerFirebase = async (data: {
        deviceId: string,
        deviceInfo: string
    }) => {
        const result = await this.api.post({
            path: Constants.ApiPath.NOTIFICATION.REGISTER,
            data: data
        });
        return result.data;
    }

    public unRegisterFirebase = async (deviceId: any) => {
        const result = await this.api.delete({
            path: `${Constants.ApiPath.NOTIFICATION.UN_REGISTER}/${deviceId}`,
        })
        return result.data;
    }
}

export default NotificationService