import Constants from "constants/index";
import IdentityService from "./indentity.service";

class TenantService extends IdentityService {
    public getAll = async (data?: any) => {
        const result = await this.api.get({
            path: `${Constants.ApiPath.TENANT.GET_ALL}?${data ? data : ""}`,
        });
        return result.data.result;
    }

    public getPaged = async (data: any) => {
        const result = await this.api.get({
            path: `${Constants.ApiPath.TENANT.GET_PAGED}?${data}`,
        });
        return result.data.result;
    }

    public getDetail = async (id: string) => {
        const result = await this.api.get({
            path: Constants.ApiPath.TENANT.GET_DETAIL + `/${id}`,
        });
        return result.data.result;
    }

    public create = async (data: any) => {
        const result = await this.api.post({
            path: Constants.ApiPath.TENANT.CREATE,
            data: data
        });
        return result.data;
    }

    public update = async (data: any) => {
        const result = await this.api.post({
            path: Constants.ApiPath.TENANT.UPDATE,
            data: data
        });
        return result.data
    }

    public delete = async (id: string) => {
        const result = await this.api.delete({
            path: Constants.ApiPath.TENANT.DELETE + `/${id}`,
        });
        return result.data;
    }

}

export default TenantService;