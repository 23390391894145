import React from 'react';
import Popper from '@mui/material/Popper';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import ListSubheader from '@mui/material/ListSubheader';

import { Icon } from '@mui/material';
import { useTheme, styled } from '@mui/material/styles';
import { VariableSizeList, ListChildComponentProps } from 'react-window';
import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete';

import Helpers from 'commons/helpers';
import { ICodename } from 'commons/interfaces';
import { Box, FormField } from "@maysoft/common-component-react";

interface IProps {
    label?: string;
    placeholder?: string;
    required?: boolean;
    multiple?: boolean;
    disabled?: boolean;
    isSelectedBox?: boolean;

    data: ICodename[];
    defaultValue?: any,
    errorMessage?: string;

    onChange: (value: any) => void;
}

const AutocompleteIconMUI: React.FunctionComponent<IProps> = ({
    data,
    label,
    errorMessage,
    required,
    multiple,
    placeholder,
    defaultValue,
    disabled,
    isSelectedBox,
    onChange
}: IProps) => {
    const formatValue = (v: any) => {
        if (data.length > 0) {
            if (multiple || Array.isArray(v)) {
                if ([...v || []].length > 0) {
                    const selectedOption: ICodename[] = []
                    v.forEach((item: any) => {
                        const temp = data.find(el => el.code == item)
                        temp && selectedOption.push(temp)
                    })
                    return selectedOption
                }
            } else {
                const selectedOption = data.find(el => el.code == v)
                return selectedOption || { code: "", name: "" };
            }
        } else {
            return multiple ? [] : undefined;;
        }
    }

    return <Autocomplete
        id={"auto_" + label}
        key={defaultValue as any || data as any}
        isOptionEqualToValue={(option, value) => (Array.isArray(option) ? option[0] : option)?.code === (Array.isArray(value) ? value[0] : value)?.code}
        // PopperComponent={StyledPopper}
        // groupBy={(option) => ((Array.isArray(option)) ? option[0] : option).name.toUpperCase()}
        // TODO: Post React 18 update - validate this conversion, look like a hidden bug
        // renderGroup={(params) => params as unknown as React.ReactNode}
        ListboxComponent={ListboxComponent}
        renderOption={(props, option, state) =>
            [props, ((Array.isArray(option)) ? option[0] : option).name, state.index] as React.ReactNode
        }

        disableListWrap
        options={data}
        multiple={multiple}
        disabled={disabled}
        disableClearable={isSelectedBox}
        value={formatValue(defaultValue)}
        getOptionLabel={(option: any) => option.name || ""}
        renderInput={(params) => (
            <Box display="flex" alignItems="end" p={0}>
                <FormField
                    {...params}
                    size="small"
                    label={label}
                    maxLength={255}
                    disabled={disabled}
                    required={required}
                    placeholder={placeholder}
                    helperText={errorMessage}
                    error={!Helpers.isNullOrEmpty(errorMessage)}
                    InputLabelProps={{ shrink: true }}
                />
                {
                    !Helpers.isNullOrEmpty(defaultValue) &&
                    <Box bgColor="#ddd" display="flex" borderRadius="4px">
                        <Icon style={{ fontSize: "40px" }}>{defaultValue}</Icon>
                    </Box>
                }
            </Box>
        )}
        onChange={(e: any, selectedOption: any) => {
            if (multiple && Array.isArray(selectedOption)) {
                let result: string[] = [];
                for (let items of selectedOption) {
                    result.push(items.code);
                }
                onChange(result)
            } else {
                onChange(selectedOption ? selectedOption?.code : undefined)
            }
        }}
    />
}

export default AutocompleteIconMUI;


const LISTBOX_PADDING = 8; // px
function renderRow(props: ListChildComponentProps) {
    const { data, index, style } = props;
    const dataSet = data[index];
    const inlineStyle = {
        ...style,
        top: (style.top as number) + LISTBOX_PADDING,
    };

    if (dataSet.hasOwnProperty('group')) {
        return (
            <ListSubheader key={dataSet.key} component="div" style={inlineStyle}>
                {dataSet.group}
            </ListSubheader>
        );
    }

    return (
        <Typography component="li" {...dataSet[0]} noWrap style={inlineStyle}>
            {/* {`#${dataSet[2] + 1} - ${dataSet[1]}`} */}
            <Icon style={{ marginRight: "8px" }}>{dataSet[1]}</Icon>{dataSet[1]}
        </Typography>
    );
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
    const outerProps = React.useContext(OuterElementContext);
    return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data: any) {
    const ref = React.useRef<VariableSizeList>(null);
    React.useEffect(() => {
        if (ref.current != null) {
            ref.current.resetAfterIndex(0, true);
        }
    }, [data]);
    return ref;
}

// Adapter for react-window
const ListboxComponent = React.forwardRef<
    HTMLDivElement,
    React.HTMLAttributes<HTMLElement>
>(function ListboxComponent(props, ref) {
    const { children, ...other } = props;
    const itemData: React.ReactChild[] = [];
    (children as React.ReactChild[]).forEach(
        (item: React.ReactChild & { children?: React.ReactChild[] }) => {
            itemData.push(item);
            itemData.push(...(item.children || []));
        },
    );

    const theme = useTheme();
    const smUp = useMediaQuery(theme.breakpoints.up('sm'), {
        noSsr: true,
    });
    const itemCount = itemData.length;
    const itemSize = smUp ? 36 : 48;

    const getChildSize = (child: React.ReactChild) => {
        if (child.hasOwnProperty('group')) {
            return 48;
        }

        return itemSize;
    };

    const getHeight = () => {
        if (itemCount > 8) {
            return 8 * itemSize;
        }
        return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
    };

    const gridRef = useResetCache(itemCount);

    return (
        <div ref={ref}>
            <OuterElementContext.Provider value={other}>
                <VariableSizeList
                    itemData={itemData}
                    height={getHeight() + 2 * LISTBOX_PADDING}
                    width="100%"
                    ref={gridRef}
                    outerElementType={OuterElementType}
                    innerElementType="ul"
                    itemSize={(index: number) => getChildSize(itemData[index])}
                    overscanCount={5}
                    itemCount={itemCount}
                >
                    {renderRow}
                </VariableSizeList>
            </OuterElementContext.Provider>
        </div>
    );
});

// const StyledPopper = styled(Popper)({
//     [`& .${autocompleteClasses.listbox}`]: {
//         boxSizing: 'border-box',
//         '& ul': {
//             padding: 0,
//             margin: 0,
//         },
//     },
// });