const Resources = {
    Images: {
        EN: require("../assets/images/en.png"),
        VN: require("../assets/images/vi.png"),
        LOGO: require("../assets/images/logoApp.png"),
        THUMBNAIL: require("../assets/images/thumbnail.png"),
        AVATAR_THUMBNAIL: require("../assets/images/avatar_thumbnail.png"),
        ORGANIZATION_THUMBNAIL: require("../assets/images/store-thumbnail.jpg"),
    },
};

export default Resources;
