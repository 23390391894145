import moment from "moment";
import { Card, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

import Helpers from "commons/helpers";
import Constants from "constants/index";
import Strings from "constants/strings";
import Screens from "constants/screens";
import EventService from "services/identity/events.service";

import { RootState } from "store";
import { DashboardLayout } from "layout";
import { ICodename } from "commons/interfaces";
import { showLoading } from "store/slice/loadingAPI.slice";
import { setListPathName } from "store/slice/titleRoute.slice";
import { EventType, Mode, ProcessStatus, Status } from "constants/enum";
import { Box, DataTable, DatePicker, Chip, Autocomplete, FormField, DataTableStatusCell, Typography, useCommonComponentContext } from "@maysoft/common-component-react";
import { setDataAlert } from "store/slice/message.slice";



export interface IRecordEvent {
    messageId: string
    type: number
    eventCode: string
    eventName: string
    eventData: string
    processStatus: number
    processNumber: number
    sendTime: string
    retryCount: number
    id: string
    status: number
    createTime: string
    createUser: string
    updateTime: string
    updateUser: string
}

interface IRequestGetpaged {
    type?: string;
    endRange?: any;
    startRange?: any;
    searchText?: string;
    processStatuses?: string[];

    pageSize?: number;
    pageNumber?: number;
    totalCount?: number;
    orderby?: string;
}

interface IModel {
    requestData?: IRequestGetpaged;
    requestDataTemp?: IRequestGetpaged;
}

const eventService = new EventService();

export const statusCellEvent = (status: number) => {
    let data: any;

    switch (status) {
        case ProcessStatus.Failure:
            data = { icon: "clear", color: "error", title: Strings.EVENT.FAILURE };
            break;
        case ProcessStatus.Pending:
            data = { icon: "report", color: "secondary", title: Strings.EVENT.PENDING };
            break;
        case ProcessStatus.Processing:
            data = { icon: "sync", color: "warning", title: Strings.EVENT.PROCESSING };
            break;
        default:
            data = { icon: "done", color: "success", title: Strings.EVENT.SUCCESS };
            break;
    };

    return <DataTableStatusCell data={data} />;
}

export const listTypeEvent: ICodename[] = [
    { code: `${EventType.Receive}`, name: Strings.EVENT.RECEIVE },
    { code: `${EventType.Send}`, name: Strings.EVENT.SEND },
];

export const listProcessStatusesEvent: ICodename[] = [
    { code: `${ProcessStatus.Pending}`, name: Strings.EVENT.PENDING },
    { code: `${ProcessStatus.Processing}`, name: Strings.EVENT.PROCESSING },
    { code: `${ProcessStatus.Success}`, name: Strings.EVENT.SUCCESS },
    { code: `${ProcessStatus.Failure}`, name: Strings.EVENT.FAILURE },
];

const EventScreen = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const { getResourcePermissions } = useCommonComponentContext();

    const resourcePermissions = getResourcePermissions(Constants.ResourceCode.EVENT);
    const listPathName = useSelector((state: RootState) => state.titleRoute.listPathName);

    const [model, setModel] = useState<IModel>({} as IModel);
    const [dataRows, setDataRows] = useState<IRecordEvent[]>([]);

    useEffect(() => {
        const item = listPathName.find(el => el.pathName === Screens.MANAGER_EVENT);

        let data: IRequestGetpaged = {
            type: searchParams.get("type"),
            endRange: searchParams.get("endRange"),
            startRange: searchParams.get("startRange"),
            searchText: searchParams.get("searchText"),
            processStatuses: searchParams.getAll("processStatuses"),
            pageNumber: Number(searchParams.get("pageNumber") || 1),
            pageSize: Number(searchParams.get("pageSize") || Constants.ROW_PER_PAGE),
            totalCount: item?.totalCount || 0,
        };

        getPaged(data);
    }, []);

    const getPaged = async (data?: IRequestGetpaged) => {
        try {
            dispatch(showLoading(true));

            const pageSize = data?.pageSize || Constants.ROW_PER_PAGE;
            const pageNumber = Helpers.getPageNumber(data?.pageNumber || 1, pageSize, data?.totalCount || 0);

            const newReq: any = {
                pageSize: pageSize,
                pageNumber: pageNumber,

                searchText: data?.searchText,
                processStatuses: data?.processStatuses,
                type: Helpers.isNullOrEmpty(data?.type) ? undefined : Number(data?.type),
                endRange: Helpers.isNullOrEmpty(data?.endRange) ? undefined : Number(data?.endRange),
                startRange: Helpers.isNullOrEmpty(data?.startRange) ? undefined : Number(data?.startRange),
            };

            const result = await eventService.getPaged(Helpers.handleFormatParams(newReq));

            const dataRowsTemp: IRecordEvent[] = [];
            [...result?.items || []].forEach((item: any) => {
                dataRowsTemp.push({ ...item });
            });

            setDataRows(dataRowsTemp);
            setModel({
                requestData: { ...newReq, totalCount: result?.totalCount || 0 },
                requestDataTemp: { ...newReq, totalCount: result?.totalCount || 0 },
            });

            let query: string = `?pageNumber=${newReq.pageNumber}&pageSize=${newReq.pageSize}`;
            if (!Helpers.isNullOrEmpty(newReq.searchText)) {
                query += `&searchText=${newReq.searchText}`;
            }
            if (!Helpers.isNullOrEmpty(newReq.endRange)) {
                query += `&endRange=${newReq.endRange}`;
            }
            if (!Helpers.isNullOrEmpty(newReq.startRange)) {
                query += `&startRange=${newReq.startRange}`;
            }
            if (!Helpers.isNullOrEmpty(newReq.type)) {
                query += `&type=${newReq.type}`;
            }

            [...newReq.processStatuses || []].forEach(el => {
                query += `&processStatuses=${el}`;
            });

            navigate(`${Screens.MANAGER_EVENT}${query}`);

            dispatch(setListPathName({ pathname: Screens.MANAGER_EVENT, query, totalCount: result.totalCount }));
            dispatch(showLoading(false));
        } catch (error) {
            dispatch(showLoading(false));
        }
    }

    const renderValueDate = (value: any) => {
        return (Helpers.isNullOrEmpty(value) || value === "0" || value === 0)
            ? undefined : Number(value) * 1000;
    }

    const onChangeValue = (value: any, key: string) => {
        let dataTemp: any = { ...model.requestDataTemp };
        dataTemp[key] = value;
        setModel({ ...model, requestDataTemp: dataTemp });
    }

    const filterForm = () => {
        return (
            <Box>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <FormField
                            maxLength={255}
                            label={Strings.EVENT.SEARCH_TEXT}
                            placeholder={Strings.EVENT.ENTER_SEARCH_TEXT}
                            defaultValue={model.requestDataTemp?.searchText}
                            onBlur={(value) => onChangeValue(value, "searchText")}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Autocomplete
                            data={listTypeEvent || []}
                            label={Strings.EVENT.EVENT_TYPE}
                            defaultValue={model.requestDataTemp?.type}
                            placeholder={Strings.EVENT.SELECT_EVENT_TYPE}
                            onChange={(value) => {
                                onChangeValue(value, "type")
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Autocomplete
                            multiple
                            label={Strings.EVENT.PROCESS_STATUS}
                            data={listProcessStatusesEvent || []}
                            placeholder={Strings.EVENT.SELECT_PROCESS_STATUS}
                            defaultValue={model.requestDataTemp?.processStatuses}
                            onChange={(value) => {
                                onChangeValue(value, "processStatuses");
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <DatePicker
                            label={Strings.INVITATION.VALID_FROM}
                            placeholder={Strings.INVITATION.VALID_FROM}
                            value={renderValueDate(model.requestDataTemp?.startRange)}
                            onChangeValue={(value: any) => {
                                onChangeValue(value ? moment(value).unix() : undefined, "startRange")
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <DatePicker
                            label={Strings.INVITATION.VALID_TO}
                            placeholder={Strings.INVITATION.VALID_TO}
                            value={renderValueDate(model.requestDataTemp?.endRange)}
                            onChangeValue={(value: any) => {
                                onChangeValue(value ? moment(value).unix() : undefined, "endRange")
                            }}
                        />
                    </Grid>
                </Grid>
            </Box>
        );
    };

    const handlRetry = async (id: string) => {
        Helpers.showConfirmAlert(Strings.EVENT.CONFIRM_RETRY, async () => {
            try {
                dispatch(showLoading(true));

                const result = await eventService.retry(id);

                if (result.statusCode === Constants.ApiCode.SUCCESS) {
                    await getPaged(model.requestData);
                    dispatch(setDataAlert({ message: Strings.EVENT.RETRY_SUCCESS, type: "success" }));
                }

                dispatch(showLoading(false));
            } catch (error) {
                dispatch(showLoading(false));
                const e = Helpers.renderExceptionError(error);
                dispatch(setDataAlert({ message: e, type: "error" }));
            }
        });
    };

    return (
        <DashboardLayout
            isPermission
            title={Strings.EVENT.TITLE_LIST_VIEW}
            route={[{ title: Strings.EVENT.TITLE_MENU, route: "" }]}
        >
            {(
                !Helpers.isNullOrEmpty(model.requestData?.type) ||
                !Helpers.isNullOrEmpty(model.requestData?.endRange) ||
                !Helpers.isNullOrEmpty(model.requestData?.startRange) ||
                !Helpers.isNullOrEmpty(model.requestData?.processStatuses)
            ) &&
                <Box mb={1}>
                    {
                        !Helpers.isNullOrEmpty(model.requestData?.startRange) &&
                        <Chip
                            label={Strings.INVITATION.VALID_FROM}
                            value={Helpers.formatDate(renderValueDate(model.requestData?.startRange))}
                            onDelete={() => {
                                getPaged({ ...model.requestData, startRange: undefined });
                            }}
                        />
                    }
                    {
                        !Helpers.isNullOrEmpty(model.requestData?.endRange) &&
                        <Chip
                            label={Strings.INVITATION.VALID_TO}
                            value={Helpers.formatDate(renderValueDate(model.requestData?.endRange))}
                            onDelete={() => {
                                getPaged({ ...model.requestData, endRange: undefined });
                            }}
                        />
                    }
                    {
                        !Helpers.isNullOrEmpty(model.requestData?.type) &&
                        <Chip
                            label={Strings.EVENT.EVENT_TYPE}
                            value={listTypeEvent.find(el => el.code === `${model.requestData?.type}`)?.name}
                            onDelete={() => {
                                getPaged({ ...model.requestData, type: undefined });
                            }}
                        />
                    }
                    {
                        [...model.requestData?.processStatuses || []].map(value => (
                            <Chip
                                label={Strings.EVENT.PROCESS_STATUS}
                                value={listProcessStatusesEvent.find(el => el.code === value.toString())?.name}
                                onDelete={() => {
                                    const temp = [...model.requestData?.processStatuses || []].filter(el => el !== value);
                                    getPaged({ ...model.requestData, processStatuses: temp });
                                }}
                            />
                        ))
                    }
                </Box>
            }
            <Card>
                <Box p={2}>
                    <DataTable
                        loading={false}
                        totalCount={model.requestData?.totalCount}
                        pageNumber={model.requestData?.pageNumber || 1}
                        rowPerPage={model.requestData?.pageSize || Constants.ROW_PER_PAGE}
                        onChangePageSize={(pageSize) => getPaged({ ...model.requestData, pageSize })}
                        onChangePageNumber={(pageNumber) => getPaged({ ...model.requestData, pageNumber })}

                        searchText={model.requestData?.searchText}
                        placeholderSearch={Strings.EVENT.SEARCH_TEXT}
                        onSearch={(val) => {
                            getPaged({ ...model.requestData, pageNumber: 1, searchText: val })
                        }}
                        onSearchChange={(val: string) => {
                            setModel({ requestData: { ...model.requestData, searchText: val } })
                        }}

                        filterForm={filterForm()}
                        onFilter={() => {
                            getPaged({ ...model.requestDataTemp });
                        }}
                        onCloseFilter={() => {
                            const dataTemp = { ...model.requestData };
                            setModel({ ...model, requestDataTemp: dataTemp });
                        }}
                        onReset={() => {
                            const dataTemp: IRequestGetpaged = {
                                ...model.requestData,
                                processStatuses: undefined,
                                searchText: undefined,
                                startRange: undefined,
                                endRange: undefined,
                                type: undefined,
                            };
                            getPaged({ ...dataTemp });
                        }}

                        table={{
                            rows: dataRows || [],
                            columns: [
                                {
                                    Header: Strings.EVENT.EVENT_CODE, accessor: "eventCode", width: "100px",
                                },
                                {
                                    Header: Strings.EVENT.EVENT_NAME, accessor: "eventName", width: "200px",
                                },
                                {
                                    Header: Strings.EVENT.SEND_TIME, accessor: "sendTime", width: "120px",
                                    Cell: (row: any) => {
                                        const dateStr = moment(Number(row.value) * 1000).format(Constants.DateFormat.DDMMYYYY);
                                        const timeStr = moment(Number(row.value) * 1000).format("HH:mm:ss");
                                        return (
                                            <Box display="grid">
                                                <Typography
                                                    color="text"
                                                    variant="caption"
                                                    fontWeight="medium"
                                                >
                                                    {dateStr}
                                                </Typography>
                                                <Typography
                                                    color="secondary"
                                                    variant="caption"
                                                >
                                                    {timeStr}
                                                </Typography>
                                            </Box>
                                        );
                                    }
                                },
                                {
                                    Header: Strings.EVENT.EVENT_TYPE, accessor: "type", width: "100px",
                                    Cell: (row: any) => <>{listTypeEvent.find(el => el.code === `${row.value}`)?.name || ""}</>
                                },
                                {
                                    Header: Strings.EVENT.PROCESS_STATUS, accessor: "processStatus", width: "150px",
                                    Cell: (row: any) => statusCellEvent(Number(row.value)),
                                },
                            ],
                        }}

                        actionList={(row) => [
                            {
                                key: "detail",
                                iconName: "view",
                                title: Strings.Common.VIEW_ALL,
                                callback: (row) => {
                                    navigate(Screens.MANAGER_EVENT_DETAIL, { state: { mode: Mode.View, dataDetail: row } })
                                },
                            },

                            resourcePermissions.canUpdate &&
                            (row?.processStatus === ProcessStatus.Failure) &&
                            {
                                key: "edit",
                                iconName: "edit",
                                title: Strings.Common.EDIT,
                                callback: (row) => {
                                    navigate(Screens.MANAGER_EVENT_DETAIL, { state: { mode: Mode.Update, dataDetail: row } })
                                },
                            },

                            resourcePermissions.canUpdate &&
                            (row?.processStatus === ProcessStatus.Failure) &&
                            {
                                key: "retry",
                                iconName: "resend",
                                title: Strings.EVENT.RETRY,
                                callback: (row) => { handlRetry(row.id); },
                            },
                        ]}
                    />
                </Box>
            </Card>
        </DashboardLayout >
    )
}

export default EventScreen;