import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ModuleResource, useCommonComponentContext } from "@maysoft/common-component-react";

import Helpers from "commons/helpers";
import Constants from "constants/index";
import Screens from "constants/screens";
import Strings from "constants/strings";

import { RootState } from "store";
import { DashboardLayout } from "layout";
import { Mode, ResourceType } from "constants/enum";
import { setListPathName } from "store/slice/titleRoute.slice";

interface RequestData {
    clientId?: string;
    resourceType: number;
    organizationId?: string;
    parentResource?: string;
    serviceCode: string;
    statuses?: number[];
    searchText?: string;
    pageNumber: number;
    pageSize?: number;
    orderBy?: string;
    totalCount?: number;
}

const ModuleScreen = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();
    const { getResourcePermissions } = useCommonComponentContext();

    const idDetail: string = searchParams.get("id");
    const modeList = Screens.MODULE_LIST.includes(window.location.pathname);
    const pramsMode = !Helpers.isNullOrEmpty(searchParams.get("mode")) ? Number(searchParams.get("mode")) : undefined;

    const resourcePermissions = getResourcePermissions(Constants.ResourceCode.MODULE);
    const listPathName = useSelector((state: RootState) => state.titleRoute.listPathName);

    const item = listPathName.find((el) => el.pathName === Screens.MODULE_LIST);

    const [mode, setMode] = useState<Mode>(pramsMode || Mode.View);

    const title = useMemo(() => {
        if (Helpers.isNullOrEmpty(idDetail)) return Strings.MODULE.TITLE_CREATE_VIEW;
        return mode === Mode.Update ? Strings.MODULE.TITLE_UPDATE_VIEW : Strings.MODULE.TITLE_DETAIL_VIEW;
    }, [mode, idDetail]);

    const route = useMemo(() => {
        let temp = [{ title: Strings.MODULE.TITLE_MENU, route: Screens.MODULE_LIST }];
        if (Helpers.isNullOrEmpty(idDetail)) return [...temp, { title: Strings.Common.CREATE_NEW, route: "" }];
        return [...temp, { title: mode === Mode.Update ? Strings.Common.UPDATE : Strings.Common.DETAIL, route: "" }];
    }, [mode, idDetail]);

    const [requestData] = useState<RequestData>({
        searchText: searchParams.get("searchText"),
        parentResource: searchParams.get("parentResource"),
        clientId: searchParams.get("clientId"),
        serviceCode: searchParams.get("serviceCode") || Constants.SERVICE_CODE.IDENTITY,
        pageNumber: Number(searchParams.get("pageNumber") || 1),
        pageSize: Number(searchParams.get("pageSize") || Constants.ROW_PER_PAGE),
        totalCount: item?.totalCount,
        resourceType: ResourceType.Module,
    } as RequestData);

    useEffect(() => {
        if (Helpers.isNullOrEmpty(idDetail)) {
            setMode(Mode.Create);
        } else {
            const newmode = resourcePermissions.canUpdate ? (pramsMode || Mode.Update) : Mode.View;
            setMode(newmode);
            navigate(Screens.MODULE_EDIT + `?id=${idDetail}&mode=${newmode}`, { replace: true });
        }
    }, [idDetail, pramsMode]);

    const handleGoBack = () => {
        const itemP = listPathName.find((el) => el.pathName === Screens.MODULE_LIST);
        navigate(Screens.MODULE_LIST + (itemP ? itemP.query : ""));
    };

    return (
        <DashboardLayout
            isPermission={resourcePermissions.canRead}
            title={modeList ? Strings.MODULE.TITLE_LIST_VIEW : title}
            route={modeList ? [{ title: Strings.MODULE.TITLE_MENU, route: "" }] : route}
        >
            <ModuleResource
                hidenActionCreate={!resourcePermissions.canCreate}
                hidenActionDelete={!resourcePermissions.canDelete}
                hidenActionUpdate={!resourcePermissions.canUpdate}

                keyModuleContainer={{
                    list: { pathName: Screens.MODULE_LIST },
                    detail: { pathName: Screens.MODULE_EDIT },
                    create: { pathName: Screens.MODULE_CREATE },
                }}
                requestGetPaged={requestData}
                onGetPaged={({ query, totalCount }) => {
                    dispatch(setListPathName({ pathname: Screens.MODULE_LIST, query, totalCount: totalCount }));
                    navigate(Screens.MODULE_LIST + query, { replace: true });
                }}
                onNavigate={(data) => {
                    if (data.id) {
                        navigate(Screens.MODULE_EDIT + `?id=${data.id}&mode=${data.mode}`);
                    } else {
                        const query = Helpers.handleFormatParams({
                            clientId: searchParams.get("clientId"),
                            serviceCode: searchParams.get("serviceCode") || Constants.SERVICE_CODE.IDENTITY,
                        });
                        const params = Helpers.isNullOrEmpty(query) ? "" : `?${query}`;
                        navigate(Screens.MODULE_CREATE + params);
                    }
                }}
                mode={mode}
                idDetail={idDetail}
                onGoBack={() => handleGoBack()}
                onChangeMode={(mode: Mode) => {
                    setMode(mode);
                    navigate(Screens.MODULE_EDIT + `?id=${idDetail}&mode=${mode}`, { replace: true });
                }}
            />
        </DashboardLayout>
    );
};

export default ModuleScreen;
