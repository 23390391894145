import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, Grid, IconButton } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ExpandLess, ExpandMore } from "@mui/icons-material";

import Helpers from "commons/helpers";
import Constants from "constants/index";
import Screens from "constants/screens";
import Strings from "constants/strings";
import TenantService from "services/identity/tenant.service";
import ServiceService from "services/identity/service.service";

import { RootState } from "store";
import { clientItem } from "./styles";
import { DashboardLayout } from "layout";
import { setDataAlert } from "store/slice/message.slice";
import { IService, ITitleRoute } from "commons/interfaces";
import { showLoading } from "store/slice/loadingAPI.slice";
import { Mode, Status } from "constants/enum";
import { Box, FormField, Typography, ControlLabelCheckBox, ImageUploader, Avatar, useCommonComponentContext } from "@maysoft/common-component-react";

interface IServiceClient {
    clientIds: string[];
    serviceCode: string;
}
interface IDataEdit {
    isEdit?: boolean;
    id?: string;
    code?: string;
    name?: string;
    logoId?: string;
    logoUrl?: string;
    status?: Status;
    updateTime?: string;
    description?: string;
    serviceClients?: IServiceClient[];
    owner?: string;
}

const tenantService = new TenantService();
const serviceService = new ServiceService();

type IError = { [k in keyof IDataEdit]?: string };

interface IServiceList extends IService {
    collapse: boolean;
}

interface IModel {
    mode?: number;
    title?: string;
    route?: ITitleRoute[];
}

const TenantEditScreen = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const { getResourcePermissions } = useCommonComponentContext();

    const id = searchParams.get("id");
    const pramsMode = !Helpers.isNullOrEmpty(searchParams.get("mode")) ? Number(searchParams.get("mode")) : undefined;

    const [model, setModel] = useState<IModel>({});
    const [dataEdit, setDataEdit] = useState<IDataEdit>({});
    const [error, setError] = useState<IError>({} as IError);

    const [serviceList, setServiceList] = useState<IServiceList[]>([]);

    // const [openModal, setOpenModal] = useState(false);
    const [ownerSelected, setOwnerSelected] = useState<{
        avatarUrl?: string;
        avatarId?: string;
        email?: string;
        fullName?: string;
        identityId?: string;
        phoneNumber?: string;
    }>({});

    const listPathName = useSelector((state: RootState) => state.titleRoute.listPathName);
    const resourcePermissions = getResourcePermissions(Constants.ResourceCode.MANAGE_TENANT);

    useEffect(() => { getData(); }, []);

    const handleGoBack = () => {
        const itemP = listPathName.find(el => el.pathName === Screens.TENANT_LIST);
        navigate(Screens.TENANT_LIST + (itemP ? itemP.query : ""));
    };

    const getAllService = async () => {
        const resultService = await serviceService.getAll();
        setServiceList(resultService.map((item) => ({ ...item, collapse: false })));
    };

    const getDetail = async () => {
        if (Helpers.isNullOrEmpty(id)) {
            setModel({
                mode: Mode.Create,
                title: Strings.TENANT.TITLE_CREATE_VIEW,
                route: [
                    { title: Strings.TENANT.TITLE_LIST_VIEW, route: Screens.TENANT_LIST },
                    { title: Strings.Common.ADD_NEW, route: "" },
                ]
            });

            setOwnerSelected(undefined);

            setDataEdit({ status: Status.Active });
        } else {
            const result: any = await tenantService.getDetail(id);

            setDataEdit({
                id: result?.id || "",
                code: result?.tenantCode,
                name: result?.name || "",
                logoId: result?.logoId || "",
                logoUrl: result?.logoUrl || "",
                description: result?.description || "",
                status: result?.status || Status.Inactive,
                serviceClients: result?.serviceClients || [],
                updateTime: result?.updateTime || "",

                owner: result.owner?.email,
            });

            setOwnerSelected({
                identityId: result.owner?.identityId,
                email: result.owner?.email,
                fullName: result.owner?.fullName,
                avatarId: result.owner?.avatarId,
                avatarUrl: result.owner?.avatarUrl,
                phoneNumber: result.owner?.phoneNumber,
            });

            const mode = resourcePermissions.canUpdate ? (pramsMode || Mode.Update) : Mode.View;

            setModel({
                mode: mode,
                title: (mode === Mode.View)
                    ? Strings.TENANT.TITLE_DETAIL_VIEW : Strings.TENANT.TITLE_UPDATE_VIEW,
                route: [
                    { title: Strings.TENANT.TITLE_LIST_VIEW, route: Screens.TENANT_LIST },
                    {
                        title: (mode === Mode.View) ? Strings.Common.VIEW : Strings.Common.UPDATE,
                        route: ""
                    },
                ]
            });

            navigate(Screens.TENANT_EDIT + `?id=${id}&mode=${mode}`, { replace: true });
        }
    };

    const getData = async () => {
        try {
            dispatch(showLoading(true));

            await getAllService();
            await getDetail();

            dispatch(showLoading(false));
        } catch (error) {
            dispatch(showLoading(false));
            const e = Helpers.renderExceptionError(error);
            dispatch(setDataAlert({ message: e, type: "error" }));
        }
    };

    const checkValidate = () => {
        let checked: boolean = true;
        let newError: any = { ...error };
        let keys = ["name", "code", "owner"];

        keys.forEach((key) => {
            let objectKey = key as keyof IDataEdit;
            if (Helpers.isNullOrEmpty(dataEdit[objectKey])) {
                newError[key] = Strings.Validation.REQUIRED;
                checked = false;
            }
        });

        if (!checked) {
            dispatch(setDataAlert({ message: Strings.Message.PLEASE_COMPLETE_ALL_INFORMATION, type: "error" }));
        }
        setError(newError);
        return checked;
    };

    const onSubmit = async () => {
        if (model.mode === Mode.View) {
            setModel({
                ...model, mode: Mode.Update,
                title: Strings.TENANT.TITLE_UPDATE_VIEW,
                route: [
                    { title: Strings.TENANT.TITLE_LIST_VIEW, route: Screens.TENANT_LIST },
                    { title: Strings.Common.UPDATE, route: "" },
                ]
            });
            navigate(Screens.TENANT_EDIT + `?id=${id}&mode=${Mode.Update}`, { replace: true });
        } else {
            try {
                if (!checkValidate()) {
                    return;
                } else {

                    dispatch(showLoading(true));

                    const dataSubmit = {
                        id: dataEdit?.id,
                        name: dataEdit?.name,
                        tenantCode: dataEdit?.code,
                        logoId: dataEdit?.logoId || null,
                        logoUrl: dataEdit?.logoUrl || null,
                        description: dataEdit?.description,
                        serviceClients: dataEdit?.serviceClients,

                        status: dataEdit?.status,
                        updateTime: dataEdit?.updateTime,

                        owner: dataEdit?.owner || null,
                    };

                    if (Helpers.isNullOrEmpty(dataSubmit.id)) {
                        await tenantService.create(dataSubmit);
                        dispatch(setDataAlert({ message: Strings.Message.CREATE_SUCCESS, type: "success" }));
                        handleGoBack();
                    } else {
                        await tenantService.update(dataSubmit);
                        setModel({
                            ...model, mode: Mode.View,
                            title: Strings.TENANT.TITLE_DETAIL_VIEW,
                            route: [
                                { title: Strings.TENANT.TITLE_LIST_VIEW, route: Screens.TENANT_LIST },
                                { title: Strings.Common.VIEW, route: "" },
                            ]
                        });
                        navigate(Screens.TENANT_EDIT + `?id=${id}&mode=${Mode.View}`, { replace: true });
                        dispatch(setDataAlert({ message: Strings.Message.UPDATE_SUCCESS, type: "success" }));
                    }

                    dispatch(showLoading(false));
                }
            } catch (error) {
                dispatch(showLoading(false));
                const e = Helpers.renderExceptionError(error);
                dispatch(setDataAlert({ message: e, type: "error" }));
            }
        }

    };

    const onChangeValue = (value: any, key: keyof IDataEdit) => {
        switch (key) {
            case "logoId":
                setDataEdit((prev) => ({
                    ...prev,
                    isEdit: true,
                    logoId: value?.avatarId,
                    logoUrl: value?.avatarUrl,
                }));
                break;
            case "serviceClients":
                const { serviceCode, clientId } = value;

                let serviceClientsTemp = [...dataEdit?.serviceClients || []];
                let index = dataEdit?.serviceClients?.findIndex((item) => item.serviceCode === serviceCode);
                if (index !== -1 && Helpers.isNullOrEmpty(clientId)) {
                    serviceClientsTemp = serviceClientsTemp.filter((item) => item.serviceCode !== serviceCode);
                    setDataEdit((prev) => ({
                        ...prev,
                        isEdit: true,
                        serviceClients: serviceClientsTemp,
                    }));
                    break;
                }
                if (index === -1) {
                    serviceClientsTemp.push({
                        clientIds: !Helpers.isNullOrEmpty(clientId) ? [clientId] : [],
                        serviceCode,
                    });
                    setDataEdit((prev) => ({
                        ...prev,
                        isEdit: true,
                        serviceClients: serviceClientsTemp,
                    }));
                    break;
                }
                let clientIndex = serviceClientsTemp[index]?.clientIds?.findIndex((item) => item === clientId);
                let clientIdsTemp: string[] = serviceClientsTemp[index].clientIds || [];
                if (clientIndex === -1) {
                    clientIdsTemp = [...clientIdsTemp, clientId];
                } else {
                    clientIdsTemp = serviceClientsTemp[index]?.clientIds?.filter((item) => item !== clientId);
                }
                serviceClientsTemp[index].clientIds = clientIdsTemp;
                setDataEdit((prev) => ({
                    ...prev,
                    isEdit: true,
                    serviceClients: serviceClientsTemp,
                }));

                break;
            default:
                setDataEdit((prev) => ({
                    ...prev,
                    [key]: value,
                    isEdit: true,
                }));
                break;
        }

        if (!Helpers.isNullOrEmpty(error[key])) {
            setError((prev) => ({ ...prev, [key]: "" }));
        }
    };

    const AvatarCard = () => (
        <Card>
            <Box p={2} textAlign="center">
                <ImageUploader
                    width={150}
                    height={150}
                    imageRatio="1:1"
                    fileId={dataEdit?.logoId}
                    disabled={model.mode === Mode.View}
                    onChangeImage={(data) => {
                        if (Helpers.isNullOrEmpty(data?.accessUrl)
                            && Helpers.isNullOrEmpty(data?.id)) {
                            return;
                        } else {
                            const temp = {
                                avatarId: data?.id,
                                avatarUrl: data?.accessUrl
                            };
                            onChangeValue(temp, "logoId");
                        }
                    }}
                />
                <Typography variant="h6">{Strings.TENANT.LOGO_TENANT}</Typography>
            </Box>
        </Card>
    );

    const InfoCard = () => (
        <Card>
            <Box p={2}>
                <Typography variant="h5">{Strings.TENANT.TITLE_INFOR}</Typography>
                <Box p={2}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <FormField
                                required
                                maxLength={6}
                                mode={model.mode}
                                label={Strings.TENANT.CODE}
                                placeholder={Strings.TENANT.ENTER_CODE}
                                errorMessage={error?.code || ""}
                                defaultValue={dataEdit?.code || ""}
                                disabled={!Helpers.isNullOrEmpty(dataEdit?.id)}
                                onBlur={(value: any) => onChangeValue(value, "code")}
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <FormField
                                required
                                maxLength={255}
                                mode={model.mode}
                                label={Strings.TENANT.NAME}
                                placeholder={Strings.TENANT.ENTER_NAME}
                                errorMessage={error?.name || ""}
                                defaultValue={dataEdit?.name || ""}
                                onBlur={(value: any) => onChangeValue(value, "name")}
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <FormField
                                required
                                maxLength={255}
                                mode={model.mode}
                                errorMessage={error?.owner || ""}
                                defaultValue={dataEdit?.owner || ""}
                                label={Strings.TENANT.EMAIL_TENANT}
                                placeholder={Strings.TENANT.ENTER_EMAIL_TENANT}
                                disabled={!Helpers.isNullOrEmpty(dataEdit?.id)}
                                onBlur={(value: any) => onChangeValue(value, "owner")}
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <FormField
                                multiline
                                // isFullRow
                                maxLength={500}
                                mode={model.mode}
                                label={Strings.TENANT.DESCRIPTION}
                                defaultValue={dataEdit?.description || ""}
                                errorMessage={error?.description || ""}
                                placeholder={Strings.TENANT.ENTER_DESCRIPTION}
                                onBlur={(value: any) => onChangeValue(value, "description")}
                            />
                        </Grid>

                        {/* <Grid item xs={12} md={6}>
                            <ControlLabelCheckBox
                                disabled={model.mode === Mode.View}
                                label={Strings.ORGANIZATION.ACTIVATED}
                                value={(dataEdit?.status === Status.Active) || false}
                                onChangeValue={(value: any) =>
                                    onChangeValue(value ? Status.Active : Status.Inactive, "status")
                                }
                            />
                        </Grid> */}

                        {!Helpers.isNullOrEmpty(dataEdit?.id) &&
                            <Grid item xs={12} md={12}>
                                <Box sx={{ display: "grid" }}>
                                    <Box display="flex" alignItems="center" marginBottom={1}>
                                        <Typography variant="h6">{Strings.TENANT.OWNER}</Typography>
                                        {/* <IconButton onClick={() => { setOpenModal(true); }}>
                                <AddCircleOutline />
                            </IconButton> */}
                                    </Box>
                                    {
                                        !Helpers.isNullOrEmpty(ownerSelected?.identityId) &&
                                        <Box
                                            sx={{
                                                padding: "8px",
                                                display: "flex",
                                                flexWrap: "wrap",
                                                borderRadius: "8px",
                                                marginBottom: "8px",
                                                alignItems: "center",
                                                border: "1px #dddddd solid",
                                            }}
                                        >
                                            <Avatar
                                                alt="avt"
                                                src={ownerSelected.avatarUrl}
                                                text={ownerSelected?.fullName || ownerSelected?.email}
                                                sx={{
                                                    width: 50,
                                                    height: 50,
                                                    marginLeft: "16px",
                                                    marginRight: "16px",
                                                    border: "1px #dddddd solid",
                                                }}
                                            />
                                            <Box display="inline-grid">
                                                <Typography variant="button" fontWeight="bold">
                                                    {ownerSelected?.fullName || ownerSelected?.email || "No Name"}
                                                </Typography>
                                                <Typography variant="button" color="secondary">
                                                    {"Sđt"}:&nbsp;{ownerSelected?.phoneNumber}
                                                </Typography>
                                                <Typography variant="button" color="secondary">
                                                    {"Email"}:&nbsp;{ownerSelected?.email}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    }
                                </Box>
                            </Grid>
                        }
                    </Grid>
                </Box>


                {/* <ModalSearchUser
                    isSingle
                    open={openModal}
                    userIds={[dataEdit?.ownerId]}
                    onClose={() => { setOpenModal(false); }}
                    onAction={(data) => {
                        if (data?.length > 0) {
                            onChangeValue(data[0]?.id, "ownerId");
                            setOwnerSelected(data[0]);
                        } else {
                            onChangeValue(undefined, "ownerId");
                            setOwnerSelected(undefined);
                        }
                        setOpenModal(false);
                    }}
                /> */}
            </Box>
        </Card>
    );

    const handleExpande = (index: number) => {
        let temp = [...serviceList];
        temp[index].collapse = !temp[index]?.collapse;
        setServiceList(temp);
    };

    const CardListItemService = ({ newListService }: { newListService: IServiceList[] }) => {
        return (
            <Card>
                <Box p={2}>
                    <Typography variant="h5">{Strings.TENANT.SERVICE_AND_CLIENT}</Typography>
                    <Box>
                        {newListService.map((item: IServiceList, index: number) => {
                            let count = 0;
                            const clientIds = item?.clientIds || [];
                            let checkedValue = dataEdit?.serviceClients?.find((el) => el.serviceCode === item.serviceCode);

                            return (
                                <Box key={index}>
                                    <Box display="flex" alignItems="center" justifyContent="space-between">
                                        <Box display="flex" alignItems="center">
                                            <ControlLabelCheckBox
                                                label=""
                                                disabled={model.mode === Mode.View}
                                                value={checkedValue?.serviceCode.includes(item?.serviceCode)}
                                                onChangeValue={(value) =>
                                                    onChangeValue(
                                                        { serviceCode: item.serviceCode },
                                                        "serviceClients"
                                                    )
                                                }
                                            />
                                            <Typography variant="button">{item?.serviceName || ""}</Typography>
                                        </Box>
                                        <IconButton onClick={() => handleExpande(index)}>
                                            {item.collapse ? <ExpandLess /> : <ExpandMore />}
                                        </IconButton>
                                    </Box>
                                    {item?.collapse &&
                                        clientIds.map((clientId: string, index: number) => {
                                            let checked = checkedValue?.clientIds?.includes(clientId);
                                            if (checked) { count += 1; }

                                            return (
                                                <Box
                                                    key={index}
                                                    display="flex"
                                                    alignItems="center"
                                                    sx={(theme) =>
                                                        clientItem(theme, {
                                                            index,
                                                            length: clientIds.length - 1,
                                                            line: count,
                                                            checked: checked,
                                                        })
                                                    }
                                                >
                                                    <Box display="flex" alignItems="center">
                                                        <ControlLabelCheckBox
                                                            label=""
                                                            value={checked}
                                                            disabled={model.mode === Mode.View}
                                                            onChangeValue={() =>
                                                                onChangeValue(
                                                                    {
                                                                        serviceCode: item.serviceCode,
                                                                        clientId: clientId,
                                                                    },
                                                                    "serviceClients"
                                                                )
                                                            }
                                                        />
                                                        <Typography variant="button">{clientId}</Typography>
                                                    </Box>
                                                </Box>
                                            );
                                        })}
                                </Box>
                            )
                        })}
                    </Box>
                </Box>
            </Card>
        );
    };

    return (
        <DashboardLayout
            title={model.title}
            route={model.route || []}
            isPermission={resourcePermissions.canRead}
            onBackPress={() => {
                if (!dataEdit.isEdit) {
                    handleGoBack();
                } else {
                    Helpers.showConfirmAlert(Strings.Message.CANCEL_ACTION_MESSAGE, handleGoBack);
                }
            }}
            nameActionPress={model.mode === Mode.View ? Strings.Common.EDIT : Strings.Common.SAVE}
            onActionPress={(resourcePermissions.canUpdate || resourcePermissions.canCreate) ? onSubmit : undefined}
        >
            <Grid container spacing={3}>
                <Grid item xs={12} sm={5} md={4} lg={3}>
                    <AvatarCard />
                </Grid>
                <Grid item xs={12} sm={7} md={8} lg={9}>
                    <Box>
                        <InfoCard />
                    </Box>
                    <Box paddingTop={3}>
                        <CardListItemService newListService={serviceList} />
                    </Box>
                </Grid>
            </Grid>
        </DashboardLayout>
    );
};

export default TenantEditScreen;
