import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Box, Typography } from "@maysoft/common-component-react";

import { DashboardLayout } from "layout";
import { showLoading } from "store/slice/loadingAPI.slice";

const NotFoundScreen = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(showLoading(false));
    }, []);

    return (
        <DashboardLayout isPermission>
            <Box textAlign="center">
                <Typography variant="h2" fontWeight="regular">404 | Page Not Found</Typography>
            </Box>
        </DashboardLayout>
    )
}

export default NotFoundScreen;