import { createTheme } from "@mui/material/styles";
import colors from "assets/theme/base/colors";
import breakpoints from "assets/theme/base/breakpoints";
import typography from "assets/theme/base/typography";
import boxShadows from "assets/theme/base/boxShadows";
import borders from "assets/theme/base/borders";
import globals from "assets/theme/base/globals";

import boxShadow from "assets/theme/functions/boxShadow";
import hexToRgb from "assets/theme/functions/hexToRgb";
import linearGradient from "assets/theme/functions/linearGradient";
import pxToRem from "assets/theme/functions/pxToRem";
import rgba from "assets/theme/functions/rgba";
import card from "assets/theme/components/card";
import autocomplete from "assets/theme/components/autoComplete";
import input from "assets/theme/components/input";
import inputLabel from "assets/theme/components/input/inputLabel";
import inputOutlined from "assets/theme/components/input/inputOutlined";
import button from "assets/theme/components/button";
import formControlLabel from "assets/theme/components/formControlLabel";
import checkbox from "assets/theme/components/checkbox";
import tableContainer from "assets/theme/components/table/tableContainer";
import tableHead from "assets/theme/components/table/tableHead";
import tableCell from "assets/theme/components/table/tableCell";
import drawer from "assets/theme/components/drawer";
import tab from "assets/theme/components/tabs/tab";
import tabs from "assets/theme/components/tabs";
import alert from "assets/theme/components/alert";

export default createTheme(
    {
        breakpoints: { ...breakpoints },
        palette: { ...colors },
        typography: { ...typography },
        boxShadows: { ...boxShadows },
        borders: { ...borders },
        functions: {
            boxShadow,
            hexToRgb,
            linearGradient,
            pxToRem,
            rgba,
        },

        components: {
            MuiCard: { ...card },
            MuiAutocomplete: { ...autocomplete },
            MuiInput: { ...input },
            MuiInputLabel: { ...inputLabel },
            MuiOutlinedInput: { ...inputOutlined },
            MuiButton: { ...button },
            MuiFormControlLabel: { ...formControlLabel },
            MuiCheckbox: { ...checkbox },
            MuiTableContainer: { ...tableContainer },
            MuiTableHead: { ...tableHead },
            MuiTableCell: { ...tableCell },
            MuiDrawer: { ...drawer },
            MuiTabs: { ...tabs },
            MuiTab: { ...tab },
            MuiAlert: { ...alert },
            MuiCssBaseline: {
                styleOverrides: {
                    ...globals,
                },
            },
        },
    },
);
