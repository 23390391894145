import { Add } from '@mui/icons-material';
import { Card, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Helpers from "commons/helpers";
import Constants from '../../constants';
import Screens from "constants/screens";
import Strings from "constants/strings";
import MenuService from 'services/identity/menu.service';

import { RootState } from 'store';
import { DashboardLayout } from "layout";
import { commonGetAllDataClient } from 'screens/client';
import { setDataAlert } from 'store/slice/message.slice';
import { commonGetAllDataService } from 'screens/service';
import { ActionTypeSelectBox, Mode } from "constants/enum";
import { showLoading } from "store/slice/loadingAPI.slice";
import { ICodename, IMultiLang } from 'commons/interfaces';
import { setListPathName } from 'store/slice/titleRoute.slice';
import { Box, Button, DataTable, FormField, Chip, Autocomplete, useCommonComponentContext } from "@maysoft/common-component-react";


interface MenuProps {
    id: string;
    clientId: string,
    tenantCode: string,
    serviceCode: string,
    icon: string,
    title: IMultiLang,
    displayOrder: number,
    resourceCode: string,
    resourceName: IMultiLang,
    targetResource: string,
    isExternal: number,
    externalUrl: string,
    parentMenu: number,
    resourceURI: string
}

interface RequestData {
    tenantCode: string;
    serviceCode: string;
    clientId: string;
    parentMenu: string;
    pageSize: number;
    pageNumber: number;
    orderby: string;
    searchText: string;
    totalCount: number;
}

interface IModel {
    requestData: RequestData;
    requestDataTemp: RequestData;
}

const menuService = new MenuService();

const MenuScreen = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { getResourcePermissions } = useCommonComponentContext();

    const resourcePermissions = getResourcePermissions(Constants.ResourceCode.MENU);
    const listPathName = useSelector((state: RootState) => state.titleRoute.listPathName);

    const [model, setModel] = useState<IModel>({} as IModel);

    const [tableData, setTableData] = useState<MenuProps[]>([]);
    const [dataSelected, setDataSelected] = useState<string[]>([]);

    const [dataClient, setDataClient] = useState<ICodename[]>([]);
    const [dataServices, setDataServices] = useState<ICodename[]>([]);
    const [dataClientTemp, setDataClientTemp] = useState<ICodename[]>([]);

    useEffect(() => { onStart() }, []);

    const onStart = async () => {
        try {
            dispatch(showLoading(true));

            const { clientId } = Helpers.getUrlParams(["clientId"]);
            const { searchText } = Helpers.getUrlParams(["searchText"]);
            const { serviceCode } = Helpers.getUrlParams(["serviceCode"]);
            const { pageNumber } = Helpers.getUrlParams(["pageNumber"]);
            const { pageSize } = Helpers.getUrlParams(["pageSize"]);
            const item = listPathName.find(el => el.pathName === Screens.MENU_LIST);

            let data: any = {
                clientId: clientId,
                searchText: searchText,
                serviceCode: serviceCode,
                pageNumber: pageNumber || 1,
                pageSize: pageSize || Constants.ROW_PER_PAGE,
                totalCount: item?.totalCount || 0,
            }


            const resultDataService = await commonGetAllDataService();
            setDataServices(resultDataService);

            await getPaged(data);

            dispatch(showLoading(false));
        } catch (error) {
            dispatch(showLoading(false));
        }
    }

    const getPaged = async (data?: RequestData) => {
        try {
            dispatch(showLoading(true));

            let requestData: any = {
                clientId: data?.clientId,
                parentMenu: data?.parentMenu,
                searchText: data?.searchText,
                pageNumber: data?.pageNumber || 1,
                pageSize: data?.pageSize || Constants.ROW_PER_PAGE,
                serviceCode: data?.serviceCode || Constants.SERVICE_CODE.IDENTITY,
            }

            const resultDataClient = await commonGetAllDataClient({ serviceCode: requestData?.serviceCode });

            const result = await menuService.getPaged(Helpers.handleFormatParams(requestData));

            setDataClient(resultDataClient);
            setDataClientTemp(resultDataClient);
            setTableData(result.items);
            setModel({
                requestData: { ...requestData, totalCount: result.totalCount },
                requestDataTemp: { ...requestData, totalCount: result.totalCount },
            });

            let query: string = `?pageNumber=${requestData?.pageNumber}&pageSize=${requestData.pageSize}`;
            if (!Helpers.isNullOrEmpty(requestData?.searchText)) {
                query += `&searchText=${requestData?.searchText}`
            }
            if (!Helpers.isNullOrEmpty(requestData?.serviceCode)) {
                query += `&serviceCode=${requestData?.serviceCode}`
            }
            if (!Helpers.isNullOrEmpty(requestData?.clientId)) {
                query += `&clientId=${requestData?.clientId}`
            }

            navigate(Screens.MENU_LIST + query, { state: { ...data, totalCount: result.totalCount } });

            dispatch(setListPathName({ pathname: Screens.MENU_LIST, query, totalCount: result.totalCount }));
            dispatch(showLoading(false))
        } catch (error: any) {
            dispatch(showLoading(false))
            const e = Helpers.renderExceptionError(error);
            dispatch(setDataAlert({ message: e, type: "error" }));
        }
    }

    const handleDelete = (id: string, clientId: string) => {
        Helpers.showConfirmAlert(
            Strings.Message.CONFIRM_DELETE,
            async () => {
                try {
                    dispatch(showLoading(true))

                    const result = await menuService.delete({ ids: [id], clientId: clientId });

                    if (result.statusCode === Constants.ApiCode.SUCCESS) {
                        await getPaged(model.requestData);

                        const listTemp = [...dataSelected || []].filter(el => el !== id);
                        setDataSelected(listTemp);

                        dispatch(setDataAlert({ message: Strings.Message.DELETE_SUCCESS, type: "success" }));
                    }

                    dispatch(showLoading(false))
                } catch (error: any) {
                    dispatch(showLoading(false))
                    const e = Helpers.renderExceptionError(error);
                    dispatch(setDataAlert({ message: e, type: "error" }));
                }
            }
        )
    }

    const handleDeleteAll = async (ids: string[]) => {
        Helpers.showConfirmAlert(
            Strings.Message.CONFIRM_DELETE_ALL,
            async () => {
                try {
                    dispatch(showLoading(true));

                    await menuService.delete({ ids: ids });

                    await getPaged(model.requestData);

                    dispatch(setDataAlert({ message: Strings.Message.DELETE_ALL_SUCCESS, type: "success" }));

                    dispatch(showLoading(false))
                } catch (error: any) {
                    dispatch(showLoading(false))
                    const e = Helpers.renderExceptionError(error);
                    dispatch(setDataAlert({ message: e, type: "error" }));
                }
            });
    }

    const onValueChange = async (newValue: any, key: string) => {
        let dataTemp: any = { ...model.requestDataTemp };

        dataTemp[key] = newValue;

        if (key === "serviceCode") {
            dispatch(showLoading(true));

            dataTemp["clientId"] = "";
            const listTemp = await commonGetAllDataClient({ serviceCode: newValue });
            setDataClientTemp(listTemp);

            dispatch(showLoading(false));
        }

        setModel({ ...model, requestDataTemp: dataTemp });
    }

    const filterForm = () => {
        return (
            <Box>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <FormField
                            maxLength={255}
                            label={Strings.MENU.SEARCH_TEXT}
                            placeholder={Strings.MENU.ENTER_SEARCH_TEXT}
                            defaultValue={model.requestDataTemp?.searchText}
                            onBlur={(value) => onValueChange(value, "searchText")}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Autocomplete
                            isSelectedBox
                            data={dataServices || []}
                            label={Strings.SERVICE.NAME}
                            defaultValue={model.requestDataTemp?.serviceCode}
                            onChange={(value) => {
                                onValueChange(value, "serviceCode")
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Autocomplete
                            data={dataClientTemp || []}
                            label={Strings.SERVICE.CLIENT}
                            defaultValue={model.requestDataTemp?.clientId}
                            onChange={(value) => {
                                onValueChange(value, "clientId")
                            }}
                        />
                    </Grid>
                </Grid>
            </Box>
        );
    };

    return (
        <DashboardLayout
            isPermission={resourcePermissions.canRead}
            title={Strings.MENU.TITLE_LIST_VIEW}
            route={[{ title: Strings.MENU.TITLE_MENU, route: "" }]}
        >
            <Grid container spacing={2}>
                {
                    resourcePermissions.canCreate &&
                    <Grid item xs={12}>
                        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "end", }}>
                            <Button onClick={() => {
                                const query = Helpers.handleFormatParams({
                                    clientId: model.requestData?.clientId,
                                    serviceCode: model.requestData?.serviceCode,
                                });
                                const params = Helpers.isNullOrEmpty(query) ? "" : `?${query}`;
                                navigate(Screens.MENU_CREATE + `${params}`);
                            }}
                            >
                                <Add />&nbsp;{Strings.Common.ADD_NEW}
                            </Button>
                        </Box>
                    </Grid>
                }
                <Grid item xs={12}>
                    <Box mb={1}>
                        {!Helpers.isNullOrEmpty(model.requestData?.serviceCode) && (
                            <Chip
                                label={Strings.SERVICE.NAME}
                                value={dataServices.find(el => el.code === model.requestData?.serviceCode)?.name}
                                onDelete={(Constants.SERVICE_CODE.IDENTITY === model.requestData?.serviceCode)
                                    ? undefined
                                    : () => {
                                        getPaged({ ...model.requestData, serviceCode: Constants.SERVICE_CODE.IDENTITY })
                                    }}
                            />
                        )}
                        {!Helpers.isNullOrEmpty(model.requestData?.clientId) && (
                            <Chip
                                label={Strings.SERVICE.CLIENT}
                                value={dataClient.find((el) => el.code === model.requestData?.clientId)?.name}
                                onDelete={() => {
                                    getPaged({ ...model.requestData, clientId: undefined })
                                }}
                            />
                        )}
                    </Box>
                    <Card>
                        <Box p={2}>
                            <DataTable
                                allowCheckbox
                                loading={false}
                                dataSelected={dataSelected}
                                handleOnChangeSelected={(data) => setDataSelected(data)}

                                totalCount={model.requestData?.totalCount}
                                pageNumber={model.requestData?.pageNumber}
                                rowPerPage={model.requestData?.pageSize || Constants.ROW_PER_PAGE}
                                onChangePageSize={(pageSize) => getPaged({ ...model.requestData, pageSize })}
                                onChangePageNumber={(pageNumber) => getPaged({ ...model.requestData, pageNumber })}

                                searchText={model.requestData?.searchText}
                                placeholderSearch={Strings.MENU.SEARCH_TEXT}
                                onSearch={(val) => getPaged({ ...model.requestData, pageNumber: 1, searchText: val })}
                                onSearchChange={(val) => {
                                    setModel({
                                        ...model,
                                        requestData: {
                                            ...model.requestData,
                                            searchText: val
                                        }
                                    })
                                }}

                                filterForm={filterForm()}
                                onFilter={() => {
                                    setDataClient([...dataClientTemp]);
                                    getPaged({ ...model.requestDataTemp, pageNumber: 1 });
                                }}
                                onCloseFilter={() => {
                                    const requestDataTemp = { ...model.requestData };
                                    setModel({ ...model, requestDataTemp });
                                    setDataClientTemp([...dataClient]);
                                }}
                                onReset={() => {
                                    getPaged({
                                        ...model.requestData,
                                        pageNumber: 1,
                                        clientId: undefined,
                                        serviceCode: Constants.SERVICE_CODE.IDENTITY,
                                    })
                                }}

                                actionTypeSelectBox={[
                                    { actionType: ActionTypeSelectBox.Delete, actionName: Strings.Common.DELETE, color: "error" }
                                ]}
                                onSubmitActionCheckedBox={(data, action) => {
                                    if (action === ActionTypeSelectBox.Delete) {
                                        handleDeleteAll(data)
                                    }
                                }}

                                table={{
                                    columns: [
                                        {
                                            Header: Strings.MENU.MENU_TITLE, accessor: "title", width: "200px",
                                            Cell: (row: any) => {
                                                const id = row?.row.original?.id;
                                                return (
                                                    <Link
                                                        className="hyperlink"
                                                        state={{ mode: Mode.View }}
                                                        to={Screens.MENU_EDIT + `?id=${id}`}
                                                    >
                                                        {Helpers.renderValueByLanguage(row?.value?.value)}
                                                    </Link>
                                                )
                                            },
                                        },
                                        { Header: Strings.MENU.EXTERNAL_URL, accessor: "externalUrl", width: "180px", },
                                        { Header: Strings.MENU.RESOURCE_URI, accessor: "resourceURI", width: "180px", },
                                        {
                                            Header: Strings.MODULE.NAME, accessor: "resourceName", width: "200px",
                                            Cell: (row: any) => {
                                                const targetResource = row?.row.original?.targetResource;
                                                return (
                                                    <Link className="hyperlink"
                                                        state={{ mode: Mode.View }}
                                                        to={Screens.MODULE_EDIT + `?id=${targetResource}`}
                                                    >
                                                        {Helpers.renderValueByLanguage(row?.value?.value)}
                                                    </Link>
                                                )
                                            }
                                        },
                                    ],
                                    rows: tableData
                                }}

                                actionList={row => [
                                    {
                                        key: "detail",
                                        iconName: "view",
                                        title: Strings.Common.VIEW_ALL,
                                        callback: (row) => navigate(Screens.MENU_EDIT + `?id=${row.id}&mode=${Mode.View}`),
                                    },
                                    resourcePermissions.canUpdate && {
                                        key: "edit",
                                        iconName: "edit",
                                        title: Strings.Common.EDIT,
                                        callback: (row) => navigate(Screens.MENU_EDIT + `?id=${row.id}&mode=${Mode.Update}`),
                                    },
                                    resourcePermissions.canDelete && {
                                        key: "delete",
                                        iconName: "delete",
                                        title: Strings.Common.DELETE,
                                        callback: (row) => handleDelete(row.id, row.clientId),
                                    },
                                ]}
                            />
                        </Box>
                    </Card>
                </Grid>
            </Grid>
        </DashboardLayout>
    )
}

export default MenuScreen;