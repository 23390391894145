import Constants from "constants/index";
import CircularProgress from "@mui/material/CircularProgress";

interface IProps {
    color?: string;
    visible?: boolean
}

const LoadingLayout = (props: IProps) => {
    return (
        <>
            {
                <div style={{
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    justifyContent: "center",
                    alignItems: "center",
                    minHeight: "100vh",
                    position: "fixed",
                    display: "flex",
                    height: "100%",
                    width: "100%",
                    margin: "auto",
                    zIndex: 1900,
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                }}>
                    <div style={{
                        border: 1,
                        width: 100,
                        height: 100,
                        display: "flex",
                        borderRadius: 12,
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor: "#ffffff",
                    }}>
                        <CircularProgress style={{ color: props.color || Constants.Styles.BLUE_COLOR }} />
                    </div>
                </div>
            }
        </>
    )
}

export default LoadingLayout