import Constants from "constants/index";
import IdentityService from "./indentity.service";

interface IRequestQuickInvite {
    clientId: string;
    organizationIds: string[];
    emails: string[];
}
class InvitationService extends IdentityService {
    public getAll = async (data: any) => {
        const result = await this.api.get({
            path: `${Constants.ApiPath.INVITATION.GET_ALL}?${data}`,
        });
        return result.data.result;
    };

    public getPaged = async (data: any) => {
        const result = await this.api.get({
            path: `${Constants.ApiPath.INVITATION.GET_PAGED}?${data}`,
        });
        return result.data.result;
    };

    public accept = async (id: string) => {
        const result = await this.api.post({
            path: `${Constants.ApiPath.INVITATION.ACCEPT}/${id}`,
        });
        return result.data;
    };

    public decline = async (id: string) => {
        const result = await this.api.post({
            path: `${Constants.ApiPath.INVITATION.DECLINE}/${id}`,
        });
        return result.data;
    };

    public create = async (data: any) => {
        const result = await this.api.post({
            path: Constants.ApiPath.INVITATION.CREATE,
            data: data,
        });
        return result.data;
    };

    public resend = async (id: string) => {
        const result = await this.api.post({
            path: `${Constants.ApiPath.INVITATION.RESEND}/${id}`,
        });
        return result.data;
    };

    public delete = async (id: string) => {
        const result = await this.api.post({
            path: `${Constants.ApiPath.INVITATION.DELETE}/${id}`,
        });
        return result.data;
    };

    public quickInvite = async (data: IRequestQuickInvite) => {
        const result = await this.api.post({
            path: `${Constants.ApiPath.INVITATION.QUICK_INVITE}`,
            data
        });
        return result.data.result;
    };
}

export default InvitationService;
