import { Add } from '@mui/icons-material';
import { Card, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { Box, Button, DataTable, FormField, useCommonComponentContext } from "@maysoft/common-component-react";

import Helpers from "commons/helpers";
import Screens from "constants/screens";
import Strings from "constants/strings";
import Constants from '../../constants';
import CustomStatusCell from 'components/CustomStatusCell';
import ServiceService from 'services/identity/service.service';

import { RootState } from 'store';
import { Mode } from 'constants/enum';
import { DashboardLayout } from "layout";
import { ICodename } from 'commons/interfaces';
import { setDataAlert } from 'store/slice/message.slice';
import { showLoading } from "store/slice/loadingAPI.slice";
import { setListPathName } from 'store/slice/titleRoute.slice';

interface ITableResponse {
    id?: string,
    status?: number,
    serviceCode?: string,
    serviceName?: string,
    description?: string,
    invitations?: string,
    resources?: string,
    serviceMemberships?: string,
    serviceClients?: any[],
    userRoles?: string,
    roles?: string,
    userOTPs?: string,
    menus?: string,
    createTime?: string,
    createUser?: string,
    updateTime?: string,
    updateUser?: string,
}

interface IModel {
    searchText?: string;
    clientId?: string;

    hasNext?: boolean;
    hasPrevious?: boolean;
    pageSize?: number;
    pageNumber?: number;
    totalCount?: number;
}

const serviceService = new ServiceService();

export const commonGetAllDataService = async (params?: { searchText?: string }) => {
    try {
        let listTemp: ICodename[] = [];

        const result = await serviceService.getAll(params);
        result.forEach((e: any) => {
            listTemp.push({
                code: e.serviceCode,
                name: e.serviceName,
            });
        });

        return listTemp;
    } catch (error) { return []; }
}

const ServiceScreen = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();
    const { getResourcePermissions } = useCommonComponentContext();

    const listPathName = useSelector((state: RootState) => state.titleRoute.listPathName);
    const resourcePermissions = getResourcePermissions(Constants.ResourceCode.MANAGE_SERVICE);
    const clientId = Helpers.getUrlParams(["clientId"])?.clientId || Helpers.getItemInLocalStorage(Constants.StorageKeys.CLIENT_ID);

    const [model, setModel] = useState<IModel>({});
    const [modelTemp, setModelTemp] = useState<IModel>({});

    const [tableData, setTableData] = useState<ITableResponse[]>([]);

    useEffect(() => {
        const pageNumber = Number(searchParams.get("pageNumber") || 1);
        const pageSize = Number(searchParams.get("pageSize") || Constants.ROW_PER_PAGE);
        const item = listPathName.find(el => el.pathName === Screens.SERVICE_LIST);

        if (!Helpers.isNullOrEmpty(clientId)) {
            Helpers.setItemInLocalStorage(Constants.StorageKeys.CLIENT_ID, clientId);
        }
        getPage({ pageNumber, pageSize, clientId, totalCount: item?.totalCount, });
    }, [])

    const getPage = async (data?: IModel) => {
        try {
            dispatch(showLoading(true));
            const pageNumber = Helpers.getPageNumber(
                data?.pageNumber || 1,
                data?.pageSize || Constants.ROW_PER_PAGE,
                data?.totalCount || 0,
            );

            const dataGetpage: any = {
                searchText: data?.searchText,
                clientId: data?.clientId || Constants.CLIENT_ID,
                pageNumber,
                pageSize: data?.pageSize || Constants.ROW_PER_PAGE,
            };

            const dataRows: any[] = [];
            const result = await serviceService.getPaged(Helpers.handleFormatParams(dataGetpage));
            (result.items || []).forEach((item: any) => {
                dataRows.push(item);
            });

            setTableData(dataRows);
            setModel({ ...data, totalCount: result.totalCount || 0 });

            let query = `?pageNumber=${pageNumber}&pageSize=${dataGetpage.pageSize}`;

            navigate(Screens.SERVICE_LIST + query, { replace: true });

            dispatch(setListPathName({ pathname: Screens.SERVICE_LIST, query, totalCount: result.totalCount }));
            dispatch(showLoading(false));
        } catch (error) {
            dispatch(showLoading(false));
            const e = Helpers.renderExceptionError(error);
            dispatch(setDataAlert({ message: e, type: "error" }));
        }
    }

    const onValueChange = (key: string, value: any) => {
        let requestDataTemp: any = { ...modelTemp };
        requestDataTemp[key] = value;
        setModelTemp(requestDataTemp);
    }

    const filterForm = () => {
        return (
            <Box>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <FormField
                            required
                            maxLength={255}
                            value={modelTemp.searchText || ""}
                            label={Strings.SERVICE.SEARCH_TEXT}
                            placeholder={Strings.SERVICE.ENTER_SEARCH_TEXT}
                            onChangeValue={(value: any) => onValueChange("searchText", value)}
                        />
                    </Grid>
                </Grid>
            </Box>
        )
    }

    const handleDelete = (id: string) => {
        Helpers.showConfirmAlert(Strings.Message.CONFIRM_DELETE, async () => {
            try {
                dispatch(showLoading(true));

                await serviceService.delete(id);

                await getPage(model);

                dispatch(setDataAlert({ message: Strings.Message.DELETE_SUCCESS, type: "success" }));

                dispatch(showLoading(false));
            } catch (error: any) {
                dispatch(showLoading(false));
                const e = Helpers.renderExceptionError(error);
                dispatch(setDataAlert({ message: e, type: "error" }));
            }
        })
    }

    return (
        <DashboardLayout
            isPermission={resourcePermissions.canRead}
            title={Strings.SERVICE.TITLE_LIST_VIEW}
            route={[{ title: Strings.SERVICE.TITLE_MENU, route: "" }]}
        >
            <Grid container spacing={2}>
                {
                    resourcePermissions.canCreate &&
                    <Grid item xs={12}>
                        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "end" }}>
                            <Button onClick={() => navigate(Screens.SERVICE_CREATE)}>
                                <Add />&nbsp;
                                {Strings.Common.ADD_NEW}
                            </Button>
                        </Box>
                    </Grid>
                }
                <Grid item xs={12}>
                    <Card>
                        <Box p={2}>
                            <DataTable
                                loading={false}
                                totalCount={model.totalCount}
                                pageNumber={model.pageNumber}
                                rowPerPage={model.pageSize || Constants.ROW_PER_PAGE}
                                onChangePageSize={(pageSize) => getPage({ ...model, pageSize })}
                                onChangePageNumber={(pageNumber) => getPage({ ...model, pageNumber })}

                                searchText={model.searchText || ""}
                                placeholderSearch={Strings.SERVICE.SEARCH_TEXT}
                                onSearch={(val) => getPage({ ...model, searchText: val })}
                                onSearchChange={(val) => onValueChange("searchText", val)}

                                filterForm={filterForm()}
                                onReset={() => getPage({})}
                                onFilter={() => getPage(model)}
                                onCloseFilter={() => setModelTemp({ ...model })}

                                table={{
                                    rows: tableData || [],
                                    columns: [
                                        {
                                            Header: Strings.SERVICE.CODE, accessor: "serviceCode", width: "100px",
                                            Cell: (row: any) => (
                                                <Link
                                                    className="hyperlink"
                                                    to={Screens.SERVICE_EDIT + `?id=${row?.row.original?.id}&mode=${Mode.View}`}
                                                >
                                                    {row.value}
                                                </Link>
                                            )
                                        },
                                        { Header: Strings.SERVICE.NAME, accessor: "serviceName", width: "200px", },
                                        {
                                            Header: Strings.Common.STATUS, accessor: "status", width: "150px",
                                            Cell: ({ value }: any) => <CustomStatusCell status={value} />
                                        },
                                    ],
                                }}

                                actionList={row => [
                                    {
                                        key: "detail",
                                        iconName: "view",
                                        title: Strings.Common.VIEW_ALL,
                                        callback: (row) => navigate(Screens.SERVICE_EDIT + `?id=${row.id}&mode=${Mode.View}`),
                                    },
                                    resourcePermissions.canUpdate && {
                                        key: "edit",
                                        iconName: "edit",
                                        title: Strings.Common.EDIT,
                                        callback: (row) => navigate(Screens.SERVICE_EDIT + `?id=${row.id}&mode=${Mode.Update}`),
                                    },
                                    resourcePermissions.canDelete && {
                                        key: "delete",
                                        iconName: "delete",
                                        title: Strings.Common.DELETE,
                                        callback: (row) => handleDelete(row.id),
                                    },
                                ]}
                            />
                        </Box>
                    </Card>
                </Grid>
            </Grid>
        </DashboardLayout>
    )
}

export default ServiceScreen;