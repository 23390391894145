import { useDispatch } from "react-redux";

import Strings from "constants/strings";

import { DashboardLayout } from "layout";
import { storeDataUserProfile } from "store/slice/userInfo.slice";
import { Box, ProfileContainer } from "@maysoft/common-component-react";

const ProfileScreen: React.FC = () => {
    const dispatch = useDispatch();

    return (
        <DashboardLayout
            isPermission
            title={Strings.PROFILE.TITLE_MENU}
            route={[{ title: Strings.PROFILE.TITLE_MENU, route: "" }]}
        >
            <Box mt={1}>
                <ProfileContainer
                    hidenCardInfoProfile={{
                        hidenIdCardInfo: true,
                        hidenPassportInfo: true,
                        hidenUserProfileByOrganization: false,
                    }}

                    isMultiCardContainer={false}
                    onDataUserProfile={(data: any) => {
                        dispatch(storeDataUserProfile(data));
                    }}
                />
            </Box>
        </DashboardLayout>
    );
};

ProfileScreen.displayName = "ProfileScreen";

export default ProfileScreen;