/* eslint-disable import/no-anonymous-default-export */
// =============================================================================
// Lang for Vietnamese
// ==================================

export default {
    App: {
        TITLE: "Identity",
    },

    Languages: {
        VIETNAM: "Việt Nam",
        VI: "Tiếng Việt",
        EN: "Tiếng Anh",
    },

    Common: {
        WEB: "Web",
        NEXT: "Tiếp tục",
        PREVIEW: "Xem trước",
        APP_MOBILE: "App Mobile",
        SELECT: "Chọn",
        COMMON: "Dùng chung",
        CONTINUE: "Tiếp tục",
        PAUSE: "Tạm dừng",
        CANCEL: "Hủy",
        CANCELED: "Đã hủy",
        CANCEL_SELECT: "Bỏ chọn",
        DISPLAY: "Hiển thị",
        TOTAL: "Tổng",
        NOTIFICATION: "Thông báo",
        OK: "OK",
        SAVE: "Lưu",
        SAVE_TEMP: "Lưu tạm",
        COMPLETE: "Hoàn thành",
        WAIT_TEST: "Chờ kiểm tra",
        PROCESSING: "Đang xử lý",
        UPDATE: "Cập nhật",
        IN_ACTIVE: "Ngưng hoạt động",
        ACTIVE: "Đang hoạt động",
        REMOVE_FROM_SERVICE: "Xóa khỏi dịch vụ",
        EMPTY_DATA: "Chưa có dữ liệu",
        NO_DATA: "Không có dữ liệu",
        ADD_NEW: "Thêm mới",
        ADD_IMAGE: "Thêm hình ảnh",
        GO_BACK: "Trở về",
        EXIT: "Thoát",
        CREATE_NEW: "Tạo mới",
        VIEW_ALL: "Xem chi tiết",
        EDIT: "Chỉnh sửa",
        LOCK: "Khóa",
        LOCKED: "Đã bị khóa",
        DRAFT: "Nháp",
        UNBLOCK: "Mở khóa",
        DELETE: "Xóa",
        REPLACE: "Thay thế",
        UPLOAD: "Tải lên",
        UPLOAD_IMAGE: "Tải ảnh lên",
        SEARCH: "Tìm kiếm",
        SEE: "Xem",
        ACTION: "Thao tác",
        MILLION: "Tr",
        BILLION: "Tỷ",
        UNCONFIRM: "Hủy bỏ",
        FILTER: "LỌC",
        FILTER_LIST: "Lọc danh sách",
        RESET: "Hủy lọc",
        TYPE: "Loại",
        PHONE_NUMBER: "Số điện thoại",
        ENTER_PHONE_NUMBER: "Nhập số điện thoại",
        CODE: "Mã",
        INPUT: "Nhập",
        INPUT_NOTE: "Nhập ghi chú",
        EMAIL: "Email",
        TITLE: "Chức danh",
        DAY: "Ngày",
        // TABLE
        LABEL_DISPLAY_ROWS: "{0} - {1} trên {2}",
        DOWN: "Tải về",
        DETAIL: "Chi tiết",
        APPROVE: "Phê duyệt",
        REJECT: "Từ chối",
        REJECT_INVITATION: "Từ chối lời mời",
        GENDER: "Giới tính",
        TIME: "Thời gian",
        START_TIME: "Thời gian bắt đầu",
        END_TIME: "Thời gian kết thúc",
        DISABLE: "Vô hiệu hóa",
        STATUS: "Trạng thái",
        COPY: "Sao chép",
        IMPORT: "Nhập",
        EXPORT: "Xuất",
        CITY: "Thành phố",
        PROVINCE: "Tỉnh thành",
        SELECT_PROVINCE: "Chọn tỉnh thành",
        DISTRICT: "Quận/huyện",
        SELECT_DISTRICT: "Chọn quận/huyện",
        WARDS: "Phường/xã",
        SELECT_WARDS: "Chọn phường/xã",
        SEARCH_TEXT: "Tìm kiếm",
        ENTER_SEARCH_TEXT: "Nhập từ khóa vào",
        ORGANIZATION: "Tổ chức",
        ACTION_WITH_EXCEL_FILE: "Thao tác excel",
        EXPORT_EXCEL: "Xuất excel",
        IMPORT_EXCEL: "Nhập excel",
        SELECT_FILE_FROM_DEVIDE: "Chọn tệp từ thiết bị",
        INVALID_DATA: "Dữ liệu không hợp lệ",
        FIELD_REQUIRED: "Trường bắt buộc",
        ACCEPT: "Đồng ý",
        ATTENTION: "Chú ý",
        MALE: "Nam",
        FEMALE: "Nữ",
        ALL: "Tất cả",
        CHANGE_STATUS: "Đổi trạng thái",
        CONFIRM: "Xác nhận",
        DATE_FORMAT: "vi",
        CLOSE: "Đóng",
        PRINT: "In",
        EXPORT_PDF: "Xuất PDF",
        ADDRESS_LINE: "Địa chỉ",
        ADDRESS_LINE_PHD: "Nhập địa chỉ",
        NAME: "Họ và tên",
        NOTE: "Ghi chú",
        BIRTHDATE: "Sinh nhật",
        APPLY: "Áp dụng",
        NOT_APPLY: "Ngừng áp dụng",
        VIEW: "Xem chi tiết",
        HERE: "Tại đây",
        UPLOAD_FILE: "Tải tệp lên",
        LOAD_MORE: "Xem thêm",
        USE_ALL_SYSTEM: "Sử dụng toàn hệ thống",
        USE: "Sử dụng",
        FAX_NUMBER: "Số fax",
        TAX_NUMBER: "Mã số thuế",
        YES: "Có",
        NO: "Không",
        SELECT_STATUS: "Chọn trạng thái",
        INVITE: "Lời mời",
        SELECTED_RECORD: "Đã chọn {0} bảng ghi",
        UNREAD: "Chưa đọc",
        MARK_READ_ALL: "Đánh dấu đọc tất cả",
        ADDRESS_INFO: "Thông tin địa chỉ",
        PENDING_INVITATION: "Chờ xác nhận lời mời",
        DELETED: "Đã xóa",
        COUNTRY_CODE: "Mã quốc gia",
        SELECT_COUNTRY_CODE: "Chọn mã quốc gia",
        LOADING: "Đang tải...",
        SKIP: "Bỏ qua",
        UNDER_MANAGEMENT: "Thuộc quản lý",
    },

    AlertStatus: {
        ERROR: "Lỗi",
        WARNING: "Cảnh báo",
        INFO: "Thông tin",
        SUCCESS: "Thành công",
        QUESTION: "Câu hỏi",
    },

    Message: {
        ERROR: "Đã có phát sinh lỗi trong quá trình xử lý, vui lòng thử lại sau. Xin lỗi vì đã gây bất tiện.",
        SUCCESS: "Thành công",
        PLACEHOLDER: "Chọn...",
        CONFIRM_LOGOUT: "Bạn có muốn đăng xuất?",
        CONFIRM_CREATE: "Bạn có chắc muốn tạo?",
        CONFIRM_UPDATE: "Bạn có chắc muốn cập nhật?",
        CONFIRM_DELETE: "Bạn có thực sự muốn xóa?",
        CONFIRM_DELETE_ALL: "Bạn có thực sự muốn xóa tất cả?",
        CONFIRM_BLOCK: "Bạn có thực sự muốn khoá tài khoản?",
        CONFIRM_SUBMIT: "Bạn có thực sự muốn gửi?",
        CONFIRM_CANCEL: "Bạn có thực sự muốn hủy?",
        CONFIRM_CANCEL_ACTIVE: "Bạn có thực sự muốn hủy kích hoạt?",
        CONFIRM_EXPORT_FILE: "Bạn có thực sự muốn tải tệp?",
        CONFIRM_APPROVE: "Bạn có thực sự muốn phê duyệt?",
        CONFIRM_REJECT: "Bạn có thực sự muốn từ chối?",
        CREATE_SUCCESS: "Tạo thành công",
        UPDATE_SUCCESS: "Cập nhật thành công",
        PAYMENT_SUCCESS: "Thanh toán thành công",
        UPDATE_STATUS_SUCCESS: "Cập nhật trạng thái thành công",
        EXPORT_FILE_SUCCESS: "Tải tệp thành công",
        DELETE_SUCCESS: "Xóa thành công",
        DELETE_ALL_SUCCESS: "Xóa tất cả thành công",
        BLOCK_SUCCESS: "Khoá thành công",
        CANCEL_SUCCESS: "Hủy thành công",
        APPROVE_SUCCESS: "Phê duyệt thành công",
        REJECT_SUCCESS: "Từ chối thành công",
        LOGIN_SUCCESS: "Đăng nhập thành công",
        LOGOUT_SUCCESS: "Đăng xuất thành công",
        CREATE_ERROR: "Tạo không thành công",
        BLOCK_ERROR: "Khoá không thành công",
        UPDATE_ERROR: "Cập nhật không thành công",
        EXPORT_FILE_ERROR: "Tải tệp không thành công",
        CANCEL_ERROR: "Hủy không thành công",
        APPROVE_ERROR: "Phê duyệt không thành công",
        REJECT_ERROR: "Từ chối không thành công",
        DELETE_ERROR: "Xóa không thành công",
        DELETE_ALL_ERROR: "Xóa tất cả không thành công",
        LOGIN_ERROR: "Đăng nhật không thành công",
        LOGOUT_ERROR: "Đăng xuất không thành công",
        EPR_TOKEN: "Phiên đăng nhập đã hết hạn!",
        PASSWORD_ERROR: "Tài khoản hoặc mật khẩu không đúng.",
        CREATE_USER_SUCCESS: "Thêm mới nhân viên thành công",
        PLEASE_COMPLETE_ALL_INFORMATION: "Vui lòng điền đầy đủ thông tin yêu cầu",
        INVALID_EMAIL: "Email không hợp lệ",
        INVALID_PHONENUMBER: "Số điện thoại không hợp lệ",
        CONFIRM_CHANGE_STATUS: "Bạn có chắc chắn muốn thay đổi trạng thái?",
        CHANGE_PHONE_NUMBER_SUCCESS: "Thay đổi số điện thoại thành công",
        CHANGE_PASSWORD_SUCCESS: "Thay đổi mật khẩu thành công",
        CHANGE_PROFILE_SUCCESS: "Thay đổi thông tin cá nhân thành công",
        NO_FUNCTION: "Xin lỗi! Hiện chưa có chức năng này!",
        CANCEL_ACTION_MESSAGE: "Thông tin chỉnh sửa chưa được lưu. Bạn chắc chắn muốn rời khỏi màn hình hiện tại không?",
        TOKEN_EXPIRED: "Hết hạn cho phiên đăng nhập! Vui lòng đăng nhập lại",
        USER_PROFILE_NOT_FOUND: "Không tìm thấy thông tin người dùng, vui lòng đăng nhập lại!",
        CONFIRM_EXPORT: "Bạn có muốn xuất file excel?",
        NOT_FORMAT_IMG: "Không đúng định dạng hình ảnh cho phép! (.png |.gif | .jpeg)",
        LIMIT_NUMBER_OF_PHOTO: "Giới hạn thêm tối thiểu là {0} ảnh và tối đa chỉ {1} ảnh",
        LIMIT_SIZE_OF_IMG: "Kích thước file lớn hơn {0}MB",
        NOT_PERMISSION: "Rất tiếc, bạn không có quyền truy cập",
        NOT_FOUND: "Không tìm thấy dữ liệu!",
        NO_ROLE_UPDATE: "Bạn không có quyền cập nhật",
        NO_ROLE_DELETE: "Bạn không có quyền xóa",
        GROUP_ALREADY_EXIST: "Nhóm đã tồn tại",
        PLEASE_ADD_PERMISSION: "Vui lòng thêm nhóm quyền",
        PLEASE_ADD_GROUP_WORK: "Vui lòng thêm nhóm làm việc",
        DONT_HAVE_ORGANIZATION: "Để sử dụng chức năng này vui lòng tạo hoặc ",
        CORRESPONDING_ORGANIZATION: " tổ chức tương ứng.",
        CONFIRM_ACTIVE_STATUS: "Bạn chắc chắn muốn chuyển sang trạng thái hoạt động?",
        CONFIRM_IN_ACTIVE_STATUS: "Bạn chắc chắn muốn chuyển sang trạng thái ngưng hoạt động?",
        IN_ACTIVE_STATUS_SUCCESS: "Cập nhật trạng thái sang ngưng hoạt động thành công",
        ACTIVE_STATUS_SUCCESS: "Cập nhật trạng thái sang hoạt động thành công",
        CHANGE_EMAIL_SUCCESS: "Thay đổi email thành công",
        NO_ADDRESS: "Chưa có thông tin, vui lòng thêm địa chỉ",
        EMAIL_ALREADY_EXISTS: "Email đã tồn tại",
        PLEASE_SELECT_AN_ORGANIZATION: "Vui lòng chọn tổ chức",
    },

    Validation: {
        EMAIL_ADDRESS: "Sai định dạng email",
        PHONE_NUMBER: "Sai định dạng số điện thoại",
        DATE_TIME: "Sai định dạng ngày tháng năm",
        USERNAME: "Tên tài khoản viết liền không dấu",
        REQUIRED: "Trường dữ liệu là bắt buộc",
        REQUIRED_NAME: "Vui lòng nhập họ và tên",
        REQUIRED_EMAIL: "Vui lòng nhập email",
        REQUIRED_USER_NAME: "Vui lòng nhập tên đăng nhập",
        REQUIRED_PASSWORD: "Vui lòng nhập mật khẩu",
        REQUIRED_CONFIRM_PASSWORD: "Vui lòng nhập lại mật khẩu",
        REQUIRED_IMAGE: "Vui lòng chọn ảnh",
        REQUIRED_ORGANIZATION: "Vui lòng chọn cửa hàng",
        REQUIRED_ADDRESS: "Vui lòng thêm địa chỉ",
        NOT_SAME: "Mật khẩu không trùng khớp",
        REQUIRED_NUMBER: "Vui lòng chỉ nhập số",
        LIMITED_SIZE: "Đã vượt quá độ dài cho phép",
        REQUIRED_FORM: "Vui lòng kiểm tra lại thông tin nhập",
        REQUIRED_AVATAR: "Vui lòng chọn ảnh đại diện",
        INVALID_TIME: "Thời gian không hợp lệ",
        ID_CARD: "Sai định dạng CMND/CCCD",
        REQUIRED_SPECIAL_CHARACTERS: "Trường dữ liệu không chứa các ký tự đặc biệt",
    },

    Login: {
        CONFIRM_PASSWORD: "Nhập lại mật khẩu",
        REGISTER_SUCCESSFULL: "Đăng ký thành công",
        REGISTER_ERROR: "Đăng ký thấy bại. Hãy thử lại!",
        USER_NAME: "Tên đăng nhập",
        PASSWORD: "Mật khẩu",
        LOGOUT: "Đăng xuất",
        LOGIN: "Đăng nhập",
        REGISTER: "Đăng ký",
        REMEMBER_ME: "Ghi nhớ đăng nhập",
        FORGOT_PASSWORD: "Quên mật khẩu",
        USER_INFO: "Thông tin tài khoản",
        SELECT_ORGANIZATION: "Chọn tổ chức",
        ORGANIZATION: "Tổ chức",
        VERIFICATION_CODE: "Mã xác nhận",
        NEW_PASSWORD: "Mật khẩu mới",
        CONFIRM_NEW_PASSWORD: "Xác nhận mật khẩu mới",

        NO_BELONG_TO_ANY_ORGANIZATION: "Hiện bạn chưa thuộc tổ chức nào!, nhờ quản trị viên thêm vào tổ chức.",
    },

    SETTING_COMMON: {
        TITLE_MENU: "Cấu hình chung",
    },

    SYSTEM_SETTING: {
        TITLE_MENU: "Cấu hình hệ thống",
        TITLE_LIST_VIEW: "Danh sách cấu hình hệ thống",
        TITLE_UPDATE_VIEW: "Cập nhật cấu hình",
        TITLE_DETAIL_VIEW: "Chi tiết cấu hình",

        CODE: "Mã",
        NAME: "Tên",
        VALUE: "Giá trị",
        DESCRIPTION: "Mô tả",
        SETTING_CONFIG_FIREBASE: "Cài đặt cấu hình firebase",
    },

    PROFILE: {
        TITLE_MENU: "Thông tin cá nhân",
        BASIC_INFO: "Thông tin cơ bản",
        PROFILE: "Hồ sơ",

        // Update email
        UPDATE_EMAIL: "Cập nhật email",
        UPDATE: "Cập nhật",
        NEW_EMAIL: "Email mới",
        ENTER_NEW_EMAIL: "Nhập email mới",
        GET_OTP_CODE: "Lấy mã xác thực",
        INPUT_OTP_CODE: "Nhập mã xác thực",

        // OTPResendCountDownButton
        SEND_TO: "Gửi lại",
        SEND_TO_AFTER: "Gửi lại sau",
        OTP_CODE: "Mã OTP",
        ENTER_OTP_CODE: "Nhập mã OTP",

        // UpdatePasswordSection
        UPDATE_PASSWORD: "Cập nhật mật khẩu",
        OLD_PASSWORD: "Mật khẩu cũ",
        NEW_PASSWORD: "Mật khẩu mới",
        CONFIRM_NEW_PASSWORD: "Xác nhận mật khẩu mới",
        ENTER_OLD_PASSWORD: "Nhập mật khẩu cũ",
        ENTER_NEW_PASSWORD: "Nhập mật khẩu mới",
        ENTER_CONFIRM_NEW_PASSWORD: "Nhập xác nhận mật khẩu mới",
        CONFIRM_NEW_PASSWORD_VALIDATE: "Nhập xác nhận mật khẩu không khớp",
        DEVICE_VERIFICATION: "Xác minh thiết bị",
        CONFIRM_DEVICE_VERIFICATION: "Chúng tôi vừa gửi mã xác nhận gồm 4 ký tự tới email {0} của bạn. Vui lòng nhập nó vào ô bên dưới",

        // User profile view
        FULL_NAME: "Họ và tên",
        ENTER_FULL_NAME: "Nhập họ và tên",
        EMAIL: "Email",
        ENTER_EMAIL: "Nhập Email",
        PHONE_NUMBER: "Số điện thoại",
        ENTER_PHONE_NUMBER: "Nhập số điện thoại",
        GENDER: "Giới tính",
        SELECT_GENDER: "Chọn giới tính",
        BIRTHDATE: "Ngày sinh",
        SELECT_BIRTHDATE: "Chọn ngày sinh",
        SELECT_EMPLOYMENT_DATE: "Chọn ngày vào làm",
        EMPLOYMENT_DATE: "Ngày vào làm",
        TERMINATIONT_DATE: "Ngày nghỉ làm",
        ENTER_TERMINATIONT_DATE: "Chọn ngày nghỉ làm",
        IDENTITY_ID: "CMND/CCCD",
        IDENTITY_ID_ISSUED_DATE: "Ngày cấp",
        IDENTITY_ID_ISSUED_PLACE: "Nơi cấp",
        ENTER_IDENTITY_ID: "Nhập CMND/CCCD",
        SELECT_IDENTITY_ID_ISSUED_DATE: "Chọn ngày cấp",
        ENTER_IDENTITY_ID_ISSUED_PLACE: "Nhập nơi cấp",
        SOCIAL_INSURANCE_CODE: "Mã bảo hiểm xã hội",
        ENTER_SOCIAL_INSURANCE_CODE: "Nhập mã bảo hiểm xã hội",

        USER_INFORMATION: "Thông tin người dùng",
        UPDATE_USER_INFORMATION: "Cập nhật thông tin người dùng",
        USER_AVATAR: "Ảnh đại diện",
        UPLOAD_AVT_COMPLETE: "Cập nhật ảnh thành công. Vui lòng chờ đợi trong giây lát!",
        UPLOAD_AVT_ERROR: "Đã có phát sinh lỗi trong quá trình xử lý. Tải ảnh lên không thành công!",

        USER_CONTACT: "Thông tin liên hệ",
        DEFAULT_CONTACT: "Mặc định",
    },

    DASHBOARD: {
        TITLE_MENU: "Trang chủ",
        CREATE_ORGANIZATION: "Tạo mới tổ chức",
        TOTAL_MANAGEMENT_ORG: "Tổ chức",
        TOTAL_MANAGEMENT_GROUP: "Nhóm",
        TOTAL_MANAGEMENT_MEMBER: "Thành viên",
        TOTAL_ORG_BELONG: "Tổ chức trực thuộc",
        TOTAL_GROUP_BELONG: "Nhóm trực thuộc",
        THAN_LAST_MONTH: "Hơn tháng trước",
        MANAGEMENT: "Quản lý",
        ORGANIZATION: "Tổ chức",
        STATISTICAL: "Thống kê",
    },

    ORGANIZATION: {
        TITLE_MENU_PARENT: "Quản lý tổ chức",
        TITLE_MENU: "Tổ chức",
        TITLE_LIST_VIEW: "Danh sách tổ chức",
        TITLE_CREATE_VIEW: "Thêm mới tổ chức",
        TITLE_UPDATE_VIEW: "Cập nhật tổ chức",
        TITLE_DETAIL_VIEW: "Chi tiết tổ chức",
        SEARCH_TEXT: "Tìm kiếm theo tên tổ chức",
        WEBSITE: "Website",
        ENTER_WEBSITE: "Nhập tên website",
        REGISTRATION_DATE: "Ngày đăng ký",
        EXPIRATION_DATE: "Ngày hết hạn",
        SELECT_REGISTRATION_DATE: "Chọn ngày đăng ký",
        SELECT_EXPIRATION_DATE: "Chọn ngày hết hạn",
        REGISTRATION_DATE_BIGGER_EXPIRATION_DATE: "Ngày đăng ký phải nhỏ hơn ngày hết hạn",
        BIRTHDAY_BIGGER_CURRENT_DATE: "Ngày sinh phải nhỏ hơn ngày hiện tại",
        ADDRESS_LINE: "Địa chỉ tổ chức",
        ENTER_ADDRESS_LINE: "Nhập địa chỉ tổ chức",
        DESCRIPTION: "Mô tả",
        ENTER_DESCRIPTION: "Mô tả",
        STORE_OWNER_INFO: "Thông tin chủ tổ chức",
        STAFF_LIST: "Danh sách nhân viên",
        STORE_OWNER: "Chủ tổ chức",
        CHOOSE_OWNER: "Chọn chủ tổ chức",
        ADD_NEW_OWNER: "Thêm chủ tổ chức mới",
        ADDRESS_INFO: "Thông tin địa chỉ",
        STORE_NAME: "Tên tổ chức",
        ENTER_STORE_NAME: "Nhập tên tổ chức",
        OWNER_NAME: "Tên chủ tổ chức",
        ENTER_OWNER_NAME: "Nhập tên chủ tổ chức",
        GROUP_NAME: "Tên nhóm làm việc",
        GROUP: "Nhóm làm việc",
        SELECT_GROUP_NAME: "Chọn tên nhóm",
        ROLE_GROUP: "Nhóm người dùng",
        IMAGE_PHOTO: "Ảnh tổ chức",
        GROUP_ADDRESS_LINE: "Địa chỉ nhóm",
        ACTIVATED: "Kích hoạt",
        TENANT: "Nhà cung cấp dịch vụ",
        ENTER_TENANT: "Chọn tên nhà cung cấp dịch vụ",
        SERVICE: "Dịch vụ",
        ENTER_SERVICE: "Chọn dịch vụ",
        NO_ORGANIZATION: "Không chọn",
        STAFF_NAME: "Thành viên",
        ACTION_ACTIVE: "Hoạt động",
        CONFIRM_REMOVE_SERVICE: "Bạn có thực sự muốn xóa tổ chức này ra khỏi dịch vụ?",
        CONFIRM_DELETE_ALL_SERVICE: "Bạn có thực sự muốn xóa tổ chức này ra khỏi tất cả dịch vụ?",
    },

    GROUP: {
        TITLE_MENU: "Nhóm người dùng",
        TITLE_LIST_VIEW: "Danh sách nhóm",
        TITLE_CREATE_VIEW: "Thêm nhóm mới",
        TITLE_UPDATE_VIEW: "Cập nhật nhóm",
        TITLE_DETAIL_VIEW: "Chi tiết nhóm",
        BASIC_INFO: "Thông tin nhóm",
        SEARCH_TEXT: "Tìm kiếm theo mã, tên nhóm",
        INFO_STAFF_OF_GROUP: "Danh sách thành viên",
        ORGANIZATION_PARENT: "Tổ chức cấp trên",
        GROUP_PARENT: "Nhóm cấp trên",
        SELECT_GROUP_PARENT: "Chọn nhóm cấp trên",
        NAME_GROUP: "Tên nhóm",
        EMAIL_GROUP: "Email nhóm",
        ADDRESS_LINE: "Địa chỉ nhóm",
        ENTER_NAME_GROUP: "Nhập tên nhóm",
        ENTER_EMAIL_GROUP: "Nhập Email nhóm",
        CODE_GROUP: "Mã nhóm",
        SEARCH_PLACEHOLDER: "Tìm kiếm theo mã/ tên nhóm",
        ACTIVATED: "Hoạt động",
        CHILD_NUMBER: "Nhóm trực thuộc",
        CONFIRM_DELETE_WHEN_HAVE_CHILD_GROUP:
            "Nếu bạn xóa nhóm này thì các nhóm trực thuộc sẽ đồng loạt bị xóa theo. Bạn có chắc chắn muốn xóa không?",
    },

    MODULE: {
        TITLE_MENU: "Nhóm tính năng",
        TITLE_LIST_VIEW: "Danh sách nhóm tính năng",
        TITLE_CREATE_VIEW: "Thêm mới nhóm tính năng",
        TITLE_UPDATE_VIEW: "Cập nhật nhóm tính năng",
        TITLE_DETAIL_VIEW: "Chi tiết nhóm tính năng",
        BASIC_INFO: "Thông tin nhóm tính năng",
        SEARCH_TEXT: "Tìm kiếm theo mã, tên",

        ADD_NEW_MODULE: "Thêm nhóm tính năng",
        NAME: "Tên nhóm tính năng",
        INPUT_NAME: "Nhập tên nhóm tính năng",
        CODE: "Mã nhóm tính năng",
        DESCIPTION: "Mô tả",
        INPUT_DESCIPTION: "Nhập mô tả",
        NAME_RESOURCE: "Tên tính năng",
        ADD_RESOURCE: "Thêm tính năng",
        SELECT_NAME_RESOURCE: "Chọn tính năng",
        IS_MENU: "Menu",
        PUBLIC: "Công khai",
        PRIVATE: "Cần chứng thực",
        PRIVATERBAC: "Cần phân quyền",
        SELECT_MODULE: "Chọn nhóm tính năng",
        ROLE_ACCESS: "Quyền truy cập",
        ADMIN_FEATURE: "Chức năng cho quản trị",
    },

    ROLE: {
        TITLE_MENU: "Vai trò",
        TITLE_LIST_VIEW: "Vai trò",
        TITLE_CREATE_VIEW: "Thêm mới vai trò",
        TITLE_UPDATE_VIEW: "Cập nhật vai trò",
        TITLE_DETAIL_VIEW: "Chi tiết vai trò",
        BASIC_INFO: "Vai trò",
        SEARCH_TEXT: "Tìm kiếm theo mã/tên",
        ENTER_SEARCH_TEXT: "Nhập mã/tên",
        DISCRIPTION: "Mô tả",
        CODE: "Mã",
        NAME_ROLE: "Tên",
        SHOW: "Hiển thị",
        RESOURCE: "Tính năng",
        ACTIVATED: "Đang hoạt động",
        READ: "Xem",
        ROLE: "Vai trò",
        CREATE: "Thêm mới",
        DELETE: "Xoá",
        UPDATE: "Chỉnh sửa",
        IMPORT: "Nhập",
        EXPORT: "Xuất",
        NONE: "Không",
        OWNER: "Chủ sở hữu",
        MANAGER: "Quản lý",
        TYPE: "Loại",
        SELECT_TYPE: "Chọn loại",
        NORMAL: "Normal",
        DEFAULT_ROLE: "Default",
        MEMBER: "Member",
        OWNER_ROLE: "Organization Owner",
        ORGANIZATION_ADMIN: "Organization Admin",
        GROUP_ADMIN_ROLE: "Group admin",
        SERVICE_ADMIN: "Service Admin",
        WARNING_OWNER: "Bạn không có quyền cập nhật vai trò này",
    },

    MENU: {
        TITLE_MENU: "Menu",
        TITLE_LIST_VIEW: "Danh sách Menu",
        TITLE_CREATE_VIEW: "Thêm mới Menu",
        TITLE_UPDATE_VIEW: "Cập nhật Menu",
        TITLE_DETAIL_VIEW: "Chi tiết Menu",
        BASIC_INFO: "Thông tin Menu",
        SEARCH_TEXT: "Tìm kiếm theo tên menu",
        ENTER_SEARCH_TEXT: "Nhập tên menu",

        CODE: "Mã Menu",
        GROUP_MODULE: "Mô-đun",
        SELECT_GROUP_MODULE: "Chọn mô-đun",
        PARENT_MENU: "Menu cấp trên",
        SELECT_PARENT_MENU: "Chọn menu cấp trên",
        EXTERNAL_URL: "Đường dẫn",
        ENTER_EXTERNAL_URL: "Nhập đường dẫn",
        MENU_TITLE: "Tiêu đề Menu",
        ENTER_TITLE_MENU: "Nhập tiêu đề Menu",
        ICON_NAME: "Icon menu",
        ENTER_ICON_NAME: "Chọn icon menu",
        DISPLAY_ORDER: "Thứ tự hiển thị",
        ENTER_DISPLAY_ORDER: "Nhập thứ tự hiển thị",
        IS_EXTERNAL: "Thêm đường dẫn",
        ADD_ICON_IMG: "Logo",
        RESOURCE_URI: "Mô-đun URI",
        ENTER_RESOURCE_URI: "Nhập Mô-đun URI (<sevice>/<module>/<feature>)",
        EXTRA_INFORMATION: "Thông tin bổ sung",
        ENTER_EXTRA_INFORMATION: "Nhập thông tin bổ sung",
        SCREEN_NAME: "Tên màn hình",
        ENTER_SCREEN_NAME: "Nhập tên màn hình",
    },

    STAFF: {
        TITLE_MENU: "Thành viên tổ chức",
        TITLE_LIST_VIEW: "Danh sách thành viên",
        TITLE_CREATE_VIEW: "Thêm mới thành viên",
        TITLE_UPDATE_VIEW: "Cập nhật thành viên",
        TITLE_DETAIL_VIEW: "Chi tiết thành viên",
        TITLE_STAFF_WORKING_GROUP: "Thông tin nhóm",
        TITLE_STAFF_INFORMATION: "Thông tin thành viên",

        CODE: "Mã thành viên",
        FULL_NAME: "Họ và tên thành viên",
        ACTIVATED: "Kích hoạt",
        GENDER_MALE: "Nam",
        GENDER_FEMALE: "Nữ",
        NAME: "Họ và tên",
        ENTER_NAME: "Nhập họ và tên",
        PHONE_NUMBER: "Số điện thoại",
        ENTER_PHONE_NUMBER: "Nhập số điện thoại",
        EMAIL: "Email",
        ENTER_EMAIL: "Nhập email",
        BIRTHDAY: "Ngày sinh",
        SELECT_BIRTHDAY: "Chọn ngày sinh",
        GENDER: "Giới tính",
        SELECT_GENDER: "Chọn giới tính",
        GROUP_USER_PERMISSION: "Vai trò thành viên",
        SELECT_GROUP_USER_PERMISSION: "Chọn Vai trò thành viên",
        WORK_GROUPS: "Nhóm làm việc",
        SELECT_WORK_GROUPS: "Chọn nhóm làm việc",
        WORK_GROUPS_ALREADY_EXIST: "Nhóm làm việc này đã tồn tại. vui lòng chọn nhóm khác",
        NOTE: "Ghi chú",
        ENTER_NOTE: "Nhập ghi chú",
        ACTIVE: "Đang hoạt động",
        INACTIVE: "Ngừng hoạt động",
        ALL: "Tất cả",
        MANAGER: "Quản lý nhóm",
        GROUP_DEFAULT: "Nhóm mặc định",
        USER_TITLE: "Chức danh",
        SELECT_USER_TITLE: "Chọn chức danh",
        REFER_TO_THE_LINK: "Tham khảo link",
        ADD_STAFF: "Thêm thành viên",
        ADD_WORK_GROUP: "Thêm nhóm làm việc",
        PLEASE_CHOOSE_GROUP_DEFAULT: "Vui lòng chọn nhóm mặc định",
        SELECTED_GROUP_WILL_BE_DEFAULT_ONE: "Nhóm được chọn sẽ là nhóm mặc định",
        MEMBER_INVITATION: {
            INVITATION: "Mời thành viên",
            CREATE_INVITATION: "Tạo lời mời",
            SEND_SUCCESS: "Gửi lời mời thành công!",
        },
        UPDATE_USER_ROLE_SUCCESS: "Cập nhật vai trò thành viên thành công",
        CONFIRM_DELETE_ALL_SERVICE: "Bạn có thực sự muốn xóa thành viên này ra khỏi các dịch vụ?",
        CONFIRM_DELETE: "Bạn có thực sự muốn xóa thành viên này?",
    },

    CLIENT: {
        TITLE_MENU: "Ứng dụng khách",
        TITLE_LIST_VIEW: "Danh sách ứng dụng khách",
        TITLE_CREATE_VIEW: "Thêm mới ứng dụng khách",
        TITLE_UPDATE_VIEW: "Cập nhật ứng dụng khách",
        TITLE_DETAIL_VIEW: "Chi tiết ứng dụng khách",
        TITLE_INFOR: "Thông tin ứng dụng khách",

        NAME: "Client Name",
        CLIENT_ID: "ClientId",
        ENTER_CLIENT_ID: "Enter ClientId",
        DESCRIPTION: "Description",
        CLIENT_URI: "Client URL",
        ALLOW_OFFLINE_ACCESS: "Allow offline access",
        ACCESS_TOKEN_LITE_TIME: "Access token life time",
        ABSOLUTE_REFRESH_TOKEN_LIFE_TIME: "Absolute refresh token life time (seconds)",
        ENTER_ABSOLUTE_REFRESH_TOKEN_LIFE_TIME: "Enter Absolute refresh token life time (seconds)",
        SLIDING_REFRESH_TOKEN_LIFE_TIME: "Sliding refresh token life time (seconds)",
        ENTER_SLIDING_REFRESH_TOKEN_LIFE_TIME: "Enter sliding refresh token life time (seconds)",
        ENTER_NAME: "Enter client name",
        ENTER_DESCRIPTION: "Enter description",
        ENTER_CLIENT_URI: "Enter client url",
        ENTER_ACCESS_TOKEN_LITE_TIME: "Enter access token life time",
        GRANT_TYPE: "Grant type",
        SELECT_GRANT_TYPE: "Select grant type",
        LOGO_CLIENT: "Logo Client",
        REDIRECT_URI: "Redirect Uri",
        ENTER_REDIRECT_URI: "Enter redirect uri",
        POST_LOGOUT_REDIRECT_URI: "Post logout redirect uri",
        ENTER_POST_LOGOUT_REDIRECT_URI: "Enter post logout redirect uri",
        SCOPE: "Scope",
        SCOPE_NAME: "Scope name",
        ENTER_SCOPE_NAME: "Enter scope name",
        SELECT_SCOPE: "Select scope",
        SCOPE_MORE: "Scope more",
    },

    SERVICE: {
        TITLE_MENU_PARENT: "Quản lý dịch vụ",
        TITLE_MENU: "Dịch vụ",
        TITLE_LIST_VIEW: "Danh sách dịch vụ",
        TITLE_CREATE_VIEW: "Thêm mới dịch vụ",
        TITLE_UPDATE_VIEW: "Cập nhật dịch vụ",
        TITLE_DETAIL_VIEW: "Chi tiết dịch vụ",
        BASIC_INFOR: "Thông tin dịch vụ",
        SEARCH_TEXT: "Tìm kiếm theo tên, mã",
        ENTER_SEARCH_TEXT: "Nhập tên, mã",
        LIST_CLIENT: "Danh sách ứng dụng khách",
        CODE: "Mã dịch vụ",
        NAME: "Tên dịch vụ",
        LINK_API: "Liên kết API",
        ENTER_LINK_API: "Nhập liên kết API",
        SELECT_SERVICE: "Chọn dịch vụ",
        DESCRIPTION: "Mô tả",
        ENTER_CODE: "Nhập mã dịch vụ",
        ENTER_NAME: "Nhập tên dịch vụ",
        ENTER_DESCRIPTION: "Nhập mô tả",
        CLIENT: "Ứng dụng khách",
        SELECT_CLIENT: "Chọn ứng dụng khách",
        TERMS_OF_SERVICE: "Điều khoản dịch vụ",
        USER_AGREEMENT: "Thỏa thuận",
        USER_POLICY: "Điều khoản bảo mật",
        ENTER_TERMS_OF_SERVICE: "Nhập điều khoản dịch vụ",
        ENTER_USER_AGREEMENT: "Nhập thỏa thuận",
        ENTER_USER_POLICY: "Nhập điều khoản bảo mật",
        DEFAULT_CLIENT: "Mặc định",
        TYPE_CLIENT: "Loại ứng dụng khách",
        SELECT_TYPE_CLIENT: "Chọn loại ứng dụng khách",
        SERVICE_RELATE_REQUESTS: "Dịch vụ hỗ trợ liên quan",
        NAME_MODULE: "Tên mô-đun",
        SELECT_NAME_MODULE: "Chọn mô-đun",
        CLIENTID_ALREADY_EXISTS: "Ứng dụng khách {0} đã tồn tại. vui lòng chọn ứng dụng khách khác",
        DEFAULT_CLIENT_ALREADY_EXISTS: "Đã tồn tại ứng dụng khách mặc định",
        SERVICE_ALREADY_EXISTS: "Dịch vụ {0} đã tồn tại. vui lòng chọn dịch vụ khác",
        NO_DATA_LIST_CLIENT: "Chưa có ứng dụng khách được thêm",
        INIT_ROLE: "Khởi tạo bộ quyền",
        CONFIRM_INIT_ROLE: "Bạn chắc chắn muốn khởi tạo bộ quyền mặt định của hệ thống cho dịch vụ {0}",
        INIT_ROLE_SUCCESS: "Bạn đã khởi tạo bộ quyền mặt định cho dịch vụ {0} thành công",

        INIT_DATA_RESOURCE: "Khởi tạo dữ liệu",
        CONFIRM_INIT_DATA_RESOURCE: "Bạn chắc chắn muốn khởi tạo dữ liệu cho dịch vụ {0}",
        INIT_DATA_RESOURCE_SUCCESS: "Bạn đã khởi tạo dữ liệu cho dịch vụ {0} thành công",
    },

    TENANT: {
        TITLE_MENU: "Người thuê",
        TITLE_LIST_VIEW: "Danh sách người thuê",
        TITLE_CREATE_VIEW: "Thêm mới người thuê",
        TITLE_UPDATE_VIEW: "Cập nhật người thuê",
        TITLE_DETAIL_VIEW: "Chi tiết người thuê",
        TITLE_INFOR: "Thông tin người thuê",
        SEARCH_TEXT: "Tìm kiếm theo tên, mã người thuê",
        ENTER_SEARCH_TEXT: "Nhập tên, mã người thuê",
        CODE: "Mã người thuê",
        NAME: "Tên người thuê",
        DESCRIPTION: "Mô tả",
        ENTER_CODE: "Nhập mã người thuê",
        ENTER_NAME: "Nhập tên người thuê",
        ENTER_DESCRIPTION: "Nhập mô tả",
        CLIENT_ID: "Ứng dụng khách",
        SERVICE_NAME: "Dịch vụ thuê",
        SELECT_CLIENT_ID: "Chọn ứng dụng khách",
        SELECT_SERVICE_NAME: "Chọn dịch vụ",
        DOMAIN_NAME: "Domain name",
        ENTER_DOMAIN_NAME: "Enter domain name",
        NO_TENANT_DETAIL: "Chưa có thông tin chi tiết người thuê",
        LIST_TENANT_CLIENT: "Danh sách ứng dụng khách",
        LOGO_TENANT: "Logo",
        ACCESS_TOKEN: "Access token",
        ENTER_ACCESS_TOKEN: "Enter access token",
        ALREADY_EXIST_CLIENT_ID: "Ứng dụng này đã tồn tại",
        SERVICE_AND_CLIENT: "Dịch vụ và ứng dụng",
        STATUS: "Trạng thái",
        OWNER: "Người thuê",
        EMAIL_TENANT: "Email người thuê",
        ENTER_EMAIL_TENANT: "Nhập email người thuê",
    },

    CODE_NAME: {
        TITLE_MENU: "Danh mục dùng chung",
        TITLE_LIST_VIEW: "Danh sách danh mục",
        TITLE_CREATE_VIEW: "Thêm mới danh mục",
        TITLE_UPDATE_VIEW: "Cập nhật danh mục",
        TITLE_DETAIL_VIEW: "Chi tiết danh mục",
        BASIC_INFO: "Thông tin danh mục",
        CODE_NAME_DETAIL: "Chi tiết danh mục",
        PARENT_GROUP_CODE: "000000",
        CODE: "Mã danh mục",
        CODE_INPUT: "Nhập mã danh mục",
        INPUT_NAME: "Nhập tên danh mục",
        NAME: "Tên danh mục",
        SHORT_NAME: "Tên viết tắt",
        INPUT_SHORT_NAME: "Nhập tên viết tắt",
        SEARCH_TEXT: "Tìm kiếm theo tên",
        ORGANIZATION_NAME: "Tổ chức áp dụng",
        SELECT_ORGANIZATION_NAME: "Chọn tổ chức",
        ALL_ORGANIZATIONS: "Tất cả các tổ chức",
        CODE_NAME: "Mã nhóm danh mục",
        ENTER_CODE_NAME: "Nhập mã nhóm danh mục",
        NAME_GROUP: "Tên nhóm danh mục",
        ENTER_NAME_GROUP: "Nhập tên nhóm danh mục",
        NAME_LIST: "Danh sách danh mục",
        PARENT_GROUP: "Danh mục cha",
        SEARCH_PARENT_GROUP: "Tìm kiếm danh mục cha",
        SCOPE_APPLICATION: "Phạm vi áp dụng",
    },

    INVITATION: {
        INVITATION_ORG: "Bạn có lời mời vào tổ chức {0} với vai trò {1}",
        ACCEPT_SUCCESS: "Bạn đã chấp thuận lời mời thành công",
        DECLINE_SUCCESS: "Bạn đã từ chối lời mời thành công",
        TITLE_MENU: "Thư mời",
        TITLE_LIST_VIEW: "Danh sách thư mời",
        CONFIRM_ACCEPT: "Bạn chắc chắn muốn chấp thuận lời mời này?",
        CONFIRM_DECLINE: "Bạn chắc chắn muốn từ chối lời mời này?",
        CONFIRM_DELETE: "Bạn thực sự muốn xóa lời mời này?",
        DELETE_SUCCESS: "Bạn đã xóa lời mời thành công",
        CONFIRM_RESEND: "Bạn thực sự muốn gửi lại lời mời này?",
        RESEND_SUCCESS: "Bạn đã gửi lại lời mời thành công",
        SEARCH_TEXT: "Tìm kiếm theo email",
        VALID_FROM: "Từ",
        VALID_TO: "Đến",
        INVITATION_EXPIRED: "Lời mời này đã hết hạn",
        RESEND: "Gửi lại lời mời",
    },

    RESOURCE: {
        TITLE_MENU: "Tính năng",
        TITLE_LIST_VIEW: "Danh sách tính năng",
        TITLE_UPDATE_VIEW: "Cập nhật tính năng",
        TITLE_DETAIL_VIEW: "Chi tiết tính năng",
        BASIC_INFO: "Thông tin tính năng",
        SEARCH_TEXT: "Tìm kiếm theo mã, tên",

        RESOURCE_DETAIL: "Tính năng chi tiết",
        CODE: "Mã tính năng",
        NAME: "Tên tính năng",
        NAME_API: "Tên API",
        ENTER_NAME_API: "Thêm tên API",
        METHOD: "Phương thức",
        SELECT_METHOD: "Chọn phương thức",
        EDIT_MODE: "Quyền thao tác",
        SELECT_EDIT_MODE: "Chọn quyền thao tác",
        DESCRIPTION: "Mô tả",
        ENTER_DESCRIPTION: " Nhập mô tả",
    },

    SERVICE_ORGANIZATION: {
        TITLE_MENU: "Quản lý tổ chức",
        TITLE_LIST_VIEW: "Danh sách tổ chức",
        ADD_ORGANIZATIONS: "Thêm tổ chức",
        ADD_USERS: "Thêm người dùng",
        ADD_NEW_ORGANIZATION: "Thêm mới tổ chức",
    },

    LOGIN_LOG: {
        TITLE_MENU: "Lịch sử đăng nhập",
        TITLE_LIST_VIEW: "Danh sách lịch sử đăng nhập",
        USER: "Người dùng",
        SELECT_USER: "Chọn người dùng",
        LOGIN_TIME: "Thời gian đăng nhập",
        LOGOUT_TIME: "Thời gian đăng xuất",
        ENTER_START_TIME: "Thời gian bắt đầu",
        ENTER_END_TIME: "Thời gian kết thúc",
        SEARCH_TEXT: "Tìm kiếm",
        ENTER_SEARCH_TEXT: "Nhập từ khóa tìm kiếm",
        LOCATION: "Vị trí",
        CURRENTLY_LOGGED: "Hiện đang đăng nhập",
    },

    POLICY: {
        TITLE_MENU: "Phân quyền",
        TITLE_LIST_VIEW: "Phân quyền",
        TITLE_UPDATE_VIEW: "Cập nhật phân quyền",
        TITLE_CREATE_VIEW: "Tạo mới phân quyền",
        TITLE_DETAIL_VIEW: "Chi tiết phân quyền",
        BASIC_INFO: "Thông tin phân quyền",
        SEARCH_TEXT: "Tìm kiếm theo mã, tên",
        ENTER_SEARCH_TEXT: "Nhập mã, tên",

        CODE: "Mã",
        NAME: "Tên",
        DISCRIPTION: "Mô tả",
        ENTER_CODE: "Nhập mã",
        ENTER_NAME: "Nhập tên",
        ENTER_DISCRIPTION: "Nhập mô tả",
        QUOTA: "Giới hạn thao tác",
        ENTER_QUOTA: "Nhập giới hạn",
        PERMISSION: "Quyền thao tác",
        SELECT_PERMISSION: "Chọn quyền thao tác",
        RESOURCE: "Tính năng",
        SELECT_RESOURCE: "Chọn tính năng",
        SERVICE: "Dịch vụ",
        SELECT_SERVICE: "Chọn dịch vụ",
        ROLE: "Vai trò",
        GROUP: "Nhóm",
        USER: "Người dùng",
        OTHER_CLAIM: "Phương pháp chứng thực khác",
        EQUAL: "Bằng",
        NOT_EQUAL: "Khác",
        GREATER_THAN: "Lớn hơn",
        LESS_THAN: "Nhỏ hơn",
        LIST_TARGET: "Danh sách đối tượng",
        TARGET: "Đối tượng",
        LIST_RESOURCE: "Danh sách tính năng",
        TARGET_TYPE: "Loại đối tượng",
        SELECT_TARGET: "Chọn đối tượng",
        SELECT_TARGET_TYPE: "Chọn loại đối tượng",
        CREATE_SUCCESS: "Tạo mới phân quyền thành công",
        UPDATE_SUCCESS: "Cập nhật thông tin phân quyền thành công",
        DELETE_SUCCESS: "Đã xóa phân quyền thành công",

    },

    EVENT: {
        TITLE_MENU: "Quản lý sự kiện",
        TITLE_LIST_VIEW: "Danh sách sự kiện",
        TITLE_UPDATE_VIEW: "Cập nhật sự kiện",
        TITLE_DETAIL_VIEW: "Chi tiết sự kiện",
        SEARCH_TEXT: "Tìm kiếm theo mã, tên",
        ENTER_SEARCH_TEXT: "Nhập mã, tên",

        EVENT_CODE: "Mã sự kiện",
        EVENT_NAME: "Tên sự kiện",
        EVENT_TYPE: "Loại sự kiện",
        PROCESS_STATUS: "Trạng thái xử lý",
        SELECT_EVENT_TYPE: "Chọn loại sự kiện",
        SELECT_PROCESS_STATUS: "Chọn loại trạng thái xử lý",
        SEND: "Gửi",
        RECEIVE: "Nhận",
        PENDING: "Chờ xử lý",
        PROCESSING: "Đang xử lý",
        SUCCESS: "Thành công",
        FAILURE: "Thất bại",
        SEND_TIME: "Thời gian",
        RETRY_COUNT: "Số lần thử lại",
        RETRY: "Thử lại",
        CONFIRM_RETRY: "Bạn có muốn thủ lại lần nữa không?",
        RETRY_SUCCESS: "Thử lại thành công",
    },

    NOTIFICATION: {
        TITLE_MENU: "Thông báo",
        TITLE_LIST_VIEW: "Danh sách thông báo",
        TITLE_UPDATE_VIEW: "Cập nhật thông báo",
        TITLE_DETAIL_VIEW: "Chi tiết thông báo",
        TITLE_CREATE_VIEW: "Thêm mới thông báo",
    },

};
