import { IService } from "commons/interfaces";
import Constants from "constants/index";
import IdentityService from "./indentity.service";

class ServiceService extends IdentityService {
    public getAll = async (data?: any): Promise<IService[]> => {
        const result = await this.api.get({
            path: Constants.ApiPath.SERVICE.GET_ALL,
            query: data,
        });
        return result.data.result;
    }

    public getPaged = async (data: any) => {
        const result = await this.api.get({
            path: `${Constants.ApiPath.SERVICE.GET_PAGED}?${data}`,
        });
        return result.data.result;
    }

    public getDetail = async (id: string) => {
        const result = await this.api.get({
            path: Constants.ApiPath.SERVICE.GET_DETAIL + `/${id}`,
        });
        return result.data.result;
    }

    public create = async (data: any) => {
        const result = await this.api.post({
            path: Constants.ApiPath.SERVICE.CREATE,
            data: data
        });
        return result.data;
    }

    public update = async (data: any) => {
        const result = await this.api.post({
            path: Constants.ApiPath.SERVICE.UPDATE,
            data: data
        });
        return result.data;
    }

    public delete = async (id: string) => {
        const result = await this.api.delete({
            path: Constants.ApiPath.SERVICE.DELETE + `/${id}`,
        });
        return result.data;
    }

    public initRole = async (id: string) => {
        const result = await this.api.post({
            path: Constants.ApiPath.SERVICE.INIT_ROLE + `/${id}`,
        });
        return result.data;
    }

}

export default ServiceService;