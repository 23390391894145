import { useEffect, useState } from "react";

import Helpers from "commons/helpers";
import Strings from "constants/strings";
import { Modal, InputHtmlEditor, Box } from "@maysoft/common-component-react";

interface IProps {
    open: boolean;
    title?: string;
    value?: any;
    disable?: boolean;
    onClose: () => void;
    onAction?: (value: any) => void;
    required?: boolean;
}


function PopupInputEditor(props: IProps) {
    const [error, setError] = useState<string>("");
    const [value, setValue] = useState<string>("");

    useEffect(() => {
        setValue(props.value);
    }, [props.value]);

    const handleBtnAction = () => {
        if (props.required && Helpers.isNullOrEmpty(value)) {
            setError(Strings.Validation.REQUIRED);
        } else {
            Helpers.isFunction(props.onAction) && props.onAction(value);
            handleBtnClose();
        }
    }

    const handleBtnClose = () => {
        setError("");
        setValue("");
        props.onClose();
    }

    return (
        <Modal
            fullWidth
            fullScreen
            maxWidth="xl"
            title={props.title}
            visible={props.open}
            onClose={handleBtnClose}
            onAction={props.disable ? undefined : handleBtnAction}
        >
            <Box height={"80vh"}>
                <InputHtmlEditor
                    maxHeight={"80vh"}
                    errorMessage={error}
                    disable={props.disable}
                    readonly={props.disable}
                    value={props.value || ""}
                    onBlur={(val) => {
                        setValue(val)
                    }}
                />
            </Box>
        </Modal>
    )
};

export default PopupInputEditor;