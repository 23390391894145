import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

import Helpers from "commons/helpers";
import Constants from "constants/index";
import Strings from "constants/strings";
import Screens from "constants/screens";
import InvitationService from "services/identity/invitation.service";

import { RootState } from "store";
import { DashboardLayout } from "layout";
import { setDataAlert } from "store/slice/message.slice";
import { showLoading } from "store/slice/loadingAPI.slice";
import { setListPathName } from "store/slice/titleRoute.slice";
import { InvitationListContainer, MyInvitationContainer, useCommonComponentContext } from "@maysoft/common-component-react";



const invitationService = new InvitationService();

export const commonHandleResendInvitationById = (props: {
    id: string;
    callback: () => void,
    dispatch: (args: any) => void,
}) => {
    Helpers.showConfirmAlert(Strings.INVITATION.CONFIRM_RESEND, async () => {
        try {
            props.dispatch(showLoading(true));

            const result = await invitationService.resend(props.id);
            if (result.statusCode === Constants.ApiCode.SUCCESS) {
                props.callback();
                props.dispatch(setDataAlert({ message: Strings.INVITATION.RESEND_SUCCESS, type: "success" }));
            }

            props.dispatch(showLoading(false));
        } catch (error) {
            props.dispatch(showLoading(false));
            const e = Helpers.renderExceptionError(error);
            props.dispatch(setDataAlert({ message: e, type: "error" }));
        }
    });
};

const InvitationScreen = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();
    const { getResourcePermissions } = useCommonComponentContext();

    const resourcePermissions = getResourcePermissions(Constants.ResourceCode.INVITATION);
    const listPathName = useSelector((state: RootState) => state.titleRoute.listPathName);

    const itemPathName = listPathName.find(el => el.pathName === Screens.MANAGER_INVITATION);

    const [dataRequest] = useState<any>({
        status: searchParams.get("status"),
        validTo: searchParams.get("validTo"),
        validFrom: searchParams.get("validFrom"),
        searchText: searchParams.get("searchText"),
        pageNumber: Number(searchParams.get("pageNumber") || 1),
        pageSize: Number(searchParams.get("pageSize") || Constants.ROW_PER_PAGE),
        totalCount: itemPathName?.totalCount,
    } as any);

    const [dataRequest2] = useState<any>({
        status: searchParams.get("status"),
        validTo: searchParams.get("validTo"),
        validFrom: searchParams.get("validFrom"),
        pageNumber: Number(searchParams.get("pageNumber") || 1),
        pageSize: Number(searchParams.get("pageSize") || Constants.ROW_PER_PAGE),
        totalCount: itemPathName?.totalCount,
    } as any);

    return (
        <DashboardLayout
            isPermission
            title={Strings.INVITATION.TITLE_LIST_VIEW}
            route={[{ title: Strings.INVITATION.TITLE_MENU, route: "" }]}
        >
            {/* <InvitationListContainer
                request={dataRequest}
                onNavigate={(data) => { }}
                onGetPaged={(query: string, totalCount: number) => { }}

                extraHeaders={[
                    {
                        Header: Strings.GROUP.NAME_GROUP,
                        accessor: "groupNames",
                        Cell: (row: any) => {
                            const newValue = [...row.value || []].map(el => el.value[Strings.getLanguage()]);
                            return (
                                <Typography variant="caption" fontWeight="medium" color="text">
                                    {newValue.join(", ")}
                                </Typography>
                            )
                        }
                    },
                    {
                        Header: "Thời gian chấp nhận",
                        accessor: "updateTime",
                        Cell: (row: any) => {
                            if (row?.row?.original?.status !== 2) {
                                return;
                            }
                            return (
                                <Typography variant="caption" fontWeight="medium" color="text">
                                    {Helpers.formatDate(Number(row?.row?.original?.updateTime) * 1000, "HH:MM DD/MM/YYYY")}
                                </Typography>
                            )
                        }
                    },
                ]}
            /> */}

            <MyInvitationContainer
                request={dataRequest2}
                onGetPaged={(query: string, totalCount: number) => {
                    dispatch(setListPathName({ pathname: Screens.MANAGER_INVITATION, query, totalCount: totalCount }));
                    navigate(Screens.MANAGER_INVITATION + query, { replace: true });
                }}
            />
        </DashboardLayout >
    )
}

export default InvitationScreen;