import { Card } from "@mui/material";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, DataTable, useCommonComponentContext } from "@maysoft/common-component-react";

import Helpers from "commons/helpers";
import Strings from "constants/strings";
import Screens from "constants/screens";
import Constants from "constants/index";

import { DashboardLayout } from "layout";
import { setDataAlert } from "store/slice/message.slice";
import { showLoading } from "store/slice/loadingAPI.slice";
import { Mode, SystemSettingType } from "constants/enum";



export interface ISettingCode {
    id: string;
    status: number;
    name?: string;
    description?: string;
    settingCode: string;
    settingValue?: string;
    organizationId?: string;

    tenantCode: string;
    serviceCode: string;
    createTime?: string;
    createUser?: string;
    updateTime?: string;
    updateUser?: string;
}

const SystemSettingScreen = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { getResourcePermissions } = useCommonComponentContext();

    const resourcePermissions = getResourcePermissions(Constants.ResourceCode.SYSTEM_SETTING);

    const [dataRows, setDataRows] = useState<ISettingCode[]>([]);

    useEffect(() => { onStart(); }, []);

    const onStart = async () => {
        try {
            dispatch(showLoading(true));

            const rows = [
                {
                    id: "mf023932",
                    tenantCode: "",
                    serviceCode: "",
                    status: 1,
                    name: Strings.SYSTEM_SETTING.SETTING_CONFIG_FIREBASE,
                    description: Strings.SYSTEM_SETTING.SETTING_CONFIG_FIREBASE,
                    settingCode: SystemSettingType.MessageFireBase,
                }
            ];

            setDataRows(rows);

            dispatch(showLoading(false));
        } catch (error) {
            dispatch(showLoading(false));
            const e = Helpers.renderExceptionError(error);
            dispatch(setDataAlert({ message: e, type: "error" }));
        }
    };

    return (
        <DashboardLayout
            isPermission={resourcePermissions.canRead}
            title={Strings.SYSTEM_SETTING.TITLE_LIST_VIEW}
            route={[{ title: Strings.SYSTEM_SETTING.TITLE_MENU, route: "" }]}
        >
            <Card sx={{ mt: 3 }}>
                <Box p={2}>
                    <DataTable
                        isShowIndex
                        loading={false}

                        pageNumber={1}
                        rowPerPage={Constants.ROW_PER_PAGE}
                        totalCount={dataRows?.length || 0}
                        table={{
                            columns: [
                                { Header: Strings.SYSTEM_SETTING.CODE, accessor: "settingCode", width: "100px" },
                                { Header: Strings.SYSTEM_SETTING.NAME, accessor: "name", width: "150px" },
                                { Header: Strings.SYSTEM_SETTING.DESCRIPTION, accessor: "description", width: "200px" },
                            ],
                            rows: dataRows || [],
                        }}

                        actionList={(row) => [
                            {
                                key: "detail",
                                iconName: "view",
                                title: Strings.Common.VIEW,
                                callback: (row) => navigate(Screens.SYSTEM_SETTING_EDIT + `?settingCode=${row.settingCode}&mode=${Mode.View}`),
                            },
                            resourcePermissions.canUpdate &&
                            {
                                key: "edit",
                                iconName: "edit",
                                title: Strings.Common.EDIT,
                                callback: (row) => navigate(Screens.SYSTEM_SETTING_EDIT + `?settingCode=${row.settingCode}&mode=${Mode.Update}`),
                            },
                        ]}
                    />
                </Box>
            </Card>
        </DashboardLayout>
    );
};

export default SystemSettingScreen;
