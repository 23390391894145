import Helpers from "commons/helpers";
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { ICodename, IRecordMenuDetail } from "commons/interfaces"
import { IUserProfile } from "@maysoft/common-component-react";

export interface IResourcePermission {
    resourceURI: string,
    permission: number,
};

export interface UserInfoState {
    fetching?: boolean;
    userProfile?: IUserProfile;
    currentOrganization?: string;

    resourceMenu?: string[];
    listGroup?: ICodename[];
    listOrganization?: ICodename[];
    menuDetails?: IRecordMenuDetail[];
    resourceCodes?: IResourcePermission[];
}

const initialState: UserInfoState = {
    fetching: false,
    userProfile: undefined,
    listGroup: [],
    currentOrganization: undefined,
    listOrganization: [],
    resourceCodes: [],
    resourceMenu: [],
    menuDetails: [],
}

export const userInfoSlice = createSlice({
    name: "userInfo",
    initialState,
    reducers: {
        fetchUserInfo: (state) => {
            state.fetching = true;
        },

        fetchUserInfoSuccess: (state, action: PayloadAction<UserInfoState | undefined>) => {
            state.fetching = false;
            if (!Helpers.isNullOrEmpty(action.payload)) {
                state.listGroup = action.payload.listGroup;
                state.menuDetails = action.payload.menuDetails;
                state.userProfile = action.payload.userProfile;
                state.resourceMenu = action.payload.resourceMenu;
                state.resourceCodes = action.payload.resourceCodes;
                state.listOrganization = action.payload.listOrganization;
            }
        },

        fetchUserInfoFailed: (state) => {
            state.fetching = false;
        },

        storeDataUserProfile: (state, action: PayloadAction<IUserProfile>) => {
            state.userProfile = action.payload;
        },

        storePhoneNumber: (state, action: PayloadAction<string>) => {
            state.userProfile.phoneNumber = action.payload
        },
        storeEmail: (state, action: PayloadAction<string>) => {
            state.userProfile.email = action.payload;
        },
        updateUserProfile: (state, action: PayloadAction<any>) => {
            state.userProfile.fullName = action.payload.fullName;
            state.userProfile.birthDate = action.payload.birthDate;
            state.userProfile.gender = action.payload.gender;
        },
        updateAvatar: (state, action: PayloadAction<any>) => {
            state.userProfile.avatarId = action.payload.avatarId;
            state.userProfile.avatarUrl = action.payload.avatarUrl;
        },
        updateOrganization: (state, action: PayloadAction<any>) => {
            state.userProfile.organizationId = action.payload;
        },
        storeOrganizationID: (state, action: PayloadAction<string>) => {
            state.userProfile.organizationId = action.payload
        },
        storeListOrganization: (state, action: PayloadAction<ICodename[]>) => {
            state.listOrganization = action.payload
        },
        storeResourceMenu: (state, action: PayloadAction<string[]>) => {
            state.resourceMenu = action.payload
        },
        storeResourceCodePermission: (state, action: PayloadAction<IResourcePermission[]>) => {
            state.resourceCodes = action.payload
        },
        resetUserInfo: () => initialState
    }
})

// Action creators are generated for each case reducer function
export const {
    fetchUserInfo,
    fetchUserInfoSuccess,
    fetchUserInfoFailed,
    storePhoneNumber,
    storeEmail,
    updateUserProfile,
    updateAvatar,
    updateOrganization,
    resetUserInfo,
    storeListOrganization,
    storeResourceMenu,
    storeOrganizationID,
    storeDataUserProfile,
    storeResourceCodePermission,
} = userInfoSlice.actions

export default userInfoSlice.reducer