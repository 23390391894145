import Constants from "constants/index";
import IdentityService from "./indentity.service";

class ResourceService extends IdentityService {
    public getAll = async (data: any) => {
        const result = await this.api.get({
            path: Constants.ApiPath.RESOURCE.GET_ALL,
            query: data
        });
        return result.data.result;
    }

    public getTemplate = async(data: any) => {
        const result = await this.api.get({
            path: Constants.ApiPath.RESOURCE.GET_TEMPLATE,
            query: data
        });
        return result.data.result;
    }

    public getPaged = async (data: any) => {
        const result = await this.api.get({
            path: Constants.ApiPath.RESOURCE.GET_PAGED,
            query: data
        });
        return result.data.result;
    }

    public getDefaultResourceDetail = async () => {
        const result = await this.api.get({
            path: Constants.ApiPath.RESOURCE.GET_DEFAULT_RESOURCE_DETAIL,
        });
        return result.data.result;
    }

    public getDetailById = async (id: any) => {
        const result = await this.api.get({
            path: Constants.ApiPath.RESOURCE.GET_DETAIL + `/${id}`,
        });
        return result.data.result;
    }

    public deleteById = async (data: string[]) => {
        const result = await this.api.delete({
            path: Constants.ApiPath.RESOURCE.DELETE,
            data: data
        });
        return result.data;
    }

    public create = async (data: any) => {
        const result = await this.api.post({
            path: Constants.ApiPath.RESOURCE.CREATE,
            data
        });
        return result.data;
    }

    public update = async (data: any) => {
        const result = await this.api.post({
            path: Constants.ApiPath.RESOURCE.UPDATE,
            data
        });
        return result.data;
    }

    public initData = async (serviceCode: string) => {
        const result = await this.api.post({
            path: Constants.ApiPath.RESOURCE.INIT_DATA,
            data: { "serviceCode": serviceCode }
        });
        return result.data;
    }

}

export default ResourceService;