
import { configureStore } from "@reduxjs/toolkit";

import rootSaga from "./saga/root.saga";
import authReducer from "./slice/auth.slice";
import createSagaMiddleware from "redux-saga";
import messageReducer from "./slice/message.slice";
import userInfoReducer from "./slice/userInfo.slice";
import loadingAPIReducer from "./slice/loadingAPI.slice";
import titleRouteReducer from "./slice/titleRoute.slice";

const sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
    reducer: {
        auth: authReducer,
        messageSlice: messageReducer,
        loadingAPI: loadingAPIReducer,
        titleRoute: titleRouteReducer,
        userInfo: userInfoReducer,
    },
    devTools: true,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(sagaMiddleware),
});

sagaMiddleware.run(rootSaga);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;