import moment from "moment";
import { Card, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Autocomplete, Box, Chip, DataTable, DateTimePicker, FormField, Typography } from "@maysoft/common-component-react";

import Helpers from "commons/helpers";
import Constants from "constants/index";
import Strings from "constants/strings";
import Screens from "constants/screens";
import LoginLogService from "services/identity/loginlog.service";

import { RootState } from "store";
import { DashboardLayout } from "layout";
import { ICodename } from "commons/interfaces";
import { setDataAlert } from "store/slice/message.slice";
import { showLoading } from "store/slice/loadingAPI.slice";
import { setListPathName } from "store/slice/titleRoute.slice";
import { commonGetAllDataService } from "screens/service";
import UserService from "services/identity/user.service";

interface IRecordLoginLog {
    id?: string
    email?: string
    userId?: string
    userName?: string
    serviceName?: string
    tenantCode?: string
    serviceCode?: string
    clientId?: string
    sessionId?: string
    deviceId?: string
    deviceInfo?: {
        browserName?: string
        browserVersion?: string
        deviceType?: string
        osVersion?: string
        osName?: string
    },
    location?: string
    ipAddress?: string
    loginTime?: string
    logoutTime?: string
    status?: number,
    createTime?: string
    createUser?: string
    updateTime?: string
    updateUser?: string
}

interface IRequestData {
    serviceCode?: string;
    userIds?: string[];
    loginStart?: string;
    loginEnd?: string;
    logoutStart?: string;
    logoutEnd?: string;
    searchText?: string;
    pageNumber?: number;
    pageSize?: number;
    orderby?: string;
    totalCount?: number;
}

interface IModel {
    requestData?: IRequestData;
    requestDataTemp?: IRequestData;
}

const userService = new UserService();
const loginLogService = new LoginLogService();

const LoginLogScreen = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const [model, setModel] = useState<IModel>({} as IModel);
    const [listUser, setListUser] = useState<ICodename[]>([]);
    const [listService, setListService] = useState<ICodename[]>([]);
    const [dataRows, setDataRows] = useState<IRecordLoginLog[]>([]);

    const userProfile = useSelector((state: RootState) => state.userInfo?.userProfile);
    const listPathName = useSelector((state: RootState) => state.titleRoute.listPathName);

    useEffect(() => { onStarted() }, [userProfile?.organizationId]);

    const onStarted = async () => {
        try {
            dispatch(showLoading(true));

            let listUserTemp: ICodename[] = [];

            const listServiceTemp = await commonGetAllDataService();

            if (!Helpers.isNullOrEmpty(userProfile?.organizationId) && userProfile?.organizationId !== "0") {
                const resultGetUser = await userService.getPaged(Helpers.handleFormatParams({
                    pageNumber: 1,
                    pageSize: 99999,
                    organizationId: userProfile?.organizationId,
                }));
                [...resultGetUser?.items || []].forEach((item: any) => {
                    listUserTemp.push({ code: item.id, name: item.organizationUserProfile?.firstName });
                });
            }

            const itemPath = listPathName.find(el => el.pathName === Screens.LOGIN_LOG);
            const dataReq: IRequestData = {
                totalCount: itemPath?.totalCount,
                searchText: searchParams.get("searchText"),
                serviceCode: searchParams.get("serviceCode"),
                loginStart: searchParams.get("loginStart"),
                loginEnd: searchParams.get("loginEnd"),
                logoutStart: searchParams.get("logoutStart"),
                logoutEnd: searchParams.get("logoutEnd"),
                userIds: searchParams.getAll("userIds"),
                pageNumber: Number(searchParams.get("pageNumber") || 1),
                pageSize: Number(searchParams.get("pageSize") || Constants.ROW_PER_PAGE),
            };

            setListService(listServiceTemp);
            setListUser(listUserTemp);

            await getPaged(dataReq);

            dispatch(showLoading(false));
        } catch (error) {
            dispatch(showLoading(false));
            const e = Helpers.renderExceptionError(error);
            dispatch(setDataAlert({ message: e, type: "error" }));
        }
    }

    const getPaged = async (data?: IRequestData) => {
        try {
            dispatch(showLoading(true));

            const pageSize = data?.pageSize || Constants.ROW_PER_PAGE;
            const pageNumber = Helpers.getPageNumber(data?.pageNumber || 1, pageSize, data?.totalCount || 0);

            const newReq = {
                pageSize: pageSize,
                pageNumber: pageNumber,
                serviceCode: data?.serviceCode,
                userIds: data?.userIds,
                loginStart: data?.loginStart,
                loginEnd: data?.loginEnd,
                logoutStart: data?.logoutStart,
                logoutEnd: data?.logoutEnd,
                searchText: data?.searchText,
            };

            const result = await loginLogService.getPaged(newReq);

            let query: string = `?pageNumber=${newReq?.pageNumber}&pageSize=${newReq?.pageSize}`;
            if (!Helpers.isNullOrEmpty(newReq?.serviceCode)) {
                query = query + `&serviceCode=${newReq?.serviceCode}`
            }
            if (!Helpers.isNullOrEmpty(newReq?.loginStart)) {
                query = query + `&loginStart=${newReq?.loginStart}`
            }
            if (!Helpers.isNullOrEmpty(newReq?.loginEnd)) {
                query = query + `&loginEnd=${newReq?.loginEnd}`
            }
            if (!Helpers.isNullOrEmpty(newReq?.logoutStart)) {
                query = query + `&logoutStart=${newReq?.logoutStart}`
            }
            if (!Helpers.isNullOrEmpty(newReq?.logoutEnd)) {
                query = query + `&logoutEnd=${newReq?.logoutEnd}`
            }
            if ([...newReq?.userIds || []].length > 0) {
                [...newReq?.userIds || []].forEach(id => {
                    query = query + `&userIds=${id}`
                });
            }

            setDataRows([...result?.items || []]);
            setModel({
                requestData: { ...newReq, totalCount: result?.totalCount },
                requestDataTemp: { ...newReq, totalCount: result?.totalCount },
            })

            navigate(Screens.LOGIN_LOG + query, { replace: true });

            dispatch(setListPathName({ pathname: Screens.LOGIN_LOG, query, totalCount: result.totalCount }));
            dispatch(showLoading(false));
        } catch (error) {
            dispatch(showLoading(false));
            const e = Helpers.renderExceptionError(error);
            dispatch(setDataAlert({ message: e, type: "error" }));
        }
    }

    const getValueDateTime = (value: any, x?: boolean) => {
        return (Helpers.isNullOrEmpty(value) || value === "0" || value === 0)
            ? undefined
            : (x ? Number(value) * 1000 : Number(value));
    };

    const renderBoxTime = (value: any) => {
        if (Helpers.isNullOrEmpty(value) ||
            "9223372036854775807" === value || Number(value) === 9223372036854775807) {
            return (
                <Typography
                    color="text"
                    variant="caption"
                    fontWeight="medium"
                >
                    {""}
                </Typography>
            );
        } else {
            const dateStr = moment(Number(value) * 1000).format(Constants.DateFormat.DDMMYYYY);
            const timeStr = moment(Number(value) * 1000).format("HH:mm:ss");
            return (
                <Box display="grid">
                    <Typography
                        color="text"
                        variant="caption"
                        fontWeight="medium"
                    >
                        {dateStr}
                    </Typography>
                    <Typography
                        color="secondary"
                        variant="caption"
                    >
                        {timeStr}
                    </Typography>
                </Box>
            );
        }
    }

    const renderStringTimeFilter = (startTime?: string, endTime?: string) => {
        let nameText = "";
        if (!Helpers.isNullOrEmpty(startTime) && !Helpers.isNullOrEmpty(endTime)) {
            nameText = nameText + moment(Number(startTime) * 1000).format(Constants.DateFormat.DDMMYYYY_HHMMSS);
            nameText = nameText + " - " + moment(Number(endTime) * 1000).format(Constants.DateFormat.DDMMYYYY_HHMMSS);
        } else {
            if (!Helpers.isNullOrEmpty(startTime)) {
                nameText = moment(Number(startTime) * 1000).format(Constants.DateFormat.DDMMYYYY_HHMMSS) + " - __/__/__";
            }
            if (!Helpers.isNullOrEmpty(endTime)) {
                nameText = "__/__/__ - " + moment(Number(endTime) * 1000).format(Constants.DateFormat.DDMMYYYY_HHMMSS);
            }
        }
        return nameText;
    }

    const filterForm = () => {
        return (
            <Box>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <FormField
                            maxLength={255}
                            label={Strings.LOGIN_LOG.SEARCH_TEXT}
                            placeholder={Strings.LOGIN_LOG.ENTER_SEARCH_TEXT}
                            defaultValue={model.requestDataTemp?.searchText}
                            onChange={(value) => {
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Autocomplete
                            data={listService || []}
                            label={Strings.SERVICE.NAME}
                            placeholder={Strings.SERVICE.SELECT_SERVICE}
                            defaultValue={model.requestDataTemp?.serviceCode}
                            onChange={(value) => {
                                setModel({
                                    ...model, requestDataTemp: {
                                        ...model.requestDataTemp,
                                        serviceCode: value,
                                    },
                                })
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Autocomplete
                            multiple
                            data={listUser || []}
                            label={Strings.LOGIN_LOG.USER}
                            placeholder={Strings.LOGIN_LOG.SELECT_USER}
                            defaultValue={model.requestDataTemp?.userIds}
                            onChange={(value) => {
                                setModel({
                                    ...model, requestDataTemp: {
                                        ...model.requestDataTemp,
                                        userIds: value,
                                    },
                                })
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="caption" fontWeight="regular" sx={{ display: "block" }}>
                            {Strings.LOGIN_LOG.LOGIN_TIME}
                        </Typography>
                        <Box display="flex" alignItems="center" justifyContent="space-between">
                            <Box width="49%">
                                <DateTimePicker
                                    placeholder={Strings.LOGIN_LOG.ENTER_START_TIME}
                                    value={getValueDateTime(model.requestDataTemp?.loginStart, true)}
                                    onChangeValue={(e: any) => {
                                        let secondDay = getValueDateTime(model.requestDataTemp?.loginEnd);
                                        const value = !Helpers.isNullOrEmpty(e) ? moment(e).unix() : undefined;

                                        if (
                                            !Helpers.isNullOrEmpty(secondDay) &&
                                            (value > secondDay) &&
                                            !Helpers.isNullOrEmpty(value)
                                        ) {
                                            secondDay = value;
                                        }

                                        const dataTemp: any = {
                                            ...model.requestDataTemp,
                                            loginEnd: secondDay,
                                            loginStart: value,
                                        };

                                        setModel({ ...model, requestDataTemp: dataTemp })
                                    }}
                                />
                            </Box>
                            <Box width="49%">
                                <DateTimePicker
                                    placeholder={Strings.LOGIN_LOG.ENTER_END_TIME}
                                    value={getValueDateTime(model.requestDataTemp?.loginEnd, true)}
                                    onChangeValue={(e: any) => {
                                        let secondDay = getValueDateTime(model.requestDataTemp?.loginStart);
                                        const value = !Helpers.isNullOrEmpty(e) ? moment(e).unix() : undefined;

                                        if (
                                            !Helpers.isNullOrEmpty(secondDay) &&
                                            (value < secondDay) &&
                                            !Helpers.isNullOrEmpty(value)
                                        ) {
                                            secondDay = value;
                                        }

                                        const dataTemp: any = {
                                            ...model.requestDataTemp,
                                            loginStart: secondDay,
                                            loginEnd: value,
                                        };

                                        setModel({ ...model, requestDataTemp: dataTemp })
                                    }}
                                />
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="caption" fontWeight="regular" sx={{ display: "block" }}>
                            {Strings.LOGIN_LOG.LOGOUT_TIME}
                        </Typography>
                        <Box display="flex" alignItems="center" justifyContent="space-between">
                            <Box width="49%">
                                <DateTimePicker
                                    placeholder={Strings.LOGIN_LOG.ENTER_START_TIME}
                                    value={getValueDateTime(model.requestDataTemp?.logoutStart, true)}
                                    onChangeValue={(e: any) => {
                                        let secondDay = getValueDateTime(model.requestDataTemp?.logoutEnd);
                                        const value = !Helpers.isNullOrEmpty(e) ? moment(e).unix() : undefined;

                                        if (
                                            !Helpers.isNullOrEmpty(secondDay) &&
                                            (value > secondDay) &&
                                            !Helpers.isNullOrEmpty(value)
                                        ) {
                                            secondDay = value;
                                        }

                                        const dataTemp: any = {
                                            ...model.requestDataTemp,
                                            logoutEnd: secondDay,
                                            logoutStart: value,
                                        };

                                        setModel({ ...model, requestDataTemp: dataTemp })
                                    }}
                                />
                            </Box>
                            <Box width="49%">
                                <DateTimePicker
                                    placeholder={Strings.LOGIN_LOG.ENTER_END_TIME}
                                    value={getValueDateTime(model.requestDataTemp?.logoutEnd, true)}
                                    onChangeValue={(e: any) => {
                                        let secondDay = getValueDateTime(model.requestDataTemp?.logoutStart);
                                        const value = !Helpers.isNullOrEmpty(e) ? moment(e).unix() : undefined;

                                        if (
                                            !Helpers.isNullOrEmpty(secondDay) &&
                                            (value < secondDay) &&
                                            !Helpers.isNullOrEmpty(value)
                                        ) {
                                            secondDay = value;
                                        }

                                        const dataTemp: any = {
                                            ...model.requestDataTemp,
                                            logoutStart: secondDay,
                                            logoutEnd: value,
                                        };

                                        setModel({ ...model, requestDataTemp: dataTemp })
                                    }}
                                />
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        );
    };

    return (
        <DashboardLayout
            isPermission
            title={Strings.LOGIN_LOG.TITLE_LIST_VIEW}
            route={[{ title: Strings.LOGIN_LOG.TITLE_MENU, route: "" }]}
        >
            <Box>
                <Box mb={1}>
                    {
                        !Helpers.isNullOrEmpty(model.requestData?.serviceCode) &&
                        <Chip
                            label={Strings.SERVICE.NAME}
                            value={listService.find(el => el.code === model.requestData?.serviceCode)?.name || ""}
                            onDelete={() => {
                                getPaged({ ...model.requestData, serviceCode: undefined });
                            }}
                        />
                    }
                    {
                        ([...model.requestData?.userIds || []].length > 0) &&
                        [...model.requestData?.userIds || []].map((item) => (
                            <Chip
                                label={Strings.LOGIN_LOG.USER}
                                value={listUser.find(el => el.code === item)?.name || ""}
                                onDelete={() => {
                                    const listTemp = [...model.requestData?.userIds || []].filter(el => el !== item);
                                    getPaged({ ...model.requestData, userIds: listTemp });
                                }}
                            />
                        ))
                    }
                    {
                        (!Helpers.isNullOrEmpty(model.requestData?.loginStart) ||
                            !Helpers.isNullOrEmpty(model.requestData?.loginEnd)) &&
                        <Chip
                            label={Strings.LOGIN_LOG.LOGIN_TIME}
                            value={renderStringTimeFilter(model.requestData?.loginStart, model.requestData?.loginEnd)}
                            onDelete={() => {
                                getPaged({ ...model.requestData, loginStart: undefined, loginEnd: undefined });
                            }}
                        />
                    }
                    {
                        (!Helpers.isNullOrEmpty(model.requestData?.logoutStart) ||
                            !Helpers.isNullOrEmpty(model.requestData?.logoutEnd)) &&
                        <Chip
                            label={Strings.LOGIN_LOG.LOGOUT_TIME}
                            value={renderStringTimeFilter(model.requestData?.logoutStart, model.requestData?.logoutEnd)}
                            onDelete={() => {
                                getPaged({ ...model.requestData, logoutStart: undefined, logoutEnd: undefined });
                            }}
                        />
                    }
                </Box>
                <Card>
                    <Box p={2}>
                        <DataTable
                            isShowIndex
                            loading={false}
                            totalCount={model.requestData?.totalCount}
                            pageNumber={model.requestData?.pageNumber}
                            rowPerPage={model.requestData?.pageSize || Constants.ROW_PER_PAGE}
                            onChangePageSize={(pageSize) => getPaged({ ...model.requestData, pageSize })}
                            onChangePageNumber={(pageNumber) => getPaged({ ...model.requestData, pageNumber })}

                            searchText={model.requestData?.searchText || ""}
                            placeholderSearch={Strings.LOGIN_LOG.SEARCH_TEXT}
                            onSearch={(val) => getPaged({ ...model, searchText: val })}
                            onSearchChange={(val) => {
                                setModel({
                                    requestData: { ...model.requestData, searchText: val },
                                    requestDataTemp: { ...model.requestDataTemp, searchText: val },
                                });
                            }}

                            filterForm={filterForm()}
                            onFilter={() => {
                                getPaged({ ...model.requestDataTemp });
                            }}
                            onCloseFilter={() => {
                                const dataTemp = { ...model.requestData };
                                setModel({ ...model, requestDataTemp: dataTemp });
                            }}
                            onReset={() => {
                                const dataTemp: IRequestData = {
                                    ...model.requestData,
                                    loginStart: undefined,
                                    loginEnd: undefined,
                                    logoutStart: undefined,
                                    logoutEnd: undefined,
                                    serviceCode: undefined,
                                    userIds: undefined,
                                };
                                getPaged({ ...dataTemp });
                            }}

                            table={{
                                columns: [
                                    {
                                        Header: Strings.LOGIN_LOG.USER, accessor: "userName", width: "180px",
                                        Cell: (row: any) => (
                                            <Box display="grid">
                                                <Typography
                                                    color="text"
                                                    variant="caption"
                                                    fontWeight="medium"
                                                >
                                                    {row.value}
                                                </Typography>
                                                <Typography
                                                    color="secondary"
                                                    variant="caption"
                                                >
                                                    {row.row?.original?.email}
                                                </Typography>
                                            </Box>
                                        ),
                                    },
                                    {
                                        Header: Strings.LOGIN_LOG.LOGIN_TIME, accessor: "loginTime", width: "130px",
                                        Cell: ({ value }: any) => renderBoxTime(value),
                                    },
                                    {
                                        Header: Strings.LOGIN_LOG.LOGOUT_TIME, accessor: "logoutTime", width: "120px",
                                        Cell: ({ value }: any) => renderBoxTime(value),
                                    },
                                    {
                                        Header: Strings.LOGIN_LOG.LOCATION, accessor: "location", width: "120px",
                                        Cell: (row: any) => (
                                            <Box display="grid">
                                                <Typography
                                                    color="text"
                                                    variant="caption"
                                                    fontWeight="medium"
                                                >
                                                    {row.value}
                                                </Typography>
                                                <Typography
                                                    color="secondary"
                                                    variant="caption"
                                                >
                                                    {`(${row.row?.original?.ipAddress})`}
                                                </Typography>
                                            </Box>
                                        ),
                                    },
                                ],
                                rows: dataRows || []
                            }}
                        />
                    </Box>
                </Card>
            </Box>
        </DashboardLayout>
    );
}

export default LoginLogScreen;