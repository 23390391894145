import APIAccessor from "services/apiProcessor";
import Constants, { MESSAGE_API_URL } from "../../constants";

class MessageService {
    protected api: APIAccessor;

    constructor() {
        this.api = new APIAccessor(MESSAGE_API_URL);
    }

    public getBySettingCode = async (settingCode: string, organizationId?: string) => {
        const query = organizationId ? `?organizationId=${organizationId}` : "";
        const result = await this.api.get({
            path: `${Constants.ApiPath.SYSTEM_SETTING.GET_BY_SETTINGCODE}/${settingCode}${query}`,
        });
        return result.data.result;
    }

}

export default MessageService