import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IDataAlert {
    message?: string,
    type: "warning" | "success" | "error" | "info",
}

export interface IMessageFirebase {
    messageId?: string,
    data?: {
        Title?: string,
        Body?: string,
    }
}

export interface MessageState {
    dataAlert: IDataAlert;
    totalUnreadNotifi: number;
    dataMessage: IMessageFirebase;
}

const initialState: MessageState = {
    totalUnreadNotifi: 0,
    dataMessage: {
        data: undefined,
        messageId: undefined,
    },
    dataAlert: { message: "", type: "info" },
}

export const messageSlice = createSlice({
    name: "message",
    initialState,
    reducers: {
        setDataMessage: (state, action: PayloadAction<IMessageFirebase>) => {
            state.dataMessage = action.payload;
        },
        resetDataMessage: (state) => {
            state.dataMessage = {
                data: undefined,
                messageId: undefined,
            };
        },

        setDataAlert: (state, action: PayloadAction<IDataAlert>) => {
            state.dataAlert = action.payload;
        },
        resetDataAlert: (state) => {
            state.dataAlert = { message: "", type: undefined };
        },

        setAddUnreadNotifi: (state) => {
            state.totalUnreadNotifi = (state.totalUnreadNotifi || 0) + 1;
        },
        setTotalUnreadNotifi: (state, action: PayloadAction<number | undefined>) => {
            state.totalUnreadNotifi = action.payload || 0;
        },

    }
})

// Action creators are generated for each case reducer function
export const {
    setAddUnreadNotifi, setTotalUnreadNotifi,
    setDataMessage, resetDataMessage,
    setDataAlert, resetDataAlert,
} = messageSlice.actions

export default messageSlice.reducer;