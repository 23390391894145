import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ModuleNotification } from "@maysoft/circle-common-react";

import Helpers from "commons/helpers";
import Screens from "constants/screens";
import Strings from "constants/strings";
import Constants from "constants/index";

import { RootState } from "store";
import { Mode } from "constants/enum";
import { DashboardLayout } from "layout";
import { IModel } from "commons/interfaces";
import { setListPathName } from "store/slice/titleRoute.slice";


const NotificationScreen = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();

    const { id } = Helpers.getUrlParams(["id"]);
    const modeList = (Screens.NOTIFICATION_LIST.includes(window.location.pathname));

    const listPathName = useSelector((state: RootState) => state.titleRoute.listPathName);

    const itemPathName = listPathName.find(el => el.pathName === Screens.NOTIFICATION_LIST);

    const userProfile = useSelector((state: RootState) => state.userInfo?.userProfile);
    const pramsMode = !Helpers.isNullOrEmpty(searchParams.get("mode")) ? Number(searchParams.get("mode")) : undefined;

    const [model, setModel] = useState<IModel>({ mode: pramsMode || Mode.View });

    const renderValueNumber = (value: string | null | undefined) => {
        return Helpers.isNullOrEmpty(value) ? undefined : Number(value);
    }

    const [dataRequest] = useState<any>({
        endTime: renderValueNumber(searchParams.get("endTime")),
        byMyself: renderValueNumber(searchParams.get("byMyself")),
        startTime: renderValueNumber(searchParams.get("startTime")),
        readStatus: renderValueNumber(searchParams.get("readStatus")),
        messageType: renderValueNumber(searchParams.get("messageType")),

        searchText: searchParams.get("searchText"),
        totalCount: itemPathName?.totalCount || 0,
        pageNumber: Number(searchParams.get("pageNumber") || 1),
        pageSize: Number(searchParams.get("pageSize") || Constants.ROW_PER_PAGE),
    });

    useEffect(() => {
        if (!Helpers.isNullOrEmpty(userProfile?.organizationId)) {
            if (Helpers.isNullOrEmpty(id)) {
                setModel({
                    mode: Mode.Create,
                    title: Strings.NOTIFICATION.TITLE_CREATE_VIEW,
                    route: [
                        { title: Strings.NOTIFICATION.TITLE_MENU, route: Screens.NOTIFICATION_LIST },
                        { title: Strings.Common.CREATE_NEW, route: "" },
                    ],
                });
            } else {
                let mode: number = pramsMode || Mode.View;
                setModel({
                    mode: mode,
                    title: (mode === Mode.View)
                        ? Strings.NOTIFICATION.TITLE_DETAIL_VIEW
                        : Strings.NOTIFICATION.TITLE_UPDATE_VIEW,
                    route: [
                        { title: Strings.NOTIFICATION.TITLE_MENU, route: Screens.NOTIFICATION_LIST },
                        {
                            title: (mode === Mode.View) ? Strings.Common.DETAIL : Strings.Common.UPDATE,
                            route: "",
                        },
                    ],
                });
                navigate(Screens.NOTIFICATION_EDIT + `?id=${id}&mode=${mode}`, { replace: true });
            }
        };
    }, [id, userProfile?.organizationId]);

    return (
        <DashboardLayout
            isPermission
            isRequiredOrganization
            title={modeList ? Strings.NOTIFICATION.TITLE_LIST_VIEW : model.title}
            route={modeList ? [{ title: Strings.NOTIFICATION.TITLE_MENU, route: "" }] : model.route}
        >
            <>
                <ModuleNotification
                    keyModuleContainer={{
                        list: { pathName: Screens.NOTIFICATION_LIST },
                        detail: { pathName: Screens.NOTIFICATION_EDIT },
                    }}

                    requestGetPaged={dataRequest}
                    onGetPaged={({ query, totalCount }) => {
                        dispatch(setListPathName({ pathname: Screens.NOTIFICATION_LIST, query, totalCount: totalCount }));
                        navigate(Screens.NOTIFICATION_LIST + query, { replace: true });
                    }}
                    onNavigate={(data: any) => {
                        if (data?.id) {
                            navigate(Screens.NOTIFICATION_EDIT + `?id=${data.id}&mode${data.mode}`);
                        } else {
                            navigate(Screens.NOTIFICATION_EDIT);
                        }
                    }}

                    idDetail={id}
                    mode={model.mode}
                    onGoBack={() => {
                        const itemP = listPathName.find(el => el.pathName === Screens.NOTIFICATION_LIST);
                        navigate(Screens.NOTIFICATION_LIST + (itemP ? itemP.query : ""));
                    }}
                    onChangeMode={(value: number) => {
                        setModel({
                            mode: value,
                            title: (value === Mode.View)
                                ? Strings.GROUP.TITLE_DETAIL_VIEW : Strings.GROUP.TITLE_UPDATE_VIEW,
                            route: [
                                { title: Strings.GROUP.TITLE_MENU, route: Screens.NOTIFICATION_LIST },
                                {
                                    title: (value === Mode.View) ? Strings.Common.DETAIL : Strings.Common.UPDATE,
                                    route: ""
                                }
                            ],
                        });
                        navigate(Screens.NOTIFICATION_EDIT + `?id=${id}&mode=${value}`, { replace: true });
                    }}
                />
            </>
        </DashboardLayout>
    );
};

export default NotificationScreen;
