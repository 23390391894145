import { Card, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import Helpers from "commons/helpers";
import Constants from "constants/index";
import Strings from "constants/strings";
import Screens from "constants/screens";
import EventService from "services/identity/events.service";

import { RootState } from "store";
import { Mode, ProcessStatus } from "constants/enum";
import { DashboardLayout } from "layout";
import { ITitleRoute } from "commons/interfaces";
import { setDataAlert } from "store/slice/message.slice";
import { showLoading } from "store/slice/loadingAPI.slice";
import { IRecordEvent, listProcessStatusesEvent, listTypeEvent } from ".";
import { Box, DatePicker, Autocomplete, FormField, useCommonComponentContext } from "@maysoft/common-component-react";



interface IModel {
    mode?: Mode;
    title?: string;
    route?: ITitleRoute[];
}

const eventService = new EventService();

const EventDetailScreen = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const { getResourcePermissions } = useCommonComponentContext();

    const resourcePermissions = getResourcePermissions(Constants.ResourceCode.EVENT);
    const listPathName = useSelector((state: RootState) => state.titleRoute.listPathName);

    const [error, setError] = useState<string>("");
    const [model, setModel] = useState<IModel>({} as IModel);
    const [isOnChange, setIsOnChange] = useState<boolean>(false);
    const [dataDetail, setDataDetail] = useState<IRecordEvent>({} as IRecordEvent);

    useEffect(() => {
        (async () => {
            dispatch(showLoading(true));

            const stateLocal = location?.state as { mode?: number, dataDetail?: IRecordEvent };

            let mode: number = stateLocal?.mode ? stateLocal?.mode : Mode.Update;

            if (!resourcePermissions.canUpdate || stateLocal?.dataDetail?.processStatus === ProcessStatus.Failure) {
                mode = Mode.View;
            }

            let title: string = (mode === Mode.View) ? Strings.EVENT.TITLE_DETAIL_VIEW : Strings.EVENT.TITLE_UPDATE_VIEW;

            let route = [
                { title: Strings.EVENT.TITLE_MENU, route: Screens.MANAGER_EVENT },
                { title: (mode === Mode.View) ? Strings.Common.DETAIL : Strings.Common.UPDATE, route: "" },
            ];

            if (!Helpers.isNullOrEmpty(stateLocal?.dataDetail)) {
                // let temp = JSON.parse(`${stateLocal?.dataDetail?.eventData}`.replaceAll(/(\r\n|\n|\r)/gm, ""));
                // const tempCourses = JSON.parse(`${temp.Courses}`.replaceAll(/(\r\n|\n|\r)/gm, ""));

                // temp = {
                //     ...temp,
                //     Courses: tempCourses,
                // };

                setDataDetail({
                    ...stateLocal?.dataDetail,
                    // eventData: JSON.stringify(temp)
                    //     .replaceAll("{", "{\n\t")
                    //     .replaceAll("}", "\n}")
                    //     .replaceAll(",", ",\n\t")
                    //     .replaceAll(";", ";\n\t")
                });
            } else {
                handleGoBack();
            }

            setModel({ mode, title, route });

            dispatch(showLoading(false));
        })();
    }, []);

    const handleGoBack = () => {
        const itemP = listPathName.find(el => el.pathName === Screens.MANAGER_EVENT);
        navigate(Screens.MANAGER_EVENT + (itemP ? itemP.query : ""));
    }

    const renderValueDate = (value: any) => {
        return (Helpers.isNullOrEmpty(value) || value === "0" || value === 0)
            ? undefined : Number(value) * 1000;
    }

    const onChangeValue = (value: any, key: string) => {
        let dataTemp: any = { ...dataDetail };
        dataTemp[key] = value;

        setIsOnChange(true);
        setDataDetail(dataTemp);
    }

    const handleOnSubmit = async () => {
        if (model.mode === Mode.View) {
            setModel({
                ...model, mode: Mode.Update,
                title: Strings.EVENT.TITLE_UPDATE_VIEW,
                route: [
                    { title: Strings.EVENT.TITLE_MENU, route: Screens.MANAGER_EVENT },
                    { title: Strings.Common.UPDATE, route: "" },
                ],
            })
        } else {
            if (Helpers.isNullOrEmpty(dataDetail?.eventData)) {
                setError(Strings.Validation.REQUIRED);
            } else {
                try {
                    dispatch(showLoading(true));

                    const result = await eventService.update({
                        id: dataDetail?.id,
                        eventData: dataDetail?.eventData,
                    });

                    if (result.statusCode === Constants.ApiCode.SUCCESS) {
                        setIsOnChange(false);
                        setModel({
                            mode: Mode.View,
                            title: Strings.EVENT.TITLE_DETAIL_VIEW,
                            route: [
                                { title: Strings.EVENT.TITLE_MENU, route: Screens.MANAGER_EVENT },
                                { title: Strings.Common.DETAIL, route: "" },
                            ],
                        })
                        dispatch(setDataAlert({ message: Strings.EVENT.RETRY_SUCCESS, type: "success" }));
                    }

                    dispatch(showLoading(false));
                } catch (error) {
                    dispatch(showLoading(false));
                    const e = Helpers.renderExceptionError(error);
                    dispatch(setDataAlert({ message: e, type: "error" }));
                }
            }
        }

    }

    return (
        <DashboardLayout
            isPermission
            title={model.title}
            route={model.route}

            onBackPress={() => {
                if (isOnChange) {
                    Helpers.showConfirmAlert(Strings.Message.CANCEL_ACTION_MESSAGE, handleGoBack);
                } else {
                    handleGoBack();
                }
            }}
            nameActionPress={(model.mode === Mode.View) ? Strings.Common.EDIT : Strings.Common.SAVE}
            onActionPress={
                (resourcePermissions.canUpdate) &&
                    (dataDetail?.processStatus === ProcessStatus.Failure)
                    ? handleOnSubmit : undefined
            }
        >
            <Card>
                <Box p={2}>
                    <Grid container spacing={3} p={2}>
                        <Grid item xs={12} md={6}>
                            <FormField
                                disabled
                                mode={model.mode}
                                label={Strings.EVENT.EVENT_CODE}
                                defaultValue={dataDetail?.eventCode}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormField
                                disabled
                                mode={model.mode}
                                label={Strings.EVENT.EVENT_NAME}
                                defaultValue={dataDetail?.eventName}
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <Autocomplete
                                disabled
                                mode={model.mode}
                                data={listTypeEvent || []}
                                label={Strings.EVENT.EVENT_TYPE}
                                defaultValue={`${dataDetail?.type}`}
                                placeholder={Strings.EVENT.SELECT_EVENT_TYPE}
                                onChange={(value) => { }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Autocomplete
                                disabled
                                mode={model.mode}
                                label={Strings.EVENT.PROCESS_STATUS}
                                data={listProcessStatusesEvent || []}
                                defaultValue={`${dataDetail?.processStatus}`}
                                placeholder={Strings.EVENT.SELECT_PROCESS_STATUS}
                                onChange={(value) => { }}
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <FormField
                                disabled
                                mode={model.mode}
                                label={Strings.EVENT.RETRY_COUNT}
                                defaultValue={dataDetail?.retryCount || "0"}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <DatePicker
                                disabled
                                label={Strings.EVENT.SEND_TIME}
                                placeholder={Strings.EVENT.SEND_TIME}
                                value={renderValueDate(dataDetail?.sendTime)}
                                onChangeValue={(value: any) => { }}
                            />
                        </Grid>

                        <Grid item xs={12} md={12}>
                            <FormField
                                isFullRow
                                multiline
                                maxLength={500}
                                mode={model.mode}
                                label={"Event data"}
                                errorMessage={error}
                                defaultValue={dataDetail?.eventData}
                                onChangeValue={(value) => {
                                    onChangeValue(value, "eventData");
                                    setError("");
                                }}
                            />
                        </Grid>
                    </Grid>
                </Box>
            </Card>
        </DashboardLayout >
    )
}

export default EventDetailScreen;