import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { SettingConfigFirebaseContainer } from "@maysoft/circle-common-react";

import Helpers from "commons/helpers";
import Strings from "constants/strings";
import Screens from "constants/screens";
import Constants from "constants/index";

import { RootState } from "store";
import { DashboardLayout } from "layout";
import { Mode, SystemSettingType } from "constants/enum";
import { useCommonComponentContext } from "@maysoft/common-component-react";



export interface ISettingCommonProps {
    mode?: any;
    settingCode: any;
    organizationId?: string;
    onBackPress: () => void;
    onChangeMode: (mode: any) => void;
}


const SystemSettingEditScreen = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const { getResourcePermissions } = useCommonComponentContext();

    const settingCode = searchParams.get("settingCode");
    const pramsMode = !Helpers.isNullOrEmpty(searchParams.get("mode")) ? Number(searchParams.get("mode")) : undefined;

    const listPathName = useSelector((state: RootState) => state.titleRoute.listPathName);
    const resourcePermissions = getResourcePermissions(Constants.ResourceCode.SYSTEM_SETTING);

    const [model, setModel] = useState<any>({ mode: Mode.View });

    useEffect(() => {
        if (!Helpers.isNullOrEmpty(settingCode)) {
            let mode: number = resourcePermissions.canUpdate ? (pramsMode || Mode.Update) : Mode.View;

            setModel({
                mode: mode,
                title: (mode === Mode.View)
                    ? Strings.SYSTEM_SETTING.TITLE_DETAIL_VIEW : Strings.SYSTEM_SETTING.TITLE_UPDATE_VIEW,
                route: [
                    { title: Strings.SYSTEM_SETTING.TITLE_MENU, route: Screens.SYSTEM_SETTING_LIST },
                    {
                        title: (mode === Mode.View)
                            ? Strings.Common.VIEW : Strings.Common.UPDATE, route: ""
                    },
                ],
            });

            navigate(Screens.SYSTEM_SETTING_EDIT + `?settingCode=${settingCode}&mode=${mode}`, { replace: true });
        }
    }, [settingCode]);

    const handleGoBack = () => {
        const itemP = listPathName.find(el => el.pathName === Screens.SYSTEM_SETTING_LIST);
        navigate(Screens.SYSTEM_SETTING_LIST + (itemP ? itemP.query : ""));
    };

    const onChangeMode = (newMode: any) => {
        if (newMode === Mode.View) {
            setModel({
                mode: newMode,
                title: Strings.SYSTEM_SETTING.TITLE_DETAIL_VIEW,
                route: [
                    {
                        title: Strings.SYSTEM_SETTING.TITLE_MENU,
                        route: Screens.SYSTEM_SETTING_LIST,
                    },
                    { title: Strings.Common.VIEW, route: "" },
                ],
            });
        }
        if (newMode === Mode.Update) {
            setModel({
                mode: newMode,
                title: Strings.SYSTEM_SETTING.TITLE_UPDATE_VIEW,
                route: [
                    {
                        title: Strings.SYSTEM_SETTING.TITLE_MENU,
                        route: Screens.SYSTEM_SETTING_LIST,
                    },
                    { title: Strings.Common.UPDATE, route: "" },
                ],
            });
        }
        navigate(Screens.SYSTEM_SETTING_EDIT + `?settingCode=${settingCode}&mode=${newMode}`, { replace: true });
    }

    return (
        <DashboardLayout
            title={model.title}
            route={model.route}
            isPermission={resourcePermissions.canRead}
        >
            {
                (settingCode === SystemSettingType.MessageFireBase) &&
                <SettingConfigFirebaseContainer
                    mode={model?.mode}
                    listServiceCode={[]}
                    hidenInputServiceCode
                    settingCode={`${SystemSettingType.MessageFireBase}`.replace("MF", "")}

                    onGoBack={() => handleGoBack()}
                    onChangeMode={(newMode) => { onChangeMode(newMode) }}
                />
            }
        </DashboardLayout>
    );
};

export default SystemSettingEditScreen;
