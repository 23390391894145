import { useEffect, useMemo, useState } from "react";
import { Card, Grid, IconButton } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { AddCircleOutline, Delete } from "@mui/icons-material";
import { useNavigate, useSearchParams } from "react-router-dom";

import Helpers from "commons/helpers";
import Screens from "constants/screens";
import Strings from "constants/strings";
import Constants from '../../constants';
import ClientService from "services/identity/client.service";

import { RootState } from "store";
import { DashboardLayout } from "layout";
import { setDataAlert } from "store/slice/message.slice";
import { showLoading } from "store/slice/loadingAPI.slice";
import { ICodename, IInput, ITitleRoute } from "commons/interfaces";
import { Mode, GrantType, Scopes, PermissionType, AppType } from "constants/enum";
import AppService, { IDataCreateUpdateSettingApp } from "services/identity/app.service";
import {
    Autocomplete, Typography, FormField, Box, ImageUploader,
    ControlLabelCheckBox, Switch, Button, useCommonComponentContext
} from "@maysoft/common-component-react";


interface IValueURI {
    error?: string;
    value?: {
        id?: string,
        value?: any,
        error?: string,
    }[];
}
interface IDataEdit {
    isEdit?: boolean;
    id?: string;
    clientId?: IInput;
    clientName?: IInput;
    description?: IInput;
    clientUri?: IInput;
    logoUrl?: IInput;
    allowOfflineAccess?: IInput;
    grantTypes?: IInput,
    redirectUris?: IValueURI,
    postLogoutRedirectUris?: IValueURI,
    absoluteRefreshTokenLifetime?: IInput,
    slidingRefreshTokenLifetime?: IInput,
    accessTokenLifetime?: IInput,
    clientSecret?: IInput,
    scopes?: IInput,
    updateTime?: string,
}

interface IModel {
    mode?: Mode;
    title?: string;
    route?: ITitleRoute[];
}

const clientService = new ClientService();

const ClientEditScreen = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [searchParams] = useSearchParams();
    const { getResourcePermissions } = useCommonComponentContext();

    const [dataEdit, setDataEdit] = useState<IDataEdit>({});
    const [model, setModel] = useState<IModel>({} as IModel);
    const [modeBasicInfo, setModeBasicInfo] = useState<Mode>(Mode.View);

    const [modeSettingApp, setModeSettingApp] = useState<Mode>(Mode.View);
    const [dataDetailSettingApp, setDataDetailSettingApp] = useState<IDataCreateUpdateSettingApp>({} as IDataCreateUpdateSettingApp);
    const [dataSettingApp, setDataSettingApp] = useState<{
        allowRegister?: boolean;
        minLength?: number;
        numeric?: boolean;
        uppercase?: boolean;
        lowercase?: boolean;
        specialCharacter?: boolean;
    }>({});

    const listPathName = useSelector((state: RootState) => state.titleRoute.listPathName);
    const resourcePermissions = getResourcePermissions(Constants.ResourceCode.MANAGE_CLIENT);

    const listScope: ICodename[] = [
        { code: `${Scopes.OfflineAccess}`, name: "Offline Access" },
        { code: `${Scopes.AuthenticationScheme}`, name: "Authentication Scheme" },
        { code: `${Scopes.Address}`, name: "Address" },
        { code: `${Scopes.Email}`, name: "Email" },
        { code: `${Scopes.OpenId}`, name: "Open Id" },
        { code: `${Scopes.Phone}`, name: "Phone" },
        { code: `${Scopes.PolicyName}`, name: "Policy Name" },
        { code: `${Scopes.Profile}`, name: "Profile" },
        { code: `${Scopes.IdentityServerApi}`, name: "Scope Name" },
    ];

    const [listScopesMore, setListScopesMore] = useState<{ id?: string, value?: any, error?: string }[]>([]);

    const listGrantType: ICodename[] = [
        { code: `${GrantType.AuthorizationCode}`, name: "Authorization Code" },
        { code: `${GrantType.ClientCredentials}`, name: "Client Credentials" },
        { code: `${GrantType.ResourceOwnerPassword}`, name: "Resource Owner Password" },
    ];

    const idClient = searchParams.get("id");
    const pramsMode = !Helpers.isNullOrEmpty(searchParams.get("mode")) ? Number(searchParams.get("mode")) : undefined;

    useEffect(() => {
        (async () => {
            if (!Helpers.isNullOrEmpty(idClient)) {
                await getDetail(idClient);

                await getAllSettingApp(dataEdit?.clientId?.value);
            } else {
                handleChangeMode(Mode.Create);
                setDataEdit({
                    absoluteRefreshTokenLifetime: { value: 2592000 },
                    slidingRefreshTokenLifetime: { value: 1296000 },
                    allowOfflineAccess: { value: false },
                    clientSecret: { value: "secret" },
                });
            }
        })();
    }, [idClient]);

    const handleGoBack = () => {
        const itemP = listPathName.find(el => el.pathName === Screens.CLIENT_LIST);
        navigate(Screens.CLIENT_LIST + (itemP ? itemP.query : ""));
    };

    const handleChangeMode = (value: Mode) => {
        let title: string = "";
        let route = [{ title: Strings.CLIENT.TITLE_MENU, route: Screens.CLIENT_LIST }];

        if (value === Mode.Create) {
            title = Strings.CLIENT.TITLE_CREATE_VIEW;
            route = [
                { title: Strings.CLIENT.TITLE_MENU, route: Screens.CLIENT_LIST },
                { title: Strings.Common.CREATE_NEW, route: "" }
            ];
        }
        if (value === Mode.Update) {
            title = Strings.CLIENT.TITLE_UPDATE_VIEW;
            route = [
                { title: Strings.CLIENT.TITLE_MENU, route: Screens.CLIENT_LIST },
                { title: Strings.Common.UPDATE, route: "" }
            ];
        }
        if (value === Mode.View) {
            title = Strings.CLIENT.TITLE_DETAIL_VIEW;
            route = [
                { title: Strings.CLIENT.TITLE_MENU, route: Screens.CLIENT_LIST },
                { title: Strings.Common.VIEW, route: "" }
            ];
        }

        setModel({
            mode: value,
            title: title,
            route: route,
        });

        setModeBasicInfo(value);
        setModeSettingApp(value);

        if (!Helpers.isNullOrEmpty(idClient)) {
            navigate(Screens.CLIENT_EDIT + `?id=${idClient}&mode=${value}`, { replace: true });
        }
    }

    const getDetail = async (id: string) => {
        try {
            dispatch(showLoading(true));
            const detail = await clientService.getDetail(id);

            const allowedScopes: string[] = [];
            const listScopeMoreTemp: any[] = [];

            (detail.allowedScopes || []).forEach((item: any) => {
                if (listScope.findIndex(el => el.code === item.scope) === -1) {
                    listScopeMoreTemp.push({
                        id: item.id,
                        value: item.scope,
                    })
                } else {
                    allowedScopes.push(item.scope);
                }
            });

            const allowedGrantTypes: ICodename[] = [];
            (detail.allowedGrantTypes || []).forEach((item: any) => {
                allowedGrantTypes.push(item.grantType);
            });


            const redirectUri: any[] = [];
            (detail.redirectUris || []).forEach((el: any) =>
                redirectUri.push({
                    id: el.id,
                    value: el.redirectUri,
                })
            );

            const postLogoutRedirectUri: any[] = [];
            (detail.postLogoutRedirectUris || []).forEach((el: any) =>
                postLogoutRedirectUri.push({
                    id: el.id,
                    value: el.postLogoutRedirectUri
                })
            );

            setListScopesMore(listScopeMoreTemp);

            setDataEdit({
                id,
                clientId: { value: detail.clientId },
                clientName: { value: detail.clientName || detail.clientId },
                description: { value: detail.description },
                logoUrl: { value: detail.logoUri },
                clientUri: { value: detail.clientUri },
                clientSecret: { value: detail.clientSecret },
                allowOfflineAccess: { value: detail.allowOfflineAccess },
                accessTokenLifetime: { value: detail.accessTokenLifetime },
                slidingRefreshTokenLifetime: { value: detail.slidingRefreshTokenLifetime },
                absoluteRefreshTokenLifetime: { value: detail.absoluteRefreshTokenLifetime },
                scopes: { value: allowedScopes },
                redirectUris: { value: redirectUri },
                grantTypes: { value: allowedGrantTypes },
                postLogoutRedirectUris: { value: postLogoutRedirectUri },
                updateTime: detail.updateTime,
            });

            const mode = (detail?.enabled === false)
                ? Mode.View
                : (resourcePermissions.canUpdate ? (pramsMode || Mode.Update) : Mode.View);

            handleChangeMode(mode);

        } catch (error) {
            const e = Helpers.renderExceptionError(error);
            dispatch(setDataAlert({ message: e, type: "error" }));
        } finally {
            dispatch(showLoading(false));
        }
    }

    const onValueChange = async (key: string, val: any) => {
        let data: any = { ...dataEdit };
        data[key] = { value: val };
        data["isEdit"] = true;
        setDataEdit(data);
    }

    const onDeleteInputURI = async (key: string, id: string) => {
        let data: any = { ...dataEdit };
        const dataTemp = [...data[key]?.value || []];
        data[key] = { value: dataTemp.filter(el => el.id !== id) };
        data["isEdit"] = true;
        setDataEdit(data);
    }

    const onSetValueInputURI = async (key: string, id: string, val: any) => {
        let data: any = { ...dataEdit };
        const dataTemp: any[] = [];
        [...data[key]?.value || []].forEach(el => {
            if (el.id !== id) {
                dataTemp.push(el);
            } else {
                dataTemp.push({ ...el, value: val });
            }
        });
        data[key] = { value: dataTemp };
        data["isEdit"] = true;
        setDataEdit(data);
    }

    const onAddNewItemScopeMore = async () => {
        let checked = true;

        let dataTemp = [...listScopesMore || []].map(element => {
            if (Helpers.isNullOrEmpty(element.value)) {
                element.error = Strings.Validation.REQUIRED;
                checked = false;
            }
            return element;
        });

        if (checked) {
            dataTemp.push({ id: Date.now().toString() });
        }
        setListScopesMore(dataTemp);
    }

    const onSetValueScopeMore = async (id: string, val: any) => {
        let dataTemp = [...listScopesMore || []];
        const index = dataTemp.findIndex(el => el.id === id);
        if (index !== -1) {
            dataTemp[index] = { id: id, value: val };
        }
        setListScopesMore(dataTemp);
    }

    const checkValidate = () => {
        let checked = true;
        let data: any = { ...dataEdit };
        const listKey: string[] = [
            "clientId",
            "clientName",
            "absoluteRefreshTokenLifetime",
            "slidingRefreshTokenLifetime",
            "accessTokenLifetime",
            "grantTypes",
            "scopes",
        ];
        listKey.forEach(key => {
            if (Helpers.isNullOrEmpty(data[key]?.value)) {
                data[key] = { error: Strings.Validation.REQUIRED };
                checked = false;
            }
            if (!Helpers.isNullOrEmpty(data[key]?.error)) {
                checked = false;
            }
        })
        if (!checked) {
            setDataEdit(data);
        }
        return checked
    }

    const onSumit = async () => {
        if (!checkValidate()) { return; }
        else {
            try {
                dispatch(showLoading(true));

                const redirectUris: string[] = [];
                const postLogoutRedirectUris: string[] = [];

                (dataEdit?.redirectUris?.value || []).forEach(el => {
                    if (!Helpers.isNullOrEmpty(el.value)) {
                        redirectUris.push(el.value);
                    }
                });

                (dataEdit?.postLogoutRedirectUris?.value || []).forEach(el => {
                    if (!Helpers.isNullOrEmpty(el.value)) {
                        postLogoutRedirectUris.push(el.value);
                    }
                });

                const allowOfflineAccess: boolean = [...dataEdit?.scopes?.value || []].findIndex(el => el === `${Scopes.OfflineAccess}`) !== -1;
                const listScopeMoreTemp: string[] = [...dataEdit?.scopes?.value || []];
                listScopesMore.forEach(el => {
                    if (!Helpers.isNullOrEmpty(el.value)) {
                        listScopeMoreTemp.push(el.value);
                    }
                });

                const dataSubmit: any = {
                    clientId: dataEdit?.clientId?.value,
                    clientName: dataEdit?.clientName?.value,
                    description: dataEdit?.description?.value,
                    clientUri: dataEdit?.clientUri?.value,
                    grantTypes: dataEdit?.grantTypes?.value,
                    allowOfflineAccess: allowOfflineAccess,
                    logoUri: dataEdit?.logoUrl?.value ? dataEdit?.logoUrl?.value : undefined,
                    slidingRefreshTokenLifetime: dataEdit?.slidingRefreshTokenLifetime?.value,
                    absoluteRefreshTokenLifetime: dataEdit?.absoluteRefreshTokenLifetime?.value,
                    accessTokenLifetime: dataEdit?.accessTokenLifetime?.value,
                    redirectUris,
                    postLogoutRedirectUris,
                    scopes: listScopeMoreTemp,
                    clientSecret: dataEdit?.clientSecret?.value,
                };

                let result: any;

                if (dataEdit?.id) {
                    result = await clientService.update({
                        ...dataSubmit,
                        id: dataEdit?.id,
                        updateTime: dataEdit?.updateTime,
                    });
                } else {
                    result = await clientService.create(dataSubmit);
                }

                if (result.statusCode === Constants.ApiCode.SUCCESS) {
                    const message = dataEdit?.id ? Strings.Message.UPDATE_SUCCESS : Strings.Message.CREATE_SUCCESS;
                    dispatch(setDataAlert({ message: message, type: "success" }));
                }

                if (Helpers.isNullOrEmpty(dataEdit?.id)) {
                    handleGoBack();
                }

            } catch (error) {
                const e = Helpers.renderExceptionError(error);
                dispatch(setDataAlert({ message: e, type: "error" }));
            } finally {
                dispatch(showLoading(false));
            }
        }
    }

    const renderClientInfo = () => (
        <Card>
            <Box p={2}>
                <Box sx={{
                    gap: 1,
                    display: "flex",
                    flexWrap: "wrap",
                    alignItems: "center",
                    justifyContent: "space-between",
                }}>
                    <Typography variant="h5">{Strings.CLIENT.TITLE_INFOR}</Typography>
                    {
                        (model.mode !== Mode.View) &&
                        resourcePermissions.canUpdate &&
                        !Helpers.isNullOrEmpty(dataEdit?.id) &&
                        <Button onClick={() => {
                            if (modeBasicInfo === Mode.View) {
                                setModeBasicInfo(Mode.Update)
                            } else {
                                onSumit();
                                setModeBasicInfo(Mode.View);
                            }
                        }}>
                            {modeBasicInfo === Mode.View ? Strings.Common.EDIT : Strings.Common.SAVE}
                        </Button>
                    }
                </Box>
                <Grid container spacing={3} p={2}>
                    <Grid item xs={12} md={6}>
                        <FormField
                            required
                            maxLength={255}
                            mode={modeBasicInfo}
                            label={Strings.CLIENT.CLIENT_ID}
                            placeholder={Strings.CLIENT.ENTER_CLIENT_ID}
                            value={dataEdit?.clientId?.value || ""}
                            errorMessage={dataEdit?.clientId?.error}
                            onChangeValue={(value) => { onValueChange("clientId", value) }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormField
                            required
                            maxLength={255}
                            mode={modeBasicInfo}
                            label={Strings.CLIENT.NAME}
                            placeholder={Strings.CLIENT.ENTER_NAME}
                            value={dataEdit?.clientName?.value || ""}
                            errorMessage={dataEdit?.clientName?.error}
                            onChangeValue={(value) => { onValueChange("clientName", value) }}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <FormField
                            maxLength={255}
                            mode={modeBasicInfo}
                            label={Strings.CLIENT.CLIENT_URI}
                            placeholder={Strings.CLIENT.ENTER_CLIENT_URI}
                            value={dataEdit?.clientUri?.value || ""}
                            errorMessage={dataEdit?.clientUri?.error}
                            onChangeValue={(value) => { onValueChange("clientUri", value) }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        {(modeBasicInfo === Mode.View)
                            ? <Typography>
                                <Typography variant="button" fontWeight="bold">{Strings.CLIENT.ACCESS_TOKEN_LITE_TIME}: </Typography>
                                <Typography variant="button">{dataEdit?.accessTokenLifetime?.value || ""}</Typography>
                            </Typography>
                            : <FormField
                                isMoney
                                required
                                type="number"
                                maxLength={20}
                                mode={modeBasicInfo}
                                label={Strings.CLIENT.ACCESS_TOKEN_LITE_TIME}
                                placeholder={Strings.CLIENT.ENTER_ACCESS_TOKEN_LITE_TIME}
                                value={dataEdit?.accessTokenLifetime?.value || ""}
                                errorMessage={dataEdit?.accessTokenLifetime?.error}
                                onChangeValue={(value) => {
                                    const newval = Number(value) < 0 ? 0 : value;
                                    onValueChange("accessTokenLifetime", newval);
                                }}
                            />
                        }
                    </Grid>

                    <Grid item xs={12} md={(modeBasicInfo === Mode.View) ? 12 : 6}>
                        {
                            (modeBasicInfo === Mode.View)
                                ? <Typography>
                                    <Typography variant="button" fontWeight="bold">{Strings.CLIENT.ABSOLUTE_REFRESH_TOKEN_LIFE_TIME}: </Typography>
                                    <Typography variant="button">{dataEdit?.absoluteRefreshTokenLifetime?.value || ""}</Typography>
                                </Typography>
                                : <FormField
                                    isMoney
                                    required
                                    type="number"
                                    maxLength={20}
                                    mode={modeBasicInfo}
                                    label={Strings.CLIENT.ABSOLUTE_REFRESH_TOKEN_LIFE_TIME}
                                    placeholder={Strings.CLIENT.ENTER_ABSOLUTE_REFRESH_TOKEN_LIFE_TIME}
                                    value={dataEdit?.absoluteRefreshTokenLifetime?.value || ""}
                                    errorMessage={dataEdit?.absoluteRefreshTokenLifetime?.error}
                                    onChangeValue={(value) => {
                                        const newval = Number(value) < 0 ? 0 : value;
                                        onValueChange("absoluteRefreshTokenLifetime", newval)
                                    }}
                                />
                        }
                    </Grid>
                    <Grid item xs={12} md={(modeBasicInfo === Mode.View) ? 12 : 6}>
                        {
                            (modeBasicInfo === Mode.View)
                                ? <Typography>
                                    <Typography variant="button" fontWeight="bold">{Strings.CLIENT.SLIDING_REFRESH_TOKEN_LIFE_TIME}: </Typography>
                                    <Typography variant="button">{dataEdit?.slidingRefreshTokenLifetime?.value || ""}</Typography>
                                </Typography>
                                : <FormField
                                    isMoney
                                    required
                                    type="number"
                                    maxLength={20}
                                    mode={modeBasicInfo}
                                    label={Strings.CLIENT.SLIDING_REFRESH_TOKEN_LIFE_TIME}
                                    placeholder={Strings.CLIENT.ENTER_SLIDING_REFRESH_TOKEN_LIFE_TIME}
                                    value={dataEdit?.slidingRefreshTokenLifetime?.value || ""}
                                    errorMessage={dataEdit?.slidingRefreshTokenLifetime?.error}
                                    onChangeValue={(value) => {
                                        const newval = Number(value) < 0 ? 0 : value;
                                        onValueChange("slidingRefreshTokenLifetime", newval)
                                    }}
                                />
                        }
                    </Grid>

                    <Grid item xs={12} md={12}>
                        <FormField
                            multiline
                            isFullRow
                            maxLength={500}
                            mode={modeBasicInfo}
                            label={Strings.CLIENT.DESCRIPTION}
                            placeholder={Strings.CLIENT.ENTER_DESCRIPTION}
                            defaultValue={dataEdit?.description?.value || ""}
                            errorMessage={dataEdit?.description?.error}
                            onBlur={(value) => { onValueChange("description", value) }}
                        />
                    </Grid>

                    <Grid item xs={12} md={12}>
                        <Autocomplete
                            required
                            multiple
                            isFullRow
                            mode={modeBasicInfo}
                            data={listGrantType || []}
                            label={Strings.CLIENT.GRANT_TYPE}
                            defaultValue={dataEdit?.grantTypes?.value}
                            errorMessage={dataEdit?.grantTypes?.error}
                            placeholder={Strings.CLIENT.SELECT_GRANT_TYPE}
                            onChange={(value) => onValueChange("grantTypes", value)}
                        />
                    </Grid>

                    <Grid item xs={12} md={12}>
                        <Autocomplete
                            required
                            multiple
                            isFullRow
                            mode={modeBasicInfo}
                            data={listScope || []}
                            label={Strings.CLIENT.SCOPE}
                            defaultValue={dataEdit?.scopes?.value}
                            errorMessage={dataEdit?.scopes?.error}
                            placeholder={Strings.CLIENT.SELECT_SCOPE}
                            onChange={(value) => onValueChange("scopes", value)}
                        />
                    </Grid>

                    {/* SCOPE MORE */}
                    {(modeBasicInfo !== Mode.View)
                        ?
                        <Grid item xs={12}>
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                                <Typography variant="button" fontWeight="bold">{Strings.CLIENT.SCOPE_MORE}</Typography>
                                <IconButton onClick={() => { onAddNewItemScopeMore() }}>
                                    <AddCircleOutline color="info" />
                                </IconButton>
                            </Box>
                            {
                                listScopesMore.map((item) => (
                                    <Box key={item.id} mt={1} sx={{ display: "flex", alignItems: "center" }}>
                                        <FormField
                                            maxLength={255}
                                            mode={modeBasicInfo}
                                            variant="outlined"
                                            errorMessage={item.error}
                                            defaultValue={item.value || ""}
                                            placeholder={Strings.CLIENT.ENTER_SCOPE_NAME}
                                            onBlur={(value) => {
                                                onSetValueScopeMore(item.id, value);
                                            }}
                                        />
                                        <IconButton onClick={() => {
                                            let dataTemp = [...listScopesMore || []].filter(el => el.id !== item.id);
                                            setListScopesMore(dataTemp);
                                        }}>
                                            <Delete color="error" />
                                        </IconButton>
                                    </Box>
                                ))
                            }
                        </Grid>
                        : ([...listScopesMore || []].length > 0) && <Grid item xs={12}>
                            <Typography variant="button" fontWeight="bold">{Strings.CLIENT.SCOPE_MORE}: </Typography>
                            <Typography variant="button">{[...listScopesMore || []].map(el => el.value).join("; ")}</Typography>
                        </Grid>
                    }

                    {/* REDIRECT URI */}
                    {(modeBasicInfo !== Mode.View)
                        ? <Grid item xs={12} md={6}>
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                                <Typography variant="button" fontWeight="bold">{Strings.CLIENT.REDIRECT_URI}</Typography>
                                <IconButton onClick={() => {
                                    const value = [...dataEdit?.redirectUris?.value || [], { id: Date.now().toString() }];
                                    onValueChange("redirectUris", value);
                                }}>
                                    <AddCircleOutline color="info" />
                                </IconButton>
                            </Box>
                            {
                                [...dataEdit?.redirectUris?.value || []].map((item) => (
                                    <Box key={item.id} mt={1} sx={{ display: "flex", alignItems: "center" }}>
                                        <FormField
                                            maxLength={255}
                                            variant="outlined"
                                            defaultValue={item.value || ""}
                                            errorMessage={dataEdit?.redirectUris?.error}
                                            placeholder={Strings.CLIENT.ENTER_REDIRECT_URI}
                                            onBlur={(value) => {
                                                onSetValueInputURI("redirectUris", item.id, value);
                                            }}
                                        />
                                        <IconButton onClick={() => {
                                            onDeleteInputURI("redirectUris", item.id);
                                        }}>
                                            <Delete color="error" />
                                        </IconButton>
                                    </Box>
                                ))
                            }
                        </Grid>
                        : <Grid item xs={12}>
                            <Typography variant="button" fontWeight="bold">{Strings.CLIENT.REDIRECT_URI}: </Typography>
                            <Box px={2} display="grid">
                                {
                                    [...dataEdit?.redirectUris?.value || []].map(el => (
                                        <Typography variant="button">- {el.value}</Typography>
                                    ))
                                }
                            </Box>
                        </Grid>
                    }

                    {/* Post Logout Redirect Uris */}
                    {(modeBasicInfo !== Mode.View)
                        ? <Grid item xs={12} md={6}>
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                                <Typography variant="button" fontWeight="bold">{Strings.CLIENT.POST_LOGOUT_REDIRECT_URI}</Typography>
                                <IconButton onClick={() => {
                                    const value = [...dataEdit?.postLogoutRedirectUris?.value || [], { id: Date.now().toString() }];
                                    onValueChange("postLogoutRedirectUris", value)
                                }}>
                                    <AddCircleOutline color="info" />
                                </IconButton>
                            </Box>
                            {
                                (dataEdit?.postLogoutRedirectUris?.value || []).map((item) => (
                                    <Box key={item.id} mt={1} sx={{ display: "flex", alignItems: "center" }}>
                                        <FormField
                                            maxLength={255}
                                            variant="outlined"
                                            mode={modeBasicInfo}
                                            defaultValue={item.value || ""}
                                            errorMessage={dataEdit?.postLogoutRedirectUris?.error}
                                            placeholder={Strings.CLIENT.ENTER_POST_LOGOUT_REDIRECT_URI}
                                            onBlur={(value) => {
                                                onSetValueInputURI("postLogoutRedirectUris", item.id, value);
                                            }}
                                        />
                                        <IconButton onClick={() => {
                                            onDeleteInputURI("postLogoutRedirectUris", item.id);
                                        }}>
                                            <Delete color="error" />
                                        </IconButton>
                                    </Box>
                                ))
                            }
                        </Grid>
                        : <Grid item xs={12}>
                            <Typography variant="button" fontWeight="bold">{Strings.CLIENT.POST_LOGOUT_REDIRECT_URI}: </Typography>
                            <Box px={2} display="grid">
                                {
                                    [...dataEdit?.postLogoutRedirectUris?.value || []].map(el => (
                                        <Typography variant="button">- {el.value}</Typography>
                                    ))
                                }
                            </Box>
                        </Grid>
                    }
                </Grid>
            </Box>
        </Card>
    );

    const valueLogoId = useMemo(() => {
        if (Helpers.isNullOrEmpty(dataEdit?.logoUrl?.value)) {
            return undefined;
        } else {
            const temps = `${dataEdit?.logoUrl?.value}`.split("/");
            return temps[temps?.length - 1];
        }
    }, [dataEdit?.logoUrl?.value]);

    // #region SettingApp
    const getAllSettingApp = async (clientId: string) => {
        try {
            const result = await new AppService().getAll({
                clientId: clientId,
                serviceCode: Constants.SERVICE_CODE.IDENTITY,
            });

            setDataDetailSettingApp({
                clientId: result[0]?.clientId,
                tenantCode: result[0]?.tenantCode,
                serviceCode: result[0]?.serviceCode,
                organizationId: result[0]?.organizationId,

                id: result[0]?.id,
                type: result[0]?.type,
                name: result[0]?.name,
                setting: result[0]?.settingJson,
                description: result[0]?.description,
            });

            setDataSettingApp({
                allowRegister: result[0]?.settingJson?.allowRegister,
                minLength: result[0]?.settingJson?.password.minLength,
                numeric: result[0]?.settingJson?.password.numeric,
                uppercase: result[0]?.settingJson?.password.uppercase,
                lowercase: result[0]?.settingJson?.password.lowercase,
                specialCharacter: result[0]?.settingJson?.password.specialCharacter,
            });

        } catch (error) {
            const e = Helpers.renderExceptionError(error);
            dispatch(setDataAlert({ message: e, type: "error" }));
        }
    }

    const handleCreateUpdateSettingApp = async () => {
        try {
            dispatch(showLoading(true));

            if (Helpers.isNullOrEmpty(dataDetailSettingApp?.id)) {
                const newReq: IDataCreateUpdateSettingApp = {
                    clientId: dataEdit?.clientId?.value,
                    tenantCode: Constants.TENANT_CODE?.IDENTITY,
                    serviceCode: Constants.SERVICE_CODE?.IDENTITY,
                    organizationId: undefined,

                    type: AppType.Website,
                    name: {
                        value: {
                            "vi": dataEdit?.clientName?.value,
                            "en": dataEdit?.clientName?.value,
                        }
                    },
                    description: { value: { "vi": "", "en": "" } },
                    setting: {
                        allowRegister: dataSettingApp?.allowRegister || false,
                        password: {
                            numeric: dataSettingApp?.numeric,
                            lowercase: dataSettingApp?.lowercase,
                            uppercase: dataSettingApp?.uppercase,
                            minLength: dataSettingApp?.minLength,
                            specialCharacter: dataSettingApp?.specialCharacter,
                        },
                    },
                };

                await new AppService().create(newReq);

                await getAllSettingApp(dataEdit?.clientId?.value);

                dispatch(setDataAlert({ message: Strings.Message.CREATE_SUCCESS, type: "success" }));
            } else {
                const newReq: IDataCreateUpdateSettingApp = {
                    ...dataDetailSettingApp,
                    name: {
                        value: {
                            "vi": dataEdit?.clientName?.value,
                            "en": dataEdit?.clientName?.value,
                        }
                    },
                    setting: {
                        allowRegister: dataSettingApp?.allowRegister || false,
                        password: {
                            numeric: dataSettingApp?.numeric,
                            lowercase: dataSettingApp?.lowercase,
                            uppercase: dataSettingApp?.uppercase,
                            minLength: dataSettingApp?.minLength,
                            specialCharacter: dataSettingApp?.specialCharacter,
                        },
                    },
                };

                await new AppService().update(newReq);

                dispatch(setDataAlert({ message: Strings.Message.UPDATE_SUCCESS, type: "success" }));
            }

        } catch (error) {
            const e = Helpers.renderExceptionError(error);
            dispatch(setDataAlert({ message: e, type: "error" }));
        } finally {
            dispatch(showLoading(false));
        }
    }

    const renderClientSettingApp = () => (
        <Card sx={{ marginTop: 3 }}>
            <Box p={2}>
                <Box sx={{
                    gap: 1,
                    display: "flex",
                    flexWrap: "wrap",
                    alignItems: "center",
                    justifyContent: "space-between",
                }}>
                    <Typography variant="h5">{"Cấu hình chung"}</Typography>
                    {
                        (model.mode !== Mode.View) &&
                        resourcePermissions.canUpdate &&
                        !Helpers.isNullOrEmpty(dataEdit?.id) &&
                        <Button onClick={() => {
                            if (modeSettingApp === Mode.View) {
                                setModeSettingApp(Mode.Update)
                            } else {
                                handleCreateUpdateSettingApp();
                                setModeSettingApp(Mode.View)
                            }
                        }}>
                            {modeSettingApp === Mode.View ? Strings.Common.EDIT : Strings.Common.SAVE}
                        </Button>
                    }
                </Box>
                <Grid container spacing={2} p={2}>
                    <Grid item xs={12}>
                        <Box display="flex" alignItems="center">
                            <Typography variant="h6" fontWeight="bold">
                                {"Cho phép đăng ký: "}
                            </Typography>
                            <Box marginLeft={1}>
                                <Switch
                                    defaultChecked={dataSettingApp?.allowRegister}
                                    disabled={modeSettingApp === Mode.View}
                                    onChange={(event, checked) => {
                                        setDataSettingApp(prev => ({
                                            ...prev,
                                            allowRegister: checked,
                                        }))
                                    }}
                                />
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h6" fontWeight="bold">
                            {"Thiết lập mật khẩu"}
                        </Typography>
                        <Box sx={{
                            gap: 1,
                            marginTop: 1,
                            paddingLeft: 1,
                            display: "grid",
                        }}>
                            <ControlLabelCheckBox
                                isRow
                                mode={modeSettingApp}
                                value={dataSettingApp?.numeric}
                                label="Ký tự số"
                                onChangeValue={(value) => {
                                    setDataSettingApp(prev => ({
                                        ...prev,
                                        numeric: value,
                                    }))
                                }}
                            />
                            <ControlLabelCheckBox
                                isRow
                                mode={modeSettingApp}
                                value={dataSettingApp?.uppercase}
                                label="Chữ hoa"
                                onChangeValue={(value) => {
                                    setDataSettingApp(prev => ({
                                        ...prev,
                                        uppercase: value,
                                    }))
                                }}
                            />
                            <ControlLabelCheckBox
                                isRow
                                mode={modeSettingApp}
                                value={dataSettingApp?.lowercase}
                                label="Chữ thường"
                                onChangeValue={(value) => {
                                    setDataSettingApp(prev => ({
                                        ...prev,
                                        lowercase: value,
                                    }))
                                }}
                            />
                            <ControlLabelCheckBox
                                isRow
                                mode={modeSettingApp}
                                value={dataSettingApp?.specialCharacter}
                                label="ký tự đặc biệt"
                                onChangeValue={(value) => {
                                    setDataSettingApp(prev => ({
                                        ...prev,
                                        specialCharacter: value,
                                    }))
                                }}
                            />
                            <Box sx={{ paddingLeft: "9px" }}>
                                <Box sx={{
                                    gap: 1,
                                    display: "flex",
                                    flexWrap: "wrap",
                                    alignItems: "center",
                                }}>
                                    <Typography variant="button" fontWeight="bold">
                                        {"Độ dài tối thiểu: "}
                                    </Typography>
                                    <Box maxWidth={"100px"}>
                                        <FormField
                                            type="number"
                                            maxLength={255}
                                            variant="outlined"
                                            mode={modeSettingApp}
                                            placeholder={"Nhập độ dài"}
                                            value={dataSettingApp?.minLength}
                                            onChangeValue={(value) => {
                                                const newValue = value ? (Number(value) > 0 ? value : 0) : undefined;
                                                setDataSettingApp(prev => ({
                                                    ...prev,
                                                    minLength: newValue,
                                                }))
                                            }}
                                        />
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Card>
    );
    // #endregion SettingApp

    return (
        <DashboardLayout
            title={model.title}
            route={model.route}
            isPermission={resourcePermissions.canRead}

            onBackPress={() => {
                if (!dataEdit?.isEdit) {
                    handleGoBack()
                } else {
                    Helpers.showConfirmAlert(Strings.Message.CANCEL_ACTION_MESSAGE, handleGoBack);
                }
            }}
            nameActionPress={(model.mode === Mode.View) ? Strings.Common.EDIT : Strings.Common.SAVE}
            onActionPress={
                (resourcePermissions.canCreate &&
                    Helpers.isNullOrEmpty(dataEdit?.id))
                    ? onSumit
                    : (
                        (resourcePermissions.canUpdate && model.mode === Mode.View)
                            ? () => { handleChangeMode(Mode.Update) }
                            : undefined
                    )
            }
        >
            <Box>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={5} md={3}>
                        <Card>
                            <Box p={2} textAlign="center">
                                <ImageUploader
                                    width={150}
                                    height={150}
                                    imageRatio="1:1"
                                    fileId={valueLogoId}
                                    disabled={modeBasicInfo === Mode.View}
                                    onChangeImage={(data) => {
                                        if (Helpers.isNullOrEmpty(data?.accessUrl)
                                            && Helpers.isNullOrEmpty(data?.id)) {
                                            return;
                                        } else {
                                            onValueChange("logoUrl", data?.accessUrl)
                                        }
                                    }}
                                />
                                <Typography variant="h6">{Strings.CLIENT.LOGO_CLIENT}</Typography>
                                {/* <Box mt={2}>
                                    <Typography variant="caption" fontWeight="bold" color="error">Note:</Typography>  <br />
                                    <Typography variant="caption"> - 30 day = 2592000 seconds</Typography> <br />
                                    <Typography variant="caption"> - 15 day = 2592000 seconds</Typography> <br />
                                    <Typography variant="caption"> - 1 day = 86400 seconds</Typography> <br />
                                    <Typography variant="caption"> - 1 hours = 3600 seconds</Typography>
                                </Box> */}
                            </Box>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={7} md={9}>
                        {renderClientInfo()}
                        {!Helpers.isNullOrEmpty(dataEdit?.id) && renderClientSettingApp()}
                    </Grid>
                </Grid>
            </Box>
        </DashboardLayout >
    )
}

export default ClientEditScreen;